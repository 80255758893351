import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Time: { input: string; output: string };
  Upload: { input: any; output: any };
};

export type GeneratedAccount = {
  __typename?: 'Account';
  acceptsMarketing?: Maybe<Scalars['Boolean']['output']>;
  /** only visible if you are the account owner and went through KYC process */
  aml: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  /** Only visible if you are the account owner */
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  /** if this account belongs to current authenticated user */
  isCurrentUser: Scalars['Boolean']['output'];
  /** unverified for marekting purposes, empty value means user opted out and null value means user has not been asked yet */
  phone?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<GeneratedPronouns>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type GeneratedAddress = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  customsCode?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  provinceCode?: Maybe<Scalars['String']['output']>;
  provinceName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Time']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type GeneratedArtist = {
  __typename?: 'Artist';
  ArteBio: Scalars['Boolean']['output'];
  airtableId?: Maybe<Scalars['String']['output']>;
  avantLink?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  byline?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  storyblokId?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type GeneratedArtistBlock = {
  __typename?: 'ArtistBlock';
  action: GeneratedArtistBlockAction;
  artistID: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  date?: Maybe<GeneratedArtistBlockDate>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<GeneratedArtistBlockImage>;
  intro: Scalars['String']['output'];
  kind: GeneratedArtistBlockKind;
  overline: Scalars['String']['output'];
  size: GeneratedArtistBlockSize;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  url: Scalars['String']['output'];
  video?: Maybe<GeneratedArtistBlockVideo>;
};

export enum GeneratedArtistBlockAction {
  Dialog = 'DIALOG',
  Unspecified = 'UNSPECIFIED',
  Url = 'URL',
}

export type GeneratedArtistBlockDate = {
  __typename?: 'ArtistBlockDate';
  end?: Maybe<Scalars['Time']['output']>;
  start?: Maybe<Scalars['Time']['output']>;
};

export type GeneratedArtistBlockDateInput = {
  end?: InputMaybe<Scalars['Time']['input']>;
  start?: InputMaybe<Scalars['Time']['input']>;
};

export type GeneratedArtistBlockImage = {
  __typename?: 'ArtistBlockImage';
  alt: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type GeneratedArtistBlockImageInput = {
  alt: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export enum GeneratedArtistBlockKind {
  Article = 'ARTICLE',
  Default = 'DEFAULT',
  Edition = 'EDITION',
  Event = 'EVENT',
  Original = 'ORIGINAL',
  Product = 'PRODUCT',
  Unspecified = 'UNSPECIFIED',
  Video = 'VIDEO',
  Website = 'WEBSITE',
}

export enum GeneratedArtistBlockSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Unspecified = 'UNSPECIFIED',
}

export type GeneratedArtistBlockVideo = {
  __typename?: 'ArtistBlockVideo';
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  platform: GeneratedVideoPlatform;
};

export type GeneratedArtistBlockVideoInput = {
  duration: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  platform: GeneratedVideoPlatform;
};

export type GeneratedArtistPermission = {
  __typename?: 'ArtistPermission';
  artistID: Scalars['String']['output'];
  canRead: Scalars['Boolean']['output'];
  canWrite: Scalars['Boolean']['output'];
};

export type GeneratedArtistTab = {
  __typename?: 'ArtistTab';
  artistID: Scalars['String']['output'];
  blockIDs: Array<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  overrideSize: GeneratedArtistBlockSize;
  priority: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type GeneratedArtistTabsBlocks = {
  __typename?: 'ArtistTabsBlocks';
  blocks: Array<GeneratedArtistBlock>;
  tabs: Array<GeneratedArtistTab>;
};

export type GeneratedAuthenticateNftPrintInput = {
  delegateCash?: InputMaybe<GeneratedDelegateCashInput>;
  delegateCashChain?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedBlockchainGetAllNftsOfContractInput = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
};

export type GeneratedBlockchainGetDelegationsByDelegateInput = {
  chain: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedBlockchainGetInfoForTokensInput = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  delegateCash?: InputMaybe<GeneratedDelegateCashInput>;
  tokens: Array<Scalars['String']['input']>;
};

export type GeneratedBlockchainIsNftTokenOwnedByWalletInput = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  delegateCash?: InputMaybe<GeneratedDelegateCashInput>;
  token: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedBlockchainNftTokenInfo = {
  __typename?: 'BlockchainNftTokenInfo';
  id: Scalars['String']['output'];
  metadataUri: Scalars['String']['output'];
  owner: Scalars['String']['output'];
};

export type GeneratedBlockchainOwnedNftTokensInContractInput = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  delegateCash?: InputMaybe<GeneratedDelegateCashInput>;
  walletAddress: Scalars['String']['input'];
};

export type GeneratedBlockchainTokenOwnerPair = {
  __typename?: 'BlockchainTokenOwnerPair';
  owner: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type GeneratedBlockchainValidateGatingInput = {
  chain: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  delegateCash?: InputMaybe<GeneratedDelegateCashInput>;
  gatingQuery: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedCalculateOrderPricingInputItem = {
  quantity: Scalars['Int']['input'];
  variantId: Scalars['String']['input'];
};

export type GeneratedCatalogueProduct = {
  __typename?: 'CatalogueProduct';
  comment?: Maybe<GeneratedPrivateLinkProductComment>;
  isEnquiry?: Maybe<Scalars['Boolean']['output']>;
  shopifyProductId?: Maybe<Scalars['String']['output']>;
  shopifyVariantId?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  type: GeneratedProductReferenceType;
  unlockCode: Scalars['String']['output'];
};

export type GeneratedCheckout = {
  __typename?: 'Checkout';
  paymentProvider: GeneratedPaymentProvider;
  pricing: GeneratedOrdersPriceCalculation;
  providerPaymentClientSecret: Scalars['String']['output'];
  providerPaymentIntentId: Scalars['String']['output'];
};

export type GeneratedCreateOrUpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  customsCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  provinceCode?: InputMaybe<Scalars['String']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedCreateReviewInput = {
  communicationOk?: InputMaybe<Scalars['Boolean']['input']>;
  consent: Scalars['Boolean']['input'];
  framedOk?: InputMaybe<Scalars['Boolean']['input']>;
  freeText: Scalars['String']['input'];
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  packagingOk?: InputMaybe<Scalars['Boolean']['input']>;
  pieceOk?: InputMaybe<Scalars['Boolean']['input']>;
  shopifyOrderId: Scalars['String']['input'];
  shopifyProductId: Scalars['String']['input'];
};

export type GeneratedCreateReviewResponse = {
  __typename?: 'CreateReviewResponse';
  id: Scalars['ID']['output'];
};

export type GeneratedCreateSubscriptionsInput = {
  name: Scalars['String']['input'];
  objectId: Scalars['String']['input'];
  ref: Scalars['String']['input'];
  type: GeneratedSubscriptionType;
};

export type GeneratedCreateSubscriptionsResponse = {
  __typename?: 'CreateSubscriptionsResponse';
  subscribed: Scalars['Boolean']['output'];
};

export type GeneratedDelegateCashInput = {
  vault: Scalars['String']['input'];
};

export type GeneratedDelegateCashOutput = {
  __typename?: 'DelegateCashOutput';
  contract: Scalars['String']['output'];
  delegate: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  vault: Scalars['String']['output'];
};

export type GeneratedDraw = {
  __typename?: 'Draw';
  accountIsInDraw: Scalars['Boolean']['output'];
  allocation: Scalars['Int']['output'];
  closeAt: Scalars['Time']['output'];
  createdAt: Scalars['Time']['output'];
  cryptoComEnabled: Scalars['Boolean']['output'];
  drawType: GeneratedDrawType;
  drawnAt?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  openAt: Scalars['Time']['output'];
  preOrderCloseAt?: Maybe<Scalars['Time']['output']>;
  product?: Maybe<GeneratedProduct>;
  productId: Scalars['String']['output'];
  productType: GeneratedDrawProductType;
  productVariantId: Scalars['String']['output'];
  status: GeneratedDrawStatus;
  totalAccounts: Scalars['Int']['output'];
};

export type GeneratedDrawEntryIntent = {
  __typename?: 'DrawEntryIntent';
  confirmedAt?: Maybe<Scalars['Time']['output']>;
  cryptoPaymentRequested: Scalars['Boolean']['output'];
  discardedAt?: Maybe<Scalars['Time']['output']>;
  draw: GeneratedDraw;
  drawId: Scalars['String']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<GeneratedPaymentMethod>;
  preOrder: Scalars['Boolean']['output'];
};

export type GeneratedDrawEntryIntentCreateResponse = {
  __typename?: 'DrawEntryIntentCreateResponse';
  intent: GeneratedDrawEntryIntent;
  pricing: GeneratedOrderPricingQuote;
  setupSecret: Scalars['String']['output'];
};

export type GeneratedDrawEntryIntentUpdateResponse = {
  __typename?: 'DrawEntryIntentUpdateResponse';
  cryptoComPaymentId?: Maybe<Scalars['String']['output']>;
  intent: GeneratedDrawEntryIntent;
  pricing: GeneratedOrderPricingQuote;
  readyToConfirm: Scalars['Boolean']['output'];
};

export enum GeneratedDrawProductType {
  Nft = 'NFT',
  Shopify = 'SHOPIFY',
  Unspecified = 'UNSPECIFIED',
}

export enum GeneratedDrawStatus {
  Closed = 'CLOSED',
  Drawn = 'DRAWN',
  Open = 'OPEN',
  Unspecified = 'UNSPECIFIED',
}

export enum GeneratedDrawType {
  Basic = 'BASIC',
  PreAuth = 'PRE_AUTH',
  Unspecified = 'UNSPECIFIED',
}

export enum GeneratedErrorCode {
  InvalidInput = 'INVALID_INPUT',
  SystemError = 'SYSTEM_ERROR',
}

export type GeneratedEventArte = {
  __typename?: 'EventArte';
  artistAccountIDs: Array<Scalars['String']['output']>;
  artistIDs: Array<Scalars['String']['output']>;
  artists: Array<GeneratedArtist>;
  chatPosterURL: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endTime?: Maybe<Scalars['Time']['output']>;
  giveaways: Array<GeneratedEventArteGiveaway>;
  id: Scalars['ID']['output'];
  journalURL: Scalars['String']['output'];
  location: Scalars['String']['output'];
  medias: Array<GeneratedEventArteMedia>;
  posterURL: Scalars['String']['output'];
  productInfo: Scalars['String']['output'];
  products: Array<GeneratedEventArteProduct>;
  replayReady: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  startTime: Scalars['Time']['output'];
  subtitle: Scalars['String']['output'];
  timeline: Array<GeneratedEventArteTimeline>;
  title: Scalars['String']['output'];
  vimeoClipID: Scalars['String']['output'];
  vimeoEventID: Scalars['String']['output'];
};

export type GeneratedEventArteGiveaway = {
  __typename?: 'EventArteGiveaway';
  endTime: Scalars['Time']['output'];
  entryTriggerEmoji: Scalars['String']['output'];
  entryTriggerWord: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startTime: Scalars['Time']['output'];
  winnerUsername: Scalars['String']['output'];
};

export type GeneratedEventArteMedia = {
  __typename?: 'EventArteMedia';
  coverUrl: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  mediaType: GeneratedEventArteMediaType;
  url: Scalars['String']['output'];
};

export enum GeneratedEventArteMediaType {
  Image = 'IMAGE',
  Unspecified = 'UNSPECIFIED',
  Video = 'VIDEO',
}

export type GeneratedEventArteProduct = {
  __typename?: 'EventArteProduct';
  id: Scalars['ID']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  shopifyID?: Maybe<Scalars['String']['output']>;
  shopifyProduct?: Maybe<GeneratedStoryblokProduct>;
};

export type GeneratedEventArteTimeline = {
  __typename?: 'EventArteTimeline';
  description: Scalars['String']['output'];
  time: Scalars['Time']['output'];
};

export type GeneratedEventartesConnection = {
  __typename?: 'EventartesConnection';
  cursor?: Maybe<Scalars['String']['output']>;
  events: Array<GeneratedEventArte>;
};

export type GeneratedExchangeRate = {
  __typename?: 'ExchangeRate';
  currency: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GeneratedFinancialTransaction = {
  __typename?: 'FinancialTransaction';
  brand?: Maybe<Scalars['String']['output']>;
  friendlyShopifyGateway: Scalars['String']['output'];
  redactedPaymentIdentifier?: Maybe<Scalars['String']['output']>;
  shopifyGateway: Scalars['String']['output'];
  type: GeneratedFinancialTransactionType;
};

export enum GeneratedFinancialTransactionType {
  FinancialTransactionTypeBankCard = 'FINANCIAL_TRANSACTION_TYPE_BANK_CARD',
  FinancialTransactionTypeBankTransfer = 'FINANCIAL_TRANSACTION_TYPE_BANK_TRANSFER',
  FinancialTransactionTypeThirdParty = 'FINANCIAL_TRANSACTION_TYPE_THIRD_PARTY',
  FinancialTransactionTypeUnspecified = 'FINANCIAL_TRANSACTION_TYPE_UNSPECIFIED',
}

export type GeneratedFramer = {
  __typename?: 'Framer';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  googleURL: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: Array<Scalars['String']['output']>;
  instagram: Scalars['String']['output'];
  l10n: Array<GeneratedFramerL10n>;
  name: Scalars['String']['output'];
  openingHours: Array<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  ratingsTotal: Scalars['Int']['output'];
  services: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  suggestedBy: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type GeneratedFramerL10n = {
  __typename?: 'FramerL10n';
  description: Scalars['String']['output'];
  locale: Scalars['String']['output'];
};

export type GeneratedFramersCity = {
  __typename?: 'FramersCity';
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GeneratedFramersConnection = {
  __typename?: 'FramersConnection';
  cursor?: Maybe<Scalars['String']['output']>;
  framers: Array<GeneratedFramer>;
};

export type GeneratedFramersCountry = {
  __typename?: 'FramersCountry';
  cities: Array<GeneratedFramersCity>;
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GeneratedFramersOptions = {
  __typename?: 'FramersOptions';
  countries: Array<GeneratedFramersCountry>;
  locales: Array<Scalars['String']['output']>;
  services: Array<Scalars['String']['output']>;
};

export type GeneratedFullNftPrintResponse = {
  __typename?: 'FullNftPrintResponse';
  events: Array<Maybe<GeneratedNftPrintEvent>>;
  ownerEmailHint: Scalars['String']['output'];
  print: GeneratedNftPrint;
};

export type GeneratedGenerateAvantPassTokenInput = {
  delegateCashVault?: InputMaybe<Scalars['String']['input']>;
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedGenerateAvantPassTokenResult = {
  __typename?: 'GenerateAvantPassTokenResult';
  token: Scalars['String']['output'];
};

export type GeneratedGenerateMultipassTokenResult = {
  __typename?: 'GenerateMultipassTokenResult';
  token: Scalars['String']['output'];
};

export type GeneratedGetNftPrintsForProductResponse = {
  __typename?: 'GetNftPrintsForProductResponse';
  prints: Array<Maybe<GeneratedNftPrint>>;
};

export type GeneratedIsSubscribedResponse = {
  __typename?: 'IsSubscribedResponse';
  subscriptions: Array<GeneratedIsSubscribedResponseItem>;
};

export type GeneratedIsSubscribedResponseItem = {
  __typename?: 'IsSubscribedResponseItem';
  objectId: Scalars['String']['output'];
  sType: GeneratedSubscriptionType;
  subscribed: Scalars['Boolean']['output'];
};

export type GeneratedIsSubscribedSubscriptionsInput = {
  objectId: Scalars['String']['input'];
  type: GeneratedSubscriptionType;
};

export enum GeneratedItemFulfillmentStatus {
  ItemFulfillmentStatusAttemptedDelivery = 'ITEM_FULFILLMENT_STATUS_ATTEMPTED_DELIVERY',
  ItemFulfillmentStatusCanceled = 'ITEM_FULFILLMENT_STATUS_CANCELED',
  ItemFulfillmentStatusConfirmed = 'ITEM_FULFILLMENT_STATUS_CONFIRMED',
  ItemFulfillmentStatusDelivered = 'ITEM_FULFILLMENT_STATUS_DELIVERED',
  ItemFulfillmentStatusFailure = 'ITEM_FULFILLMENT_STATUS_FAILURE',
  ItemFulfillmentStatusFulfilled = 'ITEM_FULFILLMENT_STATUS_FULFILLED',
  ItemFulfillmentStatusInTransit = 'ITEM_FULFILLMENT_STATUS_IN_TRANSIT',
  ItemFulfillmentStatusLabelPrinted = 'ITEM_FULFILLMENT_STATUS_LABEL_PRINTED',
  ItemFulfillmentStatusLabelPurchased = 'ITEM_FULFILLMENT_STATUS_LABEL_PURCHASED',
  ItemFulfillmentStatusLabelVoided = 'ITEM_FULFILLMENT_STATUS_LABEL_VOIDED',
  ItemFulfillmentStatusMarkedAsFulfilled = 'ITEM_FULFILLMENT_STATUS_MARKED_AS_FULFILLED',
  ItemFulfillmentStatusNotDelivered = 'ITEM_FULFILLMENT_STATUS_NOT_DELIVERED',
  ItemFulfillmentStatusOutForDelivery = 'ITEM_FULFILLMENT_STATUS_OUT_FOR_DELIVERY',
  ItemFulfillmentStatusPickedUp = 'ITEM_FULFILLMENT_STATUS_PICKED_UP',
  ItemFulfillmentStatusReadyForPickup = 'ITEM_FULFILLMENT_STATUS_READY_FOR_PICKUP',
  ItemFulfillmentStatusSubmitted = 'ITEM_FULFILLMENT_STATUS_SUBMITTED',
  ItemFulfillmentStatusUnspecified = 'ITEM_FULFILLMENT_STATUS_UNSPECIFIED',
}

export type GeneratedListOrdersResponse = {
  __typename?: 'ListOrdersResponse';
  nextPageToken: Scalars['String']['output'];
  orders: Array<GeneratedOrder>;
};

export type GeneratedMoneySet = {
  __typename?: 'MoneySet';
  amount: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
};

export type GeneratedMutation = {
  __typename?: 'Mutation';
  CreateReview: GeneratedCreateReviewResponse;
  HubspotWhitepaperList: Scalars['Boolean']['output'];
  SlackMessage: Scalars['Boolean']['output'];
  /** Upload an image by a url scoped to specifc artist, requires write access to the artist */
  artistUploadByURL: Scalars['String']['output'];
  authenticateNftPrint: GeneratedFullNftPrintResponse;
  /** Create a new address for the current user */
  createAddress: GeneratedAddress;
  createCheckout: GeneratedCheckout;
  createDrawEntryIntent?: Maybe<GeneratedDrawEntryIntentCreateResponse>;
  createSubscriptions: GeneratedCreateSubscriptionsResponse;
  /** Delete one of the users addresses */
  deleteAddress: Scalars['Boolean']['output'];
  deleteArtistBlock: Scalars['Boolean']['output'];
  deleteArtistTab: Scalars['Boolean']['output'];
  detachPaymentMethod?: Maybe<Scalars['ID']['output']>;
  enterDraw: GeneratedDraw;
  eventarteJoinedChat: Scalars['Boolean']['output'];
  framerContact: Scalars['Boolean']['output'];
  generateAvantPassToken: GeneratedGenerateAvantPassTokenResult;
  generateMultipassToken: GeneratedGenerateMultipassTokenResult;
  leaveDraw: GeneratedDraw;
  /** send the login code to user email account */
  login: Scalars['Boolean']['output'];
  logout: Scalars['Boolean']['output'];
  signup: Scalars['Boolean']['output'];
  signupAndSubscribe: Scalars['Boolean']['output'];
  /** update a users account details, any of these fields can be set, an un set field changes nothing */
  updateAccount: GeneratedAccount;
  /** Update one of the users addresses */
  updateAddress: GeneratedAddress;
  updateCheckout: GeneratedCheckout;
  updateDrawEntryIntent?: Maybe<GeneratedDrawEntryIntentUpdateResponse>;
  updateOrder: Scalars['Boolean']['output'];
  updateQuestionnaire: GeneratedQuestionnaire;
  upsertArtistBlock: GeneratedArtistBlock;
  upsertArtistTab: GeneratedArtistTab;
  /** useLoginCode exchanges a code received via email with a set of tokens or login/signup with google credentials */
  useLoginCode: GeneratedUseLoginCodeResult;
  web3GenerateNonce: Scalars['String']['output'];
  web3VerifySignature: Scalars['Boolean']['output'];
};

export type GeneratedMutationCreateReviewArgs = {
  input: GeneratedCreateReviewInput;
};

export type GeneratedMutationHubspotWhitepaperListArgs = {
  captcha: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type GeneratedMutationSlackMessageArgs = {
  captcha: Scalars['String']['input'];
  channel: GeneratedSlackChannel;
  msg: Scalars['String']['input'];
};

export type GeneratedMutationArtistUploadByUrlArgs = {
  artistID: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type GeneratedMutationAuthenticateNftPrintArgs = {
  input: GeneratedAuthenticateNftPrintInput;
};

export type GeneratedMutationCreateAddressArgs = {
  address: GeneratedCreateOrUpdateAddressInput;
};

export type GeneratedMutationCreateCheckoutArgs = {
  currencyCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  items: Array<InputMaybe<GeneratedOrderItem>>;
  paymentProvider: GeneratedPaymentProvider;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GeneratedMutationCreateDrawEntryIntentArgs = {
  cryptoPaymentRequested?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode: Scalars['String']['input'];
  drawId: Scalars['ID']['input'];
  optionalItems?: InputMaybe<Array<InputMaybe<GeneratedOrderItem>>>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type GeneratedMutationCreateSubscriptionsArgs = {
  UTMCampaign?: InputMaybe<Scalars['String']['input']>;
  UTMMedium?: InputMaybe<Scalars['String']['input']>;
  UTMSource?: InputMaybe<Scalars['String']['input']>;
  subscriptions: Array<GeneratedCreateSubscriptionsInput>;
};

export type GeneratedMutationDeleteAddressArgs = {
  addressId: Scalars['String']['input'];
};

export type GeneratedMutationDeleteArtistBlockArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedMutationDeleteArtistTabArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedMutationDetachPaymentMethodArgs = {
  id: Scalars['ID']['input'];
  providerId: GeneratedPaymentProvider;
};

export type GeneratedMutationEnterDrawArgs = {
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  refID?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedMutationEventarteJoinedChatArgs = {
  eventID: Scalars['ID']['input'];
};

export type GeneratedMutationFramerContactArgs = {
  captcha: Scalars['String']['input'];
  email: Scalars['String']['input'];
  framerId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type GeneratedMutationGenerateAvantPassTokenArgs = {
  input: GeneratedGenerateAvantPassTokenInput;
};

export type GeneratedMutationGenerateMultipassTokenArgs = {
  returnTo: Scalars['String']['input'];
};

export type GeneratedMutationLeaveDrawArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedMutationLoginArgs = {
  captcha?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedMutationSignupArgs = {
  acceptsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  captcha?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedMutationSignupAndSubscribeArgs = {
  captcha?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  origin?: InputMaybe<Scalars['String']['input']>;
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
  silenceLoginCodeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptions?: InputMaybe<Array<GeneratedCreateSubscriptionsInput>>;
};

export type GeneratedMutationUpdateAccountArgs = {
  acceptsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  avatarURL?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<GeneratedPronouns>;
  username?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedMutationUpdateAddressArgs = {
  addressId: Scalars['String']['input'];
  changes: GeneratedCreateOrUpdateAddressInput;
};

export type GeneratedMutationUpdateCheckoutArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<InputMaybe<GeneratedOrderItem>>>;
  paymentProvider: GeneratedPaymentProvider;
  providerPaymentIntentId: Scalars['String']['input'];
  shippingAddress?: InputMaybe<GeneratedOrderAddressInput>;
  tags?: InputMaybe<GeneratedOrderTagsInput>;
};

export type GeneratedMutationUpdateDrawEntryIntentArgs = {
  cryptoPaymentRequested?: InputMaybe<Scalars['Boolean']['input']>;
  currencyCode: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  optionalItems?: InputMaybe<Array<InputMaybe<GeneratedOrderItem>>>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type GeneratedMutationUpdateOrderArgs = {
  id: Scalars['ID']['input'];
  shippingAddress?: InputMaybe<GeneratedCreateOrUpdateAddressInput>;
};

export type GeneratedMutationUpdateQuestionnaireArgs = {
  input: GeneratedUpdateQuestionnaireInput;
};

export type GeneratedMutationUpsertArtistBlockArgs = {
  input: GeneratedUpsertArtistBlockInput;
};

export type GeneratedMutationUpsertArtistTabArgs = {
  input: GeneratedUpsertArtistTabInput;
};

export type GeneratedMutationUseLoginCodeArgs = {
  acceptsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  googleCredential?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedMutationWeb3GenerateNonceArgs = {
  message: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedMutationWeb3VerifySignatureArgs = {
  signature: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedNftGetVouchersResponse = {
  __typename?: 'NFTGetVouchersResponse';
  vouchers: Array<Maybe<GeneratedNftUnSignedVoucherData>>;
};

export type GeneratedNftSignedVoucher = {
  __typename?: 'NFTSignedVoucher';
  price: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
  validUntil: Scalars['String']['output'];
  voucherId: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type GeneratedNftSignedVoucherData = {
  __typename?: 'NFTSignedVoucherData';
  claimed: Scalars['Boolean']['output'];
  voucher: GeneratedNftSignedVoucher;
};

export type GeneratedNftSignedVoucherResponse = {
  __typename?: 'NFTSignedVoucherResponse';
  claimed: Scalars['Boolean']['output'];
  voucher: GeneratedNftSignedVoucher;
};

export type GeneratedNftUnSignedVoucherData = {
  __typename?: 'NFTUnSignedVoucherData';
  claimed: Scalars['Boolean']['output'];
  voucher: GeneratedNftVoucherMetadata;
};

export type GeneratedNftVoucherMetadata = {
  __typename?: 'NFTVoucherMetadata';
  chainId: Scalars['String']['output'];
  nftContractAddress: Scalars['String']['output'];
  price: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
  validUntil: Scalars['String']['output'];
  voucherId: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type GeneratedNftPrint = {
  __typename?: 'NftPrint';
  authenticated: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  orderedAt: Scalars['Time']['output'];
  owner: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
};

export type GeneratedNftPrintEvent = {
  __typename?: 'NftPrintEvent';
  authenticated: Scalars['Boolean']['output'];
  createTime: Scalars['Time']['output'];
  event: Scalars['String']['output'];
  id: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  productId: Scalars['String']['output'];
};

export type GeneratedNftSignedVoucherV2 = {
  __typename?: 'NftSignedVoucherV2';
  price: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
  validUntil: Scalars['String']['output'];
  voucherId: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export type GeneratedNftVoucherClaimStatus = {
  __typename?: 'NftVoucherClaimStatus';
  claimed: Scalars['Boolean']['output'];
  claimer: Scalars['String']['output'];
};

export type GeneratedNftVoucherV2 = {
  __typename?: 'NftVoucherV2';
  accountId: Scalars['String']['output'];
  chainId: Scalars['String']['output'];
  claimed: Scalars['Boolean']['output'];
  claimer?: Maybe<Scalars['String']['output']>;
  contractAddress: Scalars['String']['output'];
  createTime: Scalars['Time']['output'];
  editionNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nftContractAddress: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  price: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
  tokenStandard: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updateTime: Scalars['Time']['output'];
  validUntil: Scalars['Int']['output'];
  walletAddress: Scalars['String']['output'];
};

export type GeneratedOrder = {
  __typename?: 'Order';
  billingAddress?: Maybe<GeneratedOrderAddress>;
  cancelledAt?: Maybe<Scalars['Time']['output']>;
  financialStatus: GeneratedOrderFinancialStatus;
  financialTransactions: Array<GeneratedFinancialTransaction>;
  fulfillmentStatus: GeneratedOrderFulfillmentStatus;
  id: Scalars['ID']['output'];
  invoiceURL: Scalars['String']['output'];
  lineItems: Array<GeneratedOrderLineItem>;
  processedAt: Scalars['Time']['output'];
  resaleAgreementURL: Scalars['String']['output'];
  shippingAddress?: Maybe<GeneratedOrderAddress>;
  shippingMethods: Array<Scalars['String']['output']>;
  shopifyId: Scalars['String']['output'];
  shopifyOrderNumber: Scalars['String']['output'];
  subtotalPriceSet?: Maybe<GeneratedMoneySet>;
  totalDiscountsPricesSet?: Maybe<GeneratedMoneySet>;
  totalPriceSet?: Maybe<GeneratedMoneySet>;
  totalShippingPricesSet?: Maybe<GeneratedMoneySet>;
  totalTaxPriceSet?: Maybe<GeneratedMoneySet>;
};

export type GeneratedOrderAddress = {
  __typename?: 'OrderAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  provinceCode?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type GeneratedOrderAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  provinceCode?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export enum GeneratedOrderFinancialStatus {
  FinancialStatusAuthorized = 'FINANCIAL_STATUS_AUTHORIZED',
  FinancialStatusExpired = 'FINANCIAL_STATUS_EXPIRED',
  FinancialStatusPaid = 'FINANCIAL_STATUS_PAID',
  FinancialStatusPartiallyPaid = 'FINANCIAL_STATUS_PARTIALLY_PAID',
  FinancialStatusPartiallyRefunded = 'FINANCIAL_STATUS_PARTIALLY_REFUNDED',
  FinancialStatusPending = 'FINANCIAL_STATUS_PENDING',
  FinancialStatusRefunded = 'FINANCIAL_STATUS_REFUNDED',
  FinancialStatusUnspecified = 'FINANCIAL_STATUS_UNSPECIFIED',
  FinancialStatusVoided = 'FINANCIAL_STATUS_VOIDED',
}

export enum GeneratedOrderFulfillmentStatus {
  OrderFulfillmentStatusFulfilled = 'ORDER_FULFILLMENT_STATUS_FULFILLED',
  OrderFulfillmentStatusInProgress = 'ORDER_FULFILLMENT_STATUS_IN_PROGRESS',
  OrderFulfillmentStatusOnHold = 'ORDER_FULFILLMENT_STATUS_ON_HOLD',
  OrderFulfillmentStatusOpen = 'ORDER_FULFILLMENT_STATUS_OPEN',
  OrderFulfillmentStatusPartiallyFulfilled = 'ORDER_FULFILLMENT_STATUS_PARTIALLY_FULFILLED',
  OrderFulfillmentStatusPendingFulfillment = 'ORDER_FULFILLMENT_STATUS_PENDING_FULFILLMENT',
  OrderFulfillmentStatusRestocked = 'ORDER_FULFILLMENT_STATUS_RESTOCKED',
  OrderFulfillmentStatusScheduled = 'ORDER_FULFILLMENT_STATUS_SCHEDULED',
  OrderFulfillmentStatusUnfulfilled = 'ORDER_FULFILLMENT_STATUS_UNFULFILLED',
  OrderFulfillmentStatusUnspecified = 'ORDER_FULFILLMENT_STATUS_UNSPECIFIED',
}

export type GeneratedOrderItem = {
  productID: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  variantID: Scalars['ID']['input'];
};

export type GeneratedOrderLineItem = {
  __typename?: 'OrderLineItem';
  deliveredAt?: Maybe<Scalars['Time']['output']>;
  featuredImageUrl: Scalars['String']['output'];
  framedDispatchDate?: Maybe<Scalars['Time']['output']>;
  framingOffered: Scalars['String']['output'];
  fulfillmentStatus: GeneratedItemFulfillmentStatus;
  inTransitAt?: Maybe<Scalars['Time']['output']>;
  launchDate?: Maybe<Scalars['Time']['output']>;
  price: Scalars['String']['output'];
  priceCurrencyCode: Scalars['String']['output'];
  productType: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  shopifyId: Scalars['String']['output'];
  shopifyProductId: Scalars['String']['output'];
  shopifyVariantId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
  unframedDispatchDate?: Maybe<Scalars['Time']['output']>;
  variantTitle?: Maybe<Scalars['String']['output']>;
};

export type GeneratedOrderPricingQuote = {
  __typename?: 'OrderPricingQuote';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  pricing: GeneratedOrdersPriceCalculation;
};

export type GeneratedOrderTagsInput = {
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GeneratedOrdersItemPricing = {
  __typename?: 'OrdersItemPricing';
  carrierServiceName: Scalars['String']['output'];
  freeShipping: Scalars['Boolean']['output'];
  isTaxable: Scalars['Boolean']['output'];
  price: Scalars['Int']['output'];
  productId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  requiresShipping: Scalars['Boolean']['output'];
  shippingProviderId: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  variantId: Scalars['ID']['output'];
};

export type GeneratedOrdersPriceCalculation = {
  __typename?: 'OrdersPriceCalculation';
  breakdown: Array<GeneratedOrdersItemPricing>;
  currencyCode: Scalars['String']['output'];
  includesCalculatedShipping: Scalars['Boolean']['output'];
  includesCalculatedTaxes: Scalars['Boolean']['output'];
  subtotalItemsPrice: Scalars['Int']['output'];
  subtotalShippingPrice: Scalars['Int']['output'];
  subtotalTaxPrice: Scalars['Int']['output'];
  taxRate: Scalars['Float']['output'];
  taxType: GeneratedTaxType;
  totalDiscount: Scalars['Int']['output'];
  totalPrice: Scalars['Int']['output'];
};

export type GeneratedPaymentMethod = {
  __typename?: 'PaymentMethod';
  billingAddress?: Maybe<GeneratedPaymentMethodBillingAddress>;
  card?: Maybe<GeneratedPaymentMethodCard>;
  default: Scalars['Boolean']['output'];
  providerId: GeneratedPaymentProvider;
  providerPaymentMethodId: Scalars['String']['output'];
  type: GeneratedPaymentMethodType;
};

export type GeneratedPaymentMethodBillingAddress = {
  __typename?: 'PaymentMethodBillingAddress';
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GeneratedPaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  expireMonth: Scalars['Int']['output'];
  expireYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
  wallet?: Maybe<GeneratedPaymentMethodCardWallet>;
};

export type GeneratedPaymentMethodCardWallet = {
  __typename?: 'PaymentMethodCardWallet';
  type: GeneratedPaymentMethodWalletType;
};

export enum GeneratedPaymentMethodType {
  Card = 'CARD',
  Unspecified = 'UNSPECIFIED',
}

export enum GeneratedPaymentMethodWalletType {
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  Unspecified = 'UNSPECIFIED',
}

export enum GeneratedPaymentProvider {
  CryptoCom = 'CRYPTO_COM',
  Stripe = 'STRIPE',
  Unspecified = 'UNSPECIFIED',
}

export type GeneratedPrivateLink = {
  __typename?: 'PrivateLink';
  expiresAt?: Maybe<Scalars['Time']['output']>;
  heading: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  intro: Scalars['String']['output'];
  passwordRequired: Scalars['Boolean']['output'];
  products?: Maybe<Array<GeneratedCatalogueProduct>>;
  slug: Scalars['String']['output'];
  strapline: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type GeneratedPrivateLinkProductComment = {
  __typename?: 'PrivateLinkProductComment';
  avatarUrl: Scalars['String']['output'];
  body: Scalars['String']['output'];
};

export type GeneratedProduct = {
  __typename?: 'Product';
  artistIds: Array<Scalars['String']['output']>;
  artists: Array<GeneratedArtist>;
  availability: GeneratedProductAvailability;
  closeAt?: Maybe<Scalars['Time']['output']>;
  cryptoDisplayPrice: Scalars['String']['output'];
  drawLaunch: Scalars['Boolean']['output'];
  editionSize: Scalars['Int']['output'];
  framedByDefault: Scalars['Boolean']['output'];
  framedDispatchDate?: Maybe<Scalars['Time']['output']>;
  framingOffered: Scalars['String']['output'];
  framingUpsellEndTime?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  images: Array<GeneratedProductImage>;
  isFrame: Scalars['Boolean']['output'];
  launchAt?: Maybe<Scalars['Time']['output']>;
  medium: GeneratedProductMedium;
  price: Scalars['Float']['output'];
  releaseType: GeneratedProductReleaseType;
  shippingUpdateEndTime?: Maybe<Scalars['Time']['output']>;
  shopifyId: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  thumbnail?: Maybe<GeneratedProductImage>;
  title: Scalars['String']['output'];
  unframedDispatchDate?: Maybe<Scalars['Time']['output']>;
  variants: Array<GeneratedProductVariant>;
};

export enum GeneratedProductAvailability {
  Available = 'AVAILABLE',
  Sold = 'SOLD',
  Soon = 'SOON',
  Unspecified = 'UNSPECIFIED',
}

export type GeneratedProductImage = {
  __typename?: 'ProductImage';
  alt?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export enum GeneratedProductMedium {
  Collectible = 'COLLECTIBLE',
  HandFinished = 'HAND_FINISHED',
  Nft = 'NFT',
  Original = 'ORIGINAL',
  Print = 'PRINT',
  Sculpture = 'SCULPTURE',
  StudioWorks = 'STUDIO_WORKS',
  Unspecified = 'UNSPECIFIED',
}

export enum GeneratedProductReferenceType {
  ProductReferenceTypeShopify = 'PRODUCT_REFERENCE_TYPE_SHOPIFY',
  ProductReferenceTypeUnspecified = 'PRODUCT_REFERENCE_TYPE_UNSPECIFIED',
}

export enum GeneratedProductReleaseType {
  Auction = 'AUCTION',
  Draw = 'DRAW',
  NftGiveaway = 'NFT_GIVEAWAY',
  NftRankedAuction = 'NFT_RANKED_AUCTION',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Timed = 'TIMED',
  Unspecified = 'UNSPECIFIED',
}

export type GeneratedProductVariant = {
  __typename?: 'ProductVariant';
  available: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['String']['output'];
  requiresShipping: Scalars['Boolean']['output'];
  shopifyId: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum GeneratedPronouns {
  PronounsHeHim = 'PRONOUNS_HE_HIM',
  PronounsRatherNotSay = 'PRONOUNS_RATHER_NOT_SAY',
  PronounsSheHer = 'PRONOUNS_SHE_HER',
  PronounsTheyThem = 'PRONOUNS_THEY_THEM',
  PronounsUnspecified = 'PRONOUNS_UNSPECIFIED',
}

export type GeneratedQuery = {
  __typename?: 'Query';
  /** Passing no ID into account, will return your own account */
  account?: Maybe<GeneratedAccount>;
  /** Get addresses for the current user */
  addresses: Array<GeneratedAddress>;
  /** Get all the artists with arte.bio profile */
  arteBioArtists: Array<GeneratedArtist>;
  /** URL to download a csv of the arte.bio subscriptions of an artist, requires read access to the artist */
  arteBioSubscriptionsExport: Scalars['String']['output'];
  /** Get an artist */
  artist?: Maybe<GeneratedArtist>;
  /** Get all blocks of the artist in chronological order regardless if they are in a tab or not */
  artistBlocks: Array<GeneratedArtistBlock>;
  /** Get all tabs of the artist, optionally return all blocks associated with the tabs */
  artistTabsBlocks: GeneratedArtistTabsBlocks;
  /** Get artists by ids */
  artists: Array<GeneratedArtist>;
  /** Get artists by slugs */
  artistsBySlug: Array<GeneratedArtist>;
  /** Get all artists that the user has permission to read or write */
  artistsPermission: Array<GeneratedArtistPermission>;
  /** returns a list of all the tokens on a contract */
  blockchainGetAllNftsOfContract: Array<GeneratedBlockchainTokenOwnerPair>;
  blockchainGetDelegationsByDelegate: Array<Maybe<GeneratedDelegateCashOutput>>;
  /** returns token info for a given list of tokens */
  blockchainGetInfoForTokens: Array<Maybe<GeneratedBlockchainNftTokenInfo>>;
  /** validates the wallet address and account, and returns a signed voucher */
  blockchainGetSignedNftVoucher: GeneratedNftSignedVoucherV2;
  /** check if a voucher was claimed and by whom */
  blockchainGetVoucherClaimStatus: GeneratedNftVoucherClaimStatus;
  /** returns weather or not an NFT is owned by a wallet */
  blockchainIsNftTokenOwnedByWallet: Scalars['Boolean']['output'];
  /** returns a list of tokens owned by a wallet in a specified contract */
  blockchainOwnedNftTokensInContract: Array<
    Maybe<GeneratedBlockchainNftTokenInfo>
  >;
  /** checks if a wallet passes the gating query for a contract */
  blockchainValidateGating: Scalars['Boolean']['output'];
  /** calculate the price for an order, with a full breakdown for transparency */
  calculateOrderPricing: GeneratedOrdersPriceCalculation;
  draw: GeneratedDraw;
  euroFx: Array<GeneratedExchangeRate>;
  eventarte?: Maybe<GeneratedEventArte>;
  eventarteHlsURL: Scalars['String']['output'];
  eventartes?: Maybe<GeneratedEventartesConnection>;
  framer?: Maybe<GeneratedFramer>;
  framers?: Maybe<GeneratedFramersConnection>;
  framersOptions?: Maybe<GeneratedFramersOptions>;
  getDrawByProductIDs: Array<GeneratedDraw>;
  getDrawByProductId: GeneratedDraw;
  getDrawEntryIntent?: Maybe<GeneratedDrawEntryIntent>;
  getFullNftPrint: GeneratedFullNftPrintResponse;
  getNftPrintsForProduct: GeneratedGetNftPrintsForProductResponse;
  getProducts: Array<GeneratedProduct>;
  getQuestionnaire?: Maybe<GeneratedQuestionnaire>;
  getShippingRateByProductVariantID: GeneratedShippingRate;
  /** Gets the tax information for a specific country code */
  getTaxesByCountryCode: GeneratedTax;
  getVoucherByTokenIdAndProjectId: Array<Maybe<GeneratedNftVoucherV2>>;
  getVouchersForAccount: Array<Maybe<GeneratedNftVoucherV2>>;
  isSubscribed: GeneratedIsSubscribedResponse;
  listDrawEntryIntent: Array<GeneratedDrawEntryIntent>;
  listOrders?: Maybe<GeneratedListOrdersResponse>;
  listPaymentMethods: Array<GeneratedPaymentMethod>;
  nftGetSignedVoucher: GeneratedNftSignedVoucherResponse;
  nftVoucherByVoucherNumber: GeneratedSpecificVoucherResponse;
  nftsGetVouchers: GeneratedNftGetVouchersResponse;
  order: GeneratedOrder;
  /** Generate a new short-lived token to access Stream API (chat) */
  refreshStreamToken: Scalars['String']['output'];
  reviews: Array<GeneratedReview>;
  searchAccounts?: Maybe<Array<GeneratedAccount>>;
  /** Search for artists */
  searchArtists: Array<GeneratedArtist>;
  subscriptionsCount: GeneratedSubscriptionsCount;
  unstable__privateLink: GeneratedPrivateLink;
  /** Get a link to a temporary upload space in Google Cloud */
  uploadURL: Scalars['String']['output'];
  voucherExistsForTokenId: Scalars['Boolean']['output'];
};

export type GeneratedQueryAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type GeneratedQueryArteBioSubscriptionsExportArgs = {
  artistID: Scalars['ID']['input'];
};

export type GeneratedQueryArtistArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedQueryArtistBlocksArgs = {
  artistID: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type GeneratedQueryArtistTabsBlocksArgs = {
  artistID: Scalars['ID']['input'];
  withBlocks?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeneratedQueryArtistsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GeneratedQueryArtistsBySlugArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GeneratedQueryBlockchainGetAllNftsOfContractArgs = {
  input: GeneratedBlockchainGetAllNftsOfContractInput;
};

export type GeneratedQueryBlockchainGetDelegationsByDelegateArgs = {
  input: GeneratedBlockchainGetDelegationsByDelegateInput;
};

export type GeneratedQueryBlockchainGetInfoForTokensArgs = {
  input?: InputMaybe<GeneratedBlockchainGetInfoForTokensInput>;
};

export type GeneratedQueryBlockchainGetSignedNftVoucherArgs = {
  voucherId: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedQueryBlockchainGetVoucherClaimStatusArgs = {
  chainId: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  voucherId: Scalars['String']['input'];
};

export type GeneratedQueryBlockchainIsNftTokenOwnedByWalletArgs = {
  input?: InputMaybe<GeneratedBlockchainIsNftTokenOwnedByWalletInput>;
};

export type GeneratedQueryBlockchainOwnedNftTokensInContractArgs = {
  input?: InputMaybe<GeneratedBlockchainOwnedNftTokensInContractInput>;
};

export type GeneratedQueryBlockchainValidateGatingArgs = {
  input?: InputMaybe<GeneratedBlockchainValidateGatingInput>;
};

export type GeneratedQueryCalculateOrderPricingArgs = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  currencyCode: Scalars['String']['input'];
  items: Array<InputMaybe<GeneratedCalculateOrderPricingInputItem>>;
};

export type GeneratedQueryDrawArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedQueryEventarteArgs = {
  idOrSlug: Scalars['ID']['input'];
};

export type GeneratedQueryEventarteHlsUrlArgs = {
  eventID: Scalars['ID']['input'];
};

export type GeneratedQueryEventartesArgs = {
  fromNow?: InputMaybe<Scalars['Boolean']['input']>;
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedQueryFramerArgs = {
  idOrSlug: Scalars['ID']['input'];
};

export type GeneratedQueryFramersArgs = {
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  services?: InputMaybe<Array<Scalars['String']['input']>>;
  suggested?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeneratedQueryGetDrawByProductIDsArgs = {
  productIDs: Array<Scalars['String']['input']>;
};

export type GeneratedQueryGetDrawByProductIdArgs = {
  productId: Scalars['String']['input'];
};

export type GeneratedQueryGetDrawEntryIntentArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedQueryGetFullNftPrintArgs = {
  productId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};

export type GeneratedQueryGetNftPrintsForProductArgs = {
  productId: Scalars['String']['input'];
};

export type GeneratedQueryGetProductsArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  previewToken?: InputMaybe<Scalars['String']['input']>;
  shopifyIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  slugs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GeneratedQueryGetShippingRateByProductVariantIdArgs = {
  countryCode: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  productVariantID: Scalars['String']['input'];
};

export type GeneratedQueryGetTaxesByCountryCodeArgs = {
  countryCode: Scalars['String']['input'];
};

export type GeneratedQueryGetVoucherByTokenIdAndProjectIdArgs = {
  projectId: Scalars['String']['input'];
  tokenId: Scalars['Int']['input'];
};

export type GeneratedQueryIsSubscribedArgs = {
  subscriptions: Array<GeneratedIsSubscribedSubscriptionsInput>;
};

export type GeneratedQueryListOrdersArgs = {
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedQueryNftGetSignedVoucherArgs = {
  voucherId: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type GeneratedQueryNftVoucherByVoucherNumberArgs = {
  productId: Scalars['String']['input'];
  voucherNumber: Scalars['Int']['input'];
};

export type GeneratedQueryNftsGetVouchersArgs = {
  productId: Scalars['String']['input'];
};

export type GeneratedQueryOrderArgs = {
  id: Scalars['ID']['input'];
};

export type GeneratedQueryReviewsArgs = {
  shopifyProductId?: InputMaybe<Scalars['String']['input']>;
};

export type GeneratedQuerySearchAccountsArgs = {
  query: Scalars['String']['input'];
};

export type GeneratedQuerySearchArtistsArgs = {
  query: Scalars['String']['input'];
};

export type GeneratedQuerySubscriptionsCountArgs = {
  objectId: Scalars['String']['input'];
  type: GeneratedSubscriptionType;
  withRandomAccounts: Scalars['Boolean']['input'];
};

export type GeneratedQueryUnstable__PrivateLinkArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type GeneratedQueryUploadUrlArgs = {
  contentType: Scalars['String']['input'];
};

export type GeneratedQueryVoucherExistsForTokenIdArgs = {
  projectId: Scalars['String']['input'];
  tokenId: Scalars['Int']['input'];
};

export type GeneratedQuestionnaire = {
  __typename?: 'Questionnaire';
  budget?: Maybe<GeneratedQuestionnaireBudget>;
  collectibles?: Maybe<Scalars['Boolean']['output']>;
  digital?: Maybe<Scalars['Boolean']['output']>;
  drawings?: Maybe<Scalars['Boolean']['output']>;
  frequency?: Maybe<GeneratedQuestionnaireFrequency>;
  id: Scalars['ID']['output'];
  limitedPrints?: Maybe<Scalars['Boolean']['output']>;
  limitedSculptures?: Maybe<Scalars['Boolean']['output']>;
  paintings?: Maybe<Scalars['Boolean']['output']>;
  photography?: Maybe<Scalars['Boolean']['output']>;
  sculptures?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['Time']['output'];
};

export enum GeneratedQuestionnaireBudget {
  Over_10000 = 'OVER_10000',
  Under_250 = 'UNDER_250',
  Under_500 = 'UNDER_500',
  Under_1000 = 'UNDER_1000',
  Under_2500 = 'UNDER_2500',
  Under_5000 = 'UNDER_5000',
  Under_10000 = 'UNDER_10000',
  Unknown = 'UNKNOWN',
}

export enum GeneratedQuestionnaireFrequency {
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Occasionally = 'OCCASIONALLY',
  Starting = 'STARTING',
  Unknown = 'UNKNOWN',
  Yearly = 'YEARLY',
}

export type GeneratedReview = {
  __typename?: 'Review';
  freeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: Array<Scalars['String']['output']>;
  product?: Maybe<GeneratedProduct>;
  shopifyProductId: Scalars['String']['output'];
};

export type GeneratedShippingRate = {
  __typename?: 'ShippingRate';
  currencyCode: Scalars['String']['output'];
  shippingRate: Scalars['Float']['output'];
};

export enum GeneratedSlackChannel {
  Artebio = 'ARTEBIO',
}

export type GeneratedSpecificVoucherResponse = {
  __typename?: 'SpecificVoucherResponse';
  claimed: Scalars['Boolean']['output'];
  voucher: GeneratedNftVoucherMetadata;
};

export type GeneratedStoryblokFrameOption = {
  __typename?: 'StoryblokFrameOption';
  component: Scalars['String']['output'];
  description: Scalars['String']['output'];
  frameId: Scalars['String']['output'];
  frameImage: GeneratedProductImage;
  name: Scalars['String']['output'];
  productCompositeImage: Array<Maybe<GeneratedProductImage>>;
};

export type GeneratedStoryblokProduct = {
  __typename?: 'StoryblokProduct';
  description: Scalars['String']['output'];
  dimensions: Scalars['String']['output'];
  editionSize?: Maybe<Scalars['Int']['output']>;
  finishing: Scalars['String']['output'];
  frameOptions: Array<Maybe<GeneratedStoryblokFrameOption>>;
  framingOptions: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: Array<GeneratedProductImage>;
  medium: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  storyblokID: Scalars['String']['output'];
};

export enum GeneratedSubscriptionType {
  ArtebioArtist = 'ARTEBIO_ARTIST',
  ArtebioBlock = 'ARTEBIO_BLOCK',
  Artist = 'ARTIST',
  Event = 'EVENT',
  General = 'GENERAL',
  Product = 'PRODUCT',
  ProductPreOrder = 'PRODUCT_PRE_ORDER',
  Release = 'RELEASE',
  Unspecified = 'UNSPECIFIED',
}

export type GeneratedSubscriptionsCount = {
  __typename?: 'SubscriptionsCount';
  accounts: Array<GeneratedAccount>;
  count: Scalars['Int']['output'];
};

export type GeneratedTax = {
  __typename?: 'Tax';
  countryCode: Scalars['String']['output'];
  dutyTaxRate: Scalars['Float']['output'];
  taxType: GeneratedTaxType;
  vatRate: Scalars['Float']['output'];
};

export enum GeneratedTaxType {
  Ddp = 'DDP',
  Ddu = 'DDU',
  NoTax = 'NO_TAX',
  Unspecified = 'UNSPECIFIED',
  Vat = 'VAT',
}

export type GeneratedUpdateQuestionnaireInput = {
  budget?: InputMaybe<GeneratedQuestionnaireBudget>;
  collectibles?: InputMaybe<Scalars['Boolean']['input']>;
  digital?: InputMaybe<Scalars['Boolean']['input']>;
  drawings?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<GeneratedQuestionnaireFrequency>;
  limitedPrints?: InputMaybe<Scalars['Boolean']['input']>;
  limitedSculptures?: InputMaybe<Scalars['Boolean']['input']>;
  paintings?: InputMaybe<Scalars['Boolean']['input']>;
  photography?: InputMaybe<Scalars['Boolean']['input']>;
  sculptures?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeneratedUpsertArtistBlockInput = {
  action: GeneratedArtistBlockAction;
  artistID: Scalars['String']['input'];
  date?: InputMaybe<GeneratedArtistBlockDateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<GeneratedArtistBlockImageInput>;
  intro?: InputMaybe<Scalars['String']['input']>;
  kind: GeneratedArtistBlockKind;
  overline?: InputMaybe<Scalars['String']['input']>;
  size: GeneratedArtistBlockSize;
  title: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<GeneratedArtistBlockVideoInput>;
};

export type GeneratedUpsertArtistTabInput = {
  artistID: Scalars['String']['input'];
  blockIDs: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  overrideSize: GeneratedArtistBlockSize;
  priority: Scalars['Float']['input'];
  slug: Scalars['String']['input'];
};

export type GeneratedUseLoginCodeResult = {
  __typename?: 'UseLoginCodeResult';
  account: GeneratedAccount;
  isNewAccount: Scalars['Boolean']['output'];
};

export type GeneratedValidationError = {
  __typename?: 'ValidationError';
  code: GeneratedValidationErrorCode;
  info?: Maybe<Scalars['String']['output']>;
};

export enum GeneratedValidationErrorCode {
  Invalid = 'INVALID',
  Missing = 'MISSING',
  TooLong = 'TOO_LONG',
  TooShort = 'TOO_SHORT',
}

export enum GeneratedVideoPlatform {
  Unspecified = 'UNSPECIFIED',
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE',
}

export type GeneratedGenerateMultipassTokenMutationVariables = Exact<{
  returnTo: Scalars['String']['input'];
}>;

export type GeneratedGenerateMultipassTokenMutation = {
  __typename?: 'Mutation';
  generateMultipassToken: {
    __typename?: 'GenerateMultipassTokenResult';
    token: string;
  };
};

export type GeneratedGenerateAvantPassTokenMutationVariables = Exact<{
  input: GeneratedGenerateAvantPassTokenInput;
}>;

export type GeneratedGenerateAvantPassTokenMutation = {
  __typename?: 'Mutation';
  generateAvantPassToken: {
    __typename?: 'GenerateAvantPassTokenResult';
    token: string;
  };
};

export type GeneratedCreateAddressMutationVariables = Exact<{
  address: GeneratedCreateOrUpdateAddressInput;
}>;

export type GeneratedCreateAddressMutation = {
  __typename?: 'Mutation';
  createAddress: {
    __typename?: 'Address';
    id: string;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    countryCode?: string | null;
    countryName?: string | null;
    customsCode?: string | null;
    company?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    provinceCode?: string | null;
    provinceName?: string | null;
    zip?: string | null;
    isDefault?: boolean | null;
  };
};

export type GeneratedUpdateAddressMutationVariables = Exact<{
  addressId: Scalars['String']['input'];
  changes: GeneratedCreateOrUpdateAddressInput;
}>;

export type GeneratedUpdateAddressMutation = {
  __typename?: 'Mutation';
  updateAddress: {
    __typename?: 'Address';
    id: string;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    countryCode?: string | null;
    countryName?: string | null;
    customsCode?: string | null;
    company?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    provinceCode?: string | null;
    provinceName?: string | null;
    zip?: string | null;
    isDefault?: boolean | null;
  };
};

export type GeneratedDeleteAddressMutationVariables = Exact<{
  addressId: Scalars['String']['input'];
}>;

export type GeneratedDeleteAddressMutation = {
  __typename?: 'Mutation';
  deleteAddress: boolean;
};

export type GeneratedWeb3GenerateNonceMutationVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  message: Scalars['String']['input'];
}>;

export type GeneratedWeb3GenerateNonceMutation = {
  __typename?: 'Mutation';
  web3GenerateNonce: string;
};

export type GeneratedWeb3VerifySignatureMutationVariables = Exact<{
  walletAddress: Scalars['String']['input'];
  signature: Scalars['String']['input'];
}>;

export type GeneratedWeb3VerifySignatureMutation = {
  __typename?: 'Mutation';
  web3VerifySignature: boolean;
};

export type GeneratedMutationEnterDrawMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  refID?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedMutationEnterDrawMutation = {
  __typename?: 'Mutation';
  enterDraw: { __typename?: 'Draw'; accountIsInDraw: boolean };
};

export type GeneratedAuthenticateNftPrintMutationVariables = Exact<{
  input: GeneratedAuthenticateNftPrintInput;
}>;

export type GeneratedAuthenticateNftPrintMutation = {
  __typename?: 'Mutation';
  authenticateNftPrint: {
    __typename?: 'FullNftPrintResponse';
    print: {
      __typename?: 'NftPrint';
      id: string;
      tokenId: string;
      owner: string;
      authenticated: boolean;
      productId: string;
      orderedAt: string;
    };
    events: Array<{
      __typename?: 'NftPrintEvent';
      id: string;
      owner: string;
      authenticated: boolean;
      productId: string;
      createTime: string;
      event: string;
    } | null>;
  };
};

export type GeneratedMutationDetachPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  providerId: GeneratedPaymentProvider;
}>;

export type GeneratedMutationDetachPaymentMethodMutation = {
  __typename?: 'Mutation';
  detachPaymentMethod?: string | null;
};

export type GeneratedRefreshStreamTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedRefreshStreamTokenQuery = {
  __typename?: 'Query';
  refreshStreamToken: string;
};

export type GeneratedQueryAccountIsInDrawQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GeneratedQueryAccountIsInDrawQuery = {
  __typename?: 'Query';
  draw: { __typename?: 'Draw'; accountIsInDraw: boolean };
};

export type GeneratedDrawQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GeneratedDrawQuery = {
  __typename?: 'Query';
  draw: {
    __typename?: 'Draw';
    accountIsInDraw: boolean;
    allocation: number;
    openAt: string;
    preOrderCloseAt?: string | null;
    totalAccounts: number;
    status: GeneratedDrawStatus;
    closeAt: string;
    cryptoComEnabled: boolean;
  };
};

export type GeneratedGetShippingRateByProductVariantIdQueryVariables = Exact<{
  productVariantID: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
}>;

export type GeneratedGetShippingRateByProductVariantIdQuery = {
  __typename?: 'Query';
  getShippingRateByProductVariantID: {
    __typename?: 'ShippingRate';
    currencyCode: string;
    shippingRate: number;
  };
};

export type GeneratedGetProductsQueryVariables = Exact<{
  ids?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  shopifyIDs?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  slugs?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  previewToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedGetProductsQuery = {
  __typename?: 'Query';
  getProducts: Array<{
    __typename?: 'Product';
    id: string;
    shopifyId: string;
    artistIds: Array<string>;
    slug: string;
    title: string;
    price: number;
    medium: GeneratedProductMedium;
    releaseType: GeneratedProductReleaseType;
    availability: GeneratedProductAvailability;
    isFrame: boolean;
    drawLaunch: boolean;
    editionSize: number;
    cryptoDisplayPrice: string;
    framingOffered: string;
    framedDispatchDate?: string | null;
    framingUpsellEndTime?: string | null;
    shippingUpdateEndTime?: string | null;
    unframedDispatchDate?: string | null;
    tags: Array<string>;
    launchAt?: string | null;
    closeAt?: string | null;
    variants: Array<{
      __typename?: 'ProductVariant';
      id: string;
      productId: string;
      shopifyId: string;
      title: string;
      sku: string;
      available: boolean;
      requiresShipping: boolean;
      price: number;
    }>;
    thumbnail?: {
      __typename?: 'ProductImage';
      url: string;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    } | null;
    images: Array<{
      __typename?: 'ProductImage';
      url: string;
      width?: number | null;
      height?: number | null;
      alt?: string | null;
    }>;
    artists: Array<{ __typename?: 'Artist'; name: string; id: string }>;
  }>;
};

export type GeneratedGetTaxesByCountryCodeQueryVariables = Exact<{
  countryCode: Scalars['String']['input'];
}>;

export type GeneratedGetTaxesByCountryCodeQuery = {
  __typename?: 'Query';
  getTaxesByCountryCode: {
    __typename?: 'Tax';
    countryCode: string;
    dutyTaxRate: number;
    taxType: GeneratedTaxType;
    vatRate: number;
  };
};

export type GeneratedGetAddressesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedGetAddressesQuery = {
  __typename?: 'Query';
  addresses: Array<{
    __typename?: 'Address';
    id: string;
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    countryCode?: string | null;
    countryName?: string | null;
    customsCode?: string | null;
    company?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    provinceCode?: string | null;
    provinceName?: string | null;
    zip?: string | null;
    isDefault?: boolean | null;
  }>;
};

export type GeneratedSearchArtistsQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type GeneratedSearchArtistsQuery = {
  __typename?: 'Query';
  searchArtists: Array<{
    __typename?: 'Artist';
    id: string;
    name: string;
    thumbnail?: string | null;
  }>;
};

export type GeneratedGetArtistsBySlugQueryVariables = Exact<{
  slugs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GeneratedGetArtistsBySlugQuery = {
  __typename?: 'Query';
  artistsBySlug: Array<{
    __typename?: 'Artist';
    id: string;
    name: string;
    slug: string;
    thumbnail?: string | null;
    website?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    tiktok?: string | null;
    ArteBio: boolean;
  }>;
};

export type GeneratedNftsVouchersQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type GeneratedNftsVouchersQuery = {
  __typename?: 'Query';
  nftsGetVouchers: {
    __typename?: 'NFTGetVouchersResponse';
    vouchers: Array<{
      __typename?: 'NFTUnSignedVoucherData';
      claimed: boolean;
      voucher: {
        __typename?: 'NFTVoucherMetadata';
        projectId: string;
        walletAddress: string;
        voucherId: string;
        price: string;
        validUntil: string;
        tokenId: string;
      };
    } | null>;
  };
};

export type GeneratedNftVoucherByVoucherNumberQueryVariables = Exact<{
  productId: Scalars['String']['input'];
  voucherNumber: Scalars['Int']['input'];
}>;

export type GeneratedNftVoucherByVoucherNumberQuery = {
  __typename?: 'Query';
  nftVoucherByVoucherNumber: {
    __typename?: 'SpecificVoucherResponse';
    claimed: boolean;
    voucher: {
      __typename?: 'NFTVoucherMetadata';
      projectId: string;
      price: string;
      validUntil: string;
      tokenId: string;
      nftContractAddress: string;
      chainId: string;
      voucherId: string;
    };
  };
};

export type GeneratedNftGetSignedVoucherQueryVariables = Exact<{
  voucherId: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
}>;

export type GeneratedNftGetSignedVoucherQuery = {
  __typename?: 'Query';
  nftGetSignedVoucher: {
    __typename?: 'NFTSignedVoucherResponse';
    claimed: boolean;
    voucher: {
      __typename?: 'NFTSignedVoucher';
      projectId: string;
      walletAddress: string;
      voucherId: string;
      price: string;
      validUntil: string;
      tokenId: string;
      signature: string;
    };
  };
};

export type GeneratedBlockchainGetSignedNftVoucherQueryVariables = Exact<{
  voucherId: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
}>;

export type GeneratedBlockchainGetSignedNftVoucherQuery = {
  __typename?: 'Query';
  blockchainGetSignedNftVoucher: {
    __typename?: 'NftSignedVoucherV2';
    signature: string;
    projectId: string;
    walletAddress: string;
    voucherId: string;
    price: string;
    validUntil: string;
    tokenId: string;
  };
};

export type GeneratedVoucherExistsForTokenIdQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
  tokenId: Scalars['Int']['input'];
}>;

export type GeneratedVoucherExistsForTokenIdQuery = {
  __typename?: 'Query';
  voucherExistsForTokenId: boolean;
};

export type GeneratedGetNftPrintsForProductQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type GeneratedGetNftPrintsForProductQuery = {
  __typename?: 'Query';
  getNftPrintsForProduct: {
    __typename?: 'GetNftPrintsForProductResponse';
    prints: Array<{
      __typename?: 'NftPrint';
      id: string;
      tokenId: string;
      owner: string;
      authenticated: boolean;
      orderedAt: string;
    } | null>;
  };
};

export type GeneratedBlockchainGetAllNftsOfContractQueryVariables = Exact<{
  input: GeneratedBlockchainGetAllNftsOfContractInput;
}>;

export type GeneratedBlockchainGetAllNftsOfContractQuery = {
  __typename?: 'Query';
  blockchainGetAllNftsOfContract: Array<{
    __typename?: 'BlockchainTokenOwnerPair';
    token: string;
    owner: string;
  }>;
};

export type GeneratedGetFullNftPrintQueryVariables = Exact<{
  productId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
}>;

export type GeneratedGetFullNftPrintQuery = {
  __typename?: 'Query';
  getFullNftPrint: {
    __typename?: 'FullNftPrintResponse';
    ownerEmailHint: string;
    print: {
      __typename?: 'NftPrint';
      id: string;
      tokenId: string;
      owner: string;
      authenticated: boolean;
      productId: string;
      orderedAt: string;
      ownerId: string;
    };
    events: Array<{
      __typename?: 'NftPrintEvent';
      id: string;
      owner: string;
      authenticated: boolean;
      productId: string;
      createTime: string;
      event: string;
    } | null>;
  };
};

export type GeneratedBlockchainOwnedNftTokensInContractQueryVariables = Exact<{
  input: GeneratedBlockchainOwnedNftTokensInContractInput;
}>;

export type GeneratedBlockchainOwnedNftTokensInContractQuery = {
  __typename?: 'Query';
  blockchainOwnedNftTokensInContract: Array<{
    __typename?: 'BlockchainNftTokenInfo';
    id: string;
    metadataUri: string;
  } | null>;
};

export type GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables = Exact<{
  input: GeneratedBlockchainIsNftTokenOwnedByWalletInput;
}>;

export type GeneratedBlockchainIsNftTokenOwnedByWalletQuery = {
  __typename?: 'Query';
  blockchainIsNftTokenOwnedByWallet: boolean;
};

export type GeneratedBlockchainValidateGatingQueryVariables = Exact<{
  input: GeneratedBlockchainValidateGatingInput;
}>;

export type GeneratedBlockchainValidateGatingQuery = {
  __typename?: 'Query';
  blockchainValidateGating: boolean;
};

export type GeneratedBlockchainGetInfoForTokensQueryVariables = Exact<{
  input: GeneratedBlockchainGetInfoForTokensInput;
}>;

export type GeneratedBlockchainGetInfoForTokensQuery = {
  __typename?: 'Query';
  blockchainGetInfoForTokens: Array<{
    __typename?: 'BlockchainNftTokenInfo';
    id: string;
    metadataUri: string;
    owner: string;
  } | null>;
};

export type GeneratedCalculateOrderPricingQueryVariables = Exact<{
  countryCode: Scalars['String']['input'];
  currencyCode: Scalars['String']['input'];
  items:
    | Array<InputMaybe<GeneratedCalculateOrderPricingInputItem>>
    | InputMaybe<GeneratedCalculateOrderPricingInputItem>;
}>;

export type GeneratedCalculateOrderPricingQuery = {
  __typename?: 'Query';
  calculateOrderPricing: {
    __typename?: 'OrdersPriceCalculation';
    totalPrice: number;
    totalDiscount: number;
    subtotalTaxPrice: number;
    subtotalShippingPrice: number;
    subtotalItemsPrice: number;
    currencyCode: string;
    taxRate: number;
    taxType: GeneratedTaxType;
    breakdown: Array<{
      __typename?: 'OrdersItemPricing';
      productId: string;
      variantId: string;
      title: string;
      price: number;
      tags: Array<string>;
      carrierServiceName: string;
      shippingProviderId: string;
      quantity: number;
      isTaxable: boolean;
      freeShipping: boolean;
      requiresShipping: boolean;
    }>;
  };
};

export type GeneratedGetDrawByProductIdQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type GeneratedGetDrawByProductIdQuery = {
  __typename?: 'Query';
  getDrawByProductId: {
    __typename?: 'Draw';
    id: string;
    productType: GeneratedDrawProductType;
    drawType: GeneratedDrawType;
    productId: string;
    productVariantId: string;
    preOrderCloseAt?: string | null;
    allocation: number;
    accountIsInDraw: boolean;
    closeAt: string;
    openAt: string;
    drawnAt?: string | null;
    status: GeneratedDrawStatus;
    totalAccounts: number;
    cryptoComEnabled: boolean;
  };
};

export type GeneratedGetDrawEntryIntentQueryVariables = Exact<{
  drawEntryIntentId: Scalars['ID']['input'];
}>;

export type GeneratedGetDrawEntryIntentQuery = {
  __typename?: 'Query';
  getDrawEntryIntent?: {
    __typename?: 'DrawEntryIntent';
    id: string;
    errorCode?: string | null;
    confirmedAt?: string | null;
    discardedAt?: string | null;
  } | null;
};

export type GeneratedListPaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedListPaymentMethodsQuery = {
  __typename?: 'Query';
  listPaymentMethods: Array<{
    __typename?: 'PaymentMethod';
    providerId: GeneratedPaymentProvider;
    providerPaymentMethodId: string;
    type: GeneratedPaymentMethodType;
    default: boolean;
    billingAddress?: {
      __typename?: 'PaymentMethodBillingAddress';
      line1: string;
      line2: string;
      city: string;
      state: string;
      postalCode: string;
      countryCode: string;
    } | null;
    card?: {
      __typename?: 'PaymentMethodCard';
      brand: string;
      expireMonth: number;
      expireYear: number;
      last4: string;
      wallet?: {
        __typename?: 'PaymentMethodCardWallet';
        type: GeneratedPaymentMethodWalletType;
      } | null;
    } | null;
  }>;
};

export type GeneratedGetArtistsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GeneratedGetArtistsByIdsQuery = {
  __typename?: 'Query';
  artists: Array<{
    __typename?: 'Artist';
    id: string;
    name: string;
    thumbnail?: string | null;
  }>;
};

export type GeneratedArteBioArtistsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedArteBioArtistsQuery = {
  __typename?: 'Query';
  arteBioArtists: Array<{ __typename?: 'Artist'; slug: string }>;
};

export type GeneratedArtistTabFullFragment = {
  __typename?: 'ArtistTab';
  id: string;
  artistID: string;
  label: string;
  slug: string;
  priority: number;
  overrideSize: GeneratedArtistBlockSize;
  blockIDs: Array<string>;
  createdAt: string;
  updatedAt: string;
};

export type GeneratedArtistBlockFullFragment = {
  __typename?: 'ArtistBlock';
  id: string;
  artistID: string;
  kind: GeneratedArtistBlockKind;
  size: GeneratedArtistBlockSize;
  action: GeneratedArtistBlockAction;
  title: string;
  description: string;
  overline: string;
  intro: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  date?: {
    __typename?: 'ArtistBlockDate';
    start?: string | null;
    end?: string | null;
  } | null;
  image?: {
    __typename?: 'ArtistBlockImage';
    filename: string;
    alt: string;
  } | null;
  video?: {
    __typename?: 'ArtistBlockVideo';
    id: string;
    platform: GeneratedVideoPlatform;
    duration: number;
  } | null;
};

export type GeneratedGetArtistTabsBlocksQueryVariables = Exact<{
  artistID: Scalars['ID']['input'];
}>;

export type GeneratedGetArtistTabsBlocksQuery = {
  __typename?: 'Query';
  artistTabsBlocks: {
    __typename?: 'ArtistTabsBlocks';
    tabs: Array<{
      __typename?: 'ArtistTab';
      id: string;
      artistID: string;
      label: string;
      slug: string;
      priority: number;
      overrideSize: GeneratedArtistBlockSize;
      blockIDs: Array<string>;
      createdAt: string;
      updatedAt: string;
    }>;
    blocks: Array<{
      __typename?: 'ArtistBlock';
      id: string;
      artistID: string;
      kind: GeneratedArtistBlockKind;
      size: GeneratedArtistBlockSize;
      action: GeneratedArtistBlockAction;
      title: string;
      description: string;
      overline: string;
      intro: string;
      url: string;
      createdAt: string;
      updatedAt: string;
      date?: {
        __typename?: 'ArtistBlockDate';
        start?: string | null;
        end?: string | null;
      } | null;
      image?: {
        __typename?: 'ArtistBlockImage';
        filename: string;
        alt: string;
      } | null;
      video?: {
        __typename?: 'ArtistBlockVideo';
        id: string;
        platform: GeneratedVideoPlatform;
        duration: number;
      } | null;
    }>;
  };
};

export type GeneratedGetArtistTabsQueryVariables = Exact<{
  artistID: Scalars['ID']['input'];
}>;

export type GeneratedGetArtistTabsQuery = {
  __typename?: 'Query';
  artistTabsBlocks: {
    __typename?: 'ArtistTabsBlocks';
    tabs: Array<{
      __typename?: 'ArtistTab';
      id: string;
      artistID: string;
      label: string;
      slug: string;
      priority: number;
      overrideSize: GeneratedArtistBlockSize;
      blockIDs: Array<string>;
      createdAt: string;
      updatedAt: string;
    }>;
  };
};

export type GeneratedGetArtistBlocksQueryVariables = Exact<{
  artistID: Scalars['ID']['input'];
}>;

export type GeneratedGetArtistBlocksQuery = {
  __typename?: 'Query';
  artistBlocks: Array<{
    __typename?: 'ArtistBlock';
    id: string;
    artistID: string;
    kind: GeneratedArtistBlockKind;
    size: GeneratedArtistBlockSize;
    action: GeneratedArtistBlockAction;
    title: string;
    description: string;
    overline: string;
    intro: string;
    url: string;
    createdAt: string;
    updatedAt: string;
    date?: {
      __typename?: 'ArtistBlockDate';
      start?: string | null;
      end?: string | null;
    } | null;
    image?: {
      __typename?: 'ArtistBlockImage';
      filename: string;
      alt: string;
    } | null;
    video?: {
      __typename?: 'ArtistBlockVideo';
      id: string;
      platform: GeneratedVideoPlatform;
      duration: number;
    } | null;
  }>;
};

export type GeneratedGetArtistsPermissionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedGetArtistsPermissionQuery = {
  __typename?: 'Query';
  artistsPermission: Array<{
    __typename?: 'ArtistPermission';
    artistID: string;
    canRead: boolean;
    canWrite: boolean;
  }>;
};

export type GeneratedArteBioSubscriptionsExportQueryVariables = Exact<{
  artistID: Scalars['ID']['input'];
}>;

export type GeneratedArteBioSubscriptionsExportQuery = {
  __typename?: 'Query';
  arteBioSubscriptionsExport: string;
};

export type GeneratedDeleteArtistTabMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GeneratedDeleteArtistTabMutation = {
  __typename?: 'Mutation';
  deleteArtistTab: boolean;
};

export type GeneratedDeleteArtistBlockMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GeneratedDeleteArtistBlockMutation = {
  __typename?: 'Mutation';
  deleteArtistBlock: boolean;
};

export type GeneratedUpsertArtistBlockMutationVariables = Exact<{
  input: GeneratedUpsertArtistBlockInput;
}>;

export type GeneratedUpsertArtistBlockMutation = {
  __typename?: 'Mutation';
  upsertArtistBlock: {
    __typename?: 'ArtistBlock';
    id: string;
    artistID: string;
    kind: GeneratedArtistBlockKind;
    size: GeneratedArtistBlockSize;
    action: GeneratedArtistBlockAction;
    title: string;
    description: string;
    overline: string;
    intro: string;
    url: string;
    createdAt: string;
    updatedAt: string;
    date?: {
      __typename?: 'ArtistBlockDate';
      start?: string | null;
      end?: string | null;
    } | null;
    image?: {
      __typename?: 'ArtistBlockImage';
      filename: string;
      alt: string;
    } | null;
    video?: {
      __typename?: 'ArtistBlockVideo';
      id: string;
      platform: GeneratedVideoPlatform;
      duration: number;
    } | null;
  };
};

export type GeneratedUpsertArtistTabMutationVariables = Exact<{
  input: GeneratedUpsertArtistTabInput;
}>;

export type GeneratedUpsertArtistTabMutation = {
  __typename?: 'Mutation';
  upsertArtistTab: {
    __typename?: 'ArtistTab';
    id: string;
    artistID: string;
    label: string;
    slug: string;
    priority: number;
    overrideSize: GeneratedArtistBlockSize;
    blockIDs: Array<string>;
    createdAt: string;
    updatedAt: string;
  };
};

export type GeneratedCreateCheckoutMutationVariables = Exact<{
  paymentProvider: GeneratedPaymentProvider;
  idempotencyKey: Scalars['String']['input'];
  items: Array<InputMaybe<GeneratedOrderItem>> | InputMaybe<GeneratedOrderItem>;
  currencyCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GeneratedCreateCheckoutMutation = {
  __typename?: 'Mutation';
  createCheckout: {
    __typename?: 'Checkout';
    paymentProvider: GeneratedPaymentProvider;
    providerPaymentIntentId: string;
    providerPaymentClientSecret: string;
    pricing: {
      __typename?: 'OrdersPriceCalculation';
      totalPrice: number;
      subtotalTaxPrice: number;
      subtotalShippingPrice: number;
      subtotalItemsPrice: number;
      currencyCode: string;
      taxRate: number;
      taxType: GeneratedTaxType;
      includesCalculatedShipping: boolean;
      includesCalculatedTaxes: boolean;
      breakdown: Array<{
        __typename?: 'OrdersItemPricing';
        productId: string;
        variantId: string;
        title: string;
        price: number;
        quantity: number;
        isTaxable: boolean;
        freeShipping: boolean;
        requiresShipping: boolean;
        tags: Array<string>;
        carrierServiceName: string;
        shippingProviderId: string;
      }>;
    };
  };
};

export type GeneratedUpdateCheckoutMutationVariables = Exact<{
  paymentProvider: GeneratedPaymentProvider;
  providerPaymentIntentId: Scalars['String']['input'];
  items?: InputMaybe<
    Array<InputMaybe<GeneratedOrderItem>> | InputMaybe<GeneratedOrderItem>
  >;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<GeneratedOrderAddressInput>;
  tags?: InputMaybe<GeneratedOrderTagsInput>;
}>;

export type GeneratedUpdateCheckoutMutation = {
  __typename?: 'Mutation';
  updateCheckout: {
    __typename?: 'Checkout';
    paymentProvider: GeneratedPaymentProvider;
    providerPaymentIntentId: string;
    providerPaymentClientSecret: string;
    pricing: {
      __typename?: 'OrdersPriceCalculation';
      totalPrice: number;
      subtotalTaxPrice: number;
      subtotalShippingPrice: number;
      subtotalItemsPrice: number;
      currencyCode: string;
      taxRate: number;
      taxType: GeneratedTaxType;
      includesCalculatedShipping: boolean;
      includesCalculatedTaxes: boolean;
      breakdown: Array<{
        __typename?: 'OrdersItemPricing';
        productId: string;
        variantId: string;
        title: string;
        price: number;
        quantity: number;
        isTaxable: boolean;
        freeShipping: boolean;
        requiresShipping: boolean;
        tags: Array<string>;
        carrierServiceName: string;
        shippingProviderId: string;
      }>;
    };
  };
};

export type GeneratedCreateDrawEntryIntentMutationVariables = Exact<{
  drawId: Scalars['ID']['input'];
  currencyCode: Scalars['String']['input'];
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  optionalItems?: InputMaybe<
    Array<InputMaybe<GeneratedOrderItem>> | InputMaybe<GeneratedOrderItem>
  >;
  cryptoPaymentRequested?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GeneratedCreateDrawEntryIntentMutation = {
  __typename?: 'Mutation';
  createDrawEntryIntent?: {
    __typename?: 'DrawEntryIntentCreateResponse';
    setupSecret: string;
    intent: {
      __typename?: 'DrawEntryIntent';
      id: string;
      errorCode?: string | null;
      confirmedAt?: string | null;
      discardedAt?: string | null;
    };
    pricing: {
      __typename?: 'OrderPricingQuote';
      id: string;
      createdAt: string;
      pricing: {
        __typename?: 'OrdersPriceCalculation';
        totalPrice: number;
        subtotalTaxPrice: number;
        subtotalShippingPrice: number;
        subtotalItemsPrice: number;
        currencyCode: string;
        taxRate: number;
        taxType: GeneratedTaxType;
        includesCalculatedShipping: boolean;
        includesCalculatedTaxes: boolean;
        breakdown: Array<{
          __typename?: 'OrdersItemPricing';
          productId: string;
          variantId: string;
          title: string;
          price: number;
          quantity: number;
          isTaxable: boolean;
          freeShipping: boolean;
          requiresShipping: boolean;
          tags: Array<string>;
          carrierServiceName: string;
          shippingProviderId: string;
        }>;
      };
    };
  } | null;
};

export type GeneratedCreateReviewMutationVariables = Exact<{
  input: GeneratedCreateReviewInput;
}>;

export type GeneratedCreateReviewMutation = {
  __typename?: 'Mutation';
  CreateReview: { __typename?: 'CreateReviewResponse'; id: string };
};

export type GeneratedCreateSubscriptionsMutationVariables = Exact<{
  subscriptions:
    | Array<GeneratedCreateSubscriptionsInput>
    | GeneratedCreateSubscriptionsInput;
  UTMCampaign?: InputMaybe<Scalars['String']['input']>;
  UTMMedium?: InputMaybe<Scalars['String']['input']>;
  UTMSource?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedCreateSubscriptionsMutation = {
  __typename?: 'Mutation';
  createSubscriptions: {
    __typename?: 'CreateSubscriptionsResponse';
    subscribed: boolean;
  };
};

export type GeneratedGetEuroFxQueryVariables = Exact<{ [key: string]: never }>;

export type GeneratedGetEuroFxQuery = {
  __typename?: 'Query';
  euroFx: Array<{
    __typename?: 'ExchangeRate';
    currency: string;
    value: number;
  }>;
};

export type GeneratedGetAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GeneratedGetAccountQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    id: string;
    username?: string | null;
    aml: boolean;
    avatar?: string | null;
    fullName?: string | null;
    email?: string | null;
    countryCode?: string | null;
    phone?: string | null;
    pronouns?: GeneratedPronouns | null;
    createdAt?: string | null;
    dateOfBirth?: string | null;
  } | null;
};

export type GeneratedBlockchainGetDelegationsByDelegateQueryVariables = Exact<{
  input: GeneratedBlockchainGetDelegationsByDelegateInput;
}>;

export type GeneratedBlockchainGetDelegationsByDelegateQuery = {
  __typename?: 'Query';
  blockchainGetDelegationsByDelegate: Array<{
    __typename?: 'DelegateCashOutput';
    type: string;
    vault: string;
    delegate: string;
    contract: string;
    tokenId: number;
  } | null>;
};

export type GeneratedGetDrawByProductIDsQueryVariables = Exact<{
  productIDs: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GeneratedGetDrawByProductIDsQuery = {
  __typename?: 'Query';
  getDrawByProductIDs: Array<{
    __typename?: 'Draw';
    closeAt: string;
    preOrderCloseAt?: string | null;
    productId: string;
  }>;
};

export type GeneratedGetHlsQueryVariables = Exact<{
  eventID: Scalars['ID']['input'];
}>;

export type GeneratedGetHlsQuery = {
  __typename?: 'Query';
  eventarteHlsURL: string;
};

export type GeneratedGetLiveEventPollQueryVariables = Exact<{
  idOrSlug: Scalars['ID']['input'];
}>;

export type GeneratedGetLiveEventPollQuery = {
  __typename?: 'Query';
  eventarte?: {
    __typename?: 'EventArte';
    journalURL: string;
    startTime: string;
    endTime?: string | null;
    replayReady: boolean;
    vimeoClipID: string;
    giveaways: Array<{
      __typename?: 'EventArteGiveaway';
      id: string;
      entryTriggerEmoji: string;
      name: string;
      entryTriggerWord: string;
      winnerUsername: string;
      startTime: string;
      endTime: string;
    }>;
  } | null;
};

export type GeneratedGetLiveEventQueryVariables = Exact<{
  idOrSlug: Scalars['ID']['input'];
}>;

export type GeneratedGetLiveEventQuery = {
  __typename?: 'Query';
  eventarte?: {
    __typename?: 'EventArte';
    id: string;
    slug: string;
    title: string;
    subtitle: string;
    description: string;
    location: string;
    posterURL: string;
    chatPosterURL: string;
    vimeoClipID: string;
    vimeoEventID: string;
    replayReady: boolean;
    journalURL: string;
    artistAccountIDs: Array<string>;
    productInfo: string;
    startTime: string;
    endTime?: string | null;
    medias: Array<{
      __typename?: 'EventArteMedia';
      coverUrl: string;
      duration: number;
      mediaType: GeneratedEventArteMediaType;
      url: string;
    }>;
    timeline: Array<{
      __typename?: 'EventArteTimeline';
      time: string;
      description: string;
    }>;
    artists: Array<{
      __typename?: 'Artist';
      id: string;
      name: string;
      slug: string;
      thumbnail?: string | null;
    }>;
    products: Array<{
      __typename?: 'EventArteProduct';
      id: string;
      shopifyID?: string | null;
      name?: string | null;
      price?: string | null;
      imageURL?: string | null;
      shopifyProduct?: {
        __typename?: 'StoryblokProduct';
        description: string;
        dimensions: string;
        editionSize?: number | null;
        finishing: string;
        framingOptions: string;
        id: string;
        medium: string;
        name: string;
        price?: string | null;
        slug: string;
        storyblokID: string;
        images: Array<{ __typename?: 'ProductImage'; url: string }>;
        frameOptions: Array<{
          __typename?: 'StoryblokFrameOption';
          name: string;
          frameId: string;
          component: string;
          description: string;
          frameImage: {
            __typename?: 'ProductImage';
            url: string;
            alt?: string | null;
            height?: number | null;
            width?: number | null;
          };
          productCompositeImage: Array<{
            __typename?: 'ProductImage';
            url: string;
            alt?: string | null;
            height?: number | null;
            width?: number | null;
          } | null>;
        } | null>;
      } | null;
    }>;
    giveaways: Array<{
      __typename?: 'EventArteGiveaway';
      id: string;
      entryTriggerEmoji: string;
      name: string;
      entryTriggerWord: string;
      winnerUsername: string;
      startTime: string;
      endTime: string;
    }>;
  } | null;
};

export type GeneratedGetOrderReviewProductQueryVariables = Exact<{
  shopifyProductId: Scalars['String']['input'];
}>;

export type GeneratedGetOrderReviewProductQuery = {
  __typename?: 'Query';
  getProducts: Array<{
    __typename?: 'Product';
    id: string;
    title: string;
    thumbnail?: {
      __typename?: 'ProductImage';
      url: string;
      alt?: string | null;
    } | null;
    artists: Array<{ __typename?: 'Artist'; id: string; name: string }>;
  }>;
};

export type GeneratedGetPrivateLinkQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GeneratedGetPrivateLinkQuery = {
  __typename?: 'Query';
  unstable__privateLink: {
    __typename?: 'PrivateLink';
    expiresAt?: string | null;
    heading: string;
    imageUrl: string;
    intro: string;
    passwordRequired: boolean;
    slug: string;
    strapline: string;
    videoUrl?: string | null;
  };
};

export type GeneratedGetPrivateLinkWithProductsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type GeneratedGetPrivateLinkWithProductsQuery = {
  __typename?: 'Query';
  unstable__privateLink: {
    __typename?: 'PrivateLink';
    slug: string;
    expiresAt?: string | null;
    heading: string;
    imageUrl: string;
    videoUrl?: string | null;
    intro: string;
    strapline: string;
    products?: Array<{
      __typename?: 'CatalogueProduct';
      shopifyVariantId?: string | null;
      shopifyProductId?: string | null;
      unlockCode: string;
      tags: Array<string>;
      isEnquiry?: boolean | null;
      comment?: {
        __typename?: 'PrivateLinkProductComment';
        body: string;
        avatarUrl: string;
      } | null;
    }> | null;
  };
};

export type GeneratedSubscriptionsCountFragment = {
  __typename?: 'SubscriptionsCount';
  count: number;
  accounts: Array<{
    __typename?: 'Account';
    avatar?: string | null;
    id: string;
  }>;
};

export type GeneratedGetSubscriptionsCountQueryVariables = Exact<{
  type: GeneratedSubscriptionType;
  objectId: Scalars['String']['input'];
  withRandomAccounts: Scalars['Boolean']['input'];
}>;

export type GeneratedGetSubscriptionsCountQuery = {
  __typename?: 'Query';
  subscriptionsCount: {
    __typename?: 'SubscriptionsCount';
    count: number;
    accounts: Array<{
      __typename?: 'Account';
      avatar?: string | null;
      id: string;
    }>;
  };
};

export type GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
  tokenId: Scalars['Int']['input'];
}>;

export type GeneratedGetVoucherByTokenIdAndProjectIdQuery = {
  __typename?: 'Query';
  getVoucherByTokenIdAndProjectId: Array<{
    __typename?: 'NftVoucherV2';
    id: number;
    uid: string;
    walletAddress: string;
    accountId: string;
    tokenId: number;
    chainId: string;
    contractAddress: string;
    projectId: string;
    price: string;
    validUntil: number;
    claimed: boolean;
    claimer?: string | null;
    nftContractAddress: string;
    editionNumber: number;
    orderId: string;
    status: string;
    tokenStandard: string;
    createTime: string;
    updateTime: string;
  } | null>;
};

export type GeneratedHubspotWhitepaperListMutationVariables = Exact<{
  email: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
}>;

export type GeneratedHubspotWhitepaperListMutation = {
  __typename?: 'Mutation';
  HubspotWhitepaperList: boolean;
};

export type GeneratedIsSubscribedQueryVariables = Exact<{
  subscriptions:
    | Array<GeneratedIsSubscribedSubscriptionsInput>
    | GeneratedIsSubscribedSubscriptionsInput;
}>;

export type GeneratedIsSubscribedQuery = {
  __typename?: 'Query';
  isSubscribed: {
    __typename?: 'IsSubscribedResponse';
    subscriptions: Array<{
      __typename?: 'IsSubscribedResponseItem';
      objectId: string;
      sType: GeneratedSubscriptionType;
    }>;
  };
};

export type GeneratedEventarteJoinedChatMutationVariables = Exact<{
  eventID: Scalars['ID']['input'];
}>;

export type GeneratedEventarteJoinedChatMutation = {
  __typename?: 'Mutation';
  eventarteJoinedChat: boolean;
};

export type GeneratedLeaveDrawMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GeneratedLeaveDrawMutation = {
  __typename?: 'Mutation';
  leaveDraw: { __typename?: 'Draw'; id: string };
};

export type GeneratedListDrawEntryIntentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedListDrawEntryIntentsQuery = {
  __typename?: 'Query';
  listDrawEntryIntent: Array<{
    __typename?: 'DrawEntryIntent';
    id: string;
    confirmedAt?: string | null;
    cryptoPaymentRequested: boolean;
    preOrder: boolean;
    draw: {
      __typename?: 'Draw';
      id: string;
      closeAt: string;
      preOrderCloseAt?: string | null;
      status: GeneratedDrawStatus;
      product?: {
        __typename?: 'Product';
        id: string;
        shopifyId: string;
        slug: string;
        title: string;
        price: number;
        thumbnail?: { __typename?: 'ProductImage'; url: string } | null;
      } | null;
    };
    paymentMethod?: {
      __typename?: 'PaymentMethod';
      type: GeneratedPaymentMethodType;
      card?: {
        __typename?: 'PaymentMethodCard';
        brand: string;
        expireMonth: number;
        expireYear: number;
        last4: string;
      } | null;
    } | null;
  }>;
};

export type GeneratedLoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedLoginMutation = {
  __typename?: 'Mutation';
  login: boolean;
};

export type GeneratedLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type GeneratedLogoutMutation = {
  __typename?: 'Mutation';
  logout: boolean;
};

export type GeneratedOrderDetailsFragment = {
  __typename?: 'Order';
  shopifyId: string;
  shopifyOrderNumber: string;
  invoiceURL: string;
  resaleAgreementURL: string;
  shippingMethods: Array<string>;
  processedAt: string;
  cancelledAt?: string | null;
  fulfillmentStatus: GeneratedOrderFulfillmentStatus;
  financialStatus: GeneratedOrderFinancialStatus;
  financialTransactions: Array<{
    __typename?: 'FinancialTransaction';
    type: GeneratedFinancialTransactionType;
    redactedPaymentIdentifier?: string | null;
    brand?: string | null;
  }>;
  shippingAddress?: {
    __typename?: 'OrderAddress';
    firstName: string;
    lastName: string;
    company?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    zip: string;
    province?: string | null;
    country?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  billingAddress?: {
    __typename?: 'OrderAddress';
    firstName: string;
    lastName: string;
    company?: string | null;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    zip: string;
    province?: string | null;
    country?: string | null;
    countryCode?: string | null;
    phone?: string | null;
  } | null;
  totalPriceSet?: {
    __typename?: 'MoneySet';
    amount: string;
    currencyCode: string;
  } | null;
  subtotalPriceSet?: {
    __typename?: 'MoneySet';
    amount: string;
    currencyCode: string;
  } | null;
  totalTaxPriceSet?: {
    __typename?: 'MoneySet';
    amount: string;
    currencyCode: string;
  } | null;
  totalShippingPricesSet?: {
    __typename?: 'MoneySet';
    amount: string;
    currencyCode: string;
  } | null;
  totalDiscountsPricesSet?: {
    __typename?: 'MoneySet';
    amount: string;
    currencyCode: string;
  } | null;
  lineItems: Array<{
    __typename?: 'OrderLineItem';
    shopifyId: string;
    shopifyProductId: string;
    title: string;
    variantTitle?: string | null;
    featuredImageUrl: string;
    quantity: number;
    productType: string;
    framingOffered: string;
    framedDispatchDate?: string | null;
    unframedDispatchDate?: string | null;
    fulfillmentStatus: GeneratedItemFulfillmentStatus;
    inTransitAt?: string | null;
    deliveredAt?: string | null;
    trackingUrl?: string | null;
    price: string;
    priceCurrencyCode: string;
  }>;
};

export type GeneratedOrderDetailsSummaryFragment = {
  __typename?: 'Order';
  shopifyId: string;
  shopifyOrderNumber: string;
  processedAt: string;
  cancelledAt?: string | null;
  fulfillmentStatus: GeneratedOrderFulfillmentStatus;
  financialStatus: GeneratedOrderFinancialStatus;
  lineItems: Array<{
    __typename?: 'OrderLineItem';
    shopifyId: string;
    shopifyProductId: string;
    title: string;
    variantTitle?: string | null;
    featuredImageUrl: string;
    productType: string;
    framingOffered: string;
    framedDispatchDate?: string | null;
    unframedDispatchDate?: string | null;
    fulfillmentStatus: GeneratedItemFulfillmentStatus;
    inTransitAt?: string | null;
    deliveredAt?: string | null;
    quantity: number;
    trackingUrl?: string | null;
  }>;
};

export type GeneratedListCustomerOrdersQueryVariables = Exact<{
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedListCustomerOrdersQuery = {
  __typename?: 'Query';
  listOrders?: {
    __typename?: 'ListOrdersResponse';
    nextPageToken: string;
    orders: Array<{
      __typename?: 'Order';
      shopifyId: string;
      shopifyOrderNumber: string;
      processedAt: string;
      cancelledAt?: string | null;
      fulfillmentStatus: GeneratedOrderFulfillmentStatus;
      financialStatus: GeneratedOrderFinancialStatus;
      lineItems: Array<{
        __typename?: 'OrderLineItem';
        shopifyId: string;
        shopifyProductId: string;
        title: string;
        variantTitle?: string | null;
        featuredImageUrl: string;
        productType: string;
        framingOffered: string;
        framedDispatchDate?: string | null;
        unframedDispatchDate?: string | null;
        fulfillmentStatus: GeneratedItemFulfillmentStatus;
        inTransitAt?: string | null;
        deliveredAt?: string | null;
        quantity: number;
        trackingUrl?: string | null;
      }>;
    }>;
  } | null;
};

export type GeneratedGetOrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GeneratedGetOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    shopifyId: string;
    shopifyOrderNumber: string;
    invoiceURL: string;
    resaleAgreementURL: string;
    shippingMethods: Array<string>;
    processedAt: string;
    cancelledAt?: string | null;
    fulfillmentStatus: GeneratedOrderFulfillmentStatus;
    financialStatus: GeneratedOrderFinancialStatus;
    financialTransactions: Array<{
      __typename?: 'FinancialTransaction';
      type: GeneratedFinancialTransactionType;
      redactedPaymentIdentifier?: string | null;
      brand?: string | null;
    }>;
    shippingAddress?: {
      __typename?: 'OrderAddress';
      firstName: string;
      lastName: string;
      company?: string | null;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      zip: string;
      province?: string | null;
      country?: string | null;
      countryCode?: string | null;
      phone?: string | null;
    } | null;
    billingAddress?: {
      __typename?: 'OrderAddress';
      firstName: string;
      lastName: string;
      company?: string | null;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      zip: string;
      province?: string | null;
      country?: string | null;
      countryCode?: string | null;
      phone?: string | null;
    } | null;
    totalPriceSet?: {
      __typename?: 'MoneySet';
      amount: string;
      currencyCode: string;
    } | null;
    subtotalPriceSet?: {
      __typename?: 'MoneySet';
      amount: string;
      currencyCode: string;
    } | null;
    totalTaxPriceSet?: {
      __typename?: 'MoneySet';
      amount: string;
      currencyCode: string;
    } | null;
    totalShippingPricesSet?: {
      __typename?: 'MoneySet';
      amount: string;
      currencyCode: string;
    } | null;
    totalDiscountsPricesSet?: {
      __typename?: 'MoneySet';
      amount: string;
      currencyCode: string;
    } | null;
    lineItems: Array<{
      __typename?: 'OrderLineItem';
      shopifyId: string;
      shopifyProductId: string;
      title: string;
      variantTitle?: string | null;
      featuredImageUrl: string;
      quantity: number;
      productType: string;
      framingOffered: string;
      framedDispatchDate?: string | null;
      unframedDispatchDate?: string | null;
      fulfillmentStatus: GeneratedItemFulfillmentStatus;
      inTransitAt?: string | null;
      deliveredAt?: string | null;
      trackingUrl?: string | null;
      price: string;
      priceCurrencyCode: string;
    }>;
  };
};

export type GeneratedGetQuestionnaireQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GeneratedGetQuestionnaireQuery = {
  __typename?: 'Query';
  getQuestionnaire?: {
    __typename?: 'Questionnaire';
    id: string;
    frequency?: GeneratedQuestionnaireFrequency | null;
    budget?: GeneratedQuestionnaireBudget | null;
    limitedPrints?: boolean | null;
    limitedSculptures?: boolean | null;
    digital?: boolean | null;
    collectibles?: boolean | null;
    paintings?: boolean | null;
    sculptures?: boolean | null;
    photography?: boolean | null;
    drawings?: boolean | null;
  } | null;
};

export type GeneratedUpdateQuestionnaireMutationVariables = Exact<{
  input: GeneratedUpdateQuestionnaireInput;
}>;

export type GeneratedUpdateQuestionnaireMutation = {
  __typename?: 'Mutation';
  updateQuestionnaire: { __typename?: 'Questionnaire'; id: string };
};

export type GeneratedReviewDetailsFragment = {
  __typename?: 'Review';
  id: string;
  shopifyProductId: string;
  freeText: string;
  images: Array<string>;
  product?: {
    __typename?: 'Product';
    id: string;
    slug: string;
    title: string;
    artists: Array<{
      __typename?: 'Artist';
      id: string;
      slug: string;
      name: string;
    }>;
  } | null;
};

export type GeneratedReviewsQueryVariables = Exact<{
  shopifyProductId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedReviewsQuery = {
  __typename?: 'Query';
  reviews: Array<{
    __typename?: 'Review';
    id: string;
    shopifyProductId: string;
    freeText: string;
    images: Array<string>;
    product?: {
      __typename?: 'Product';
      id: string;
      slug: string;
      title: string;
      artists: Array<{
        __typename?: 'Artist';
        id: string;
        slug: string;
        name: string;
      }>;
    } | null;
  }>;
};

export type GeneratedSignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedSignupMutation = {
  __typename?: 'Mutation';
  signup: boolean;
};

export type GeneratedSignupAndSubscribeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<
    Array<GeneratedCreateSubscriptionsInput> | GeneratedCreateSubscriptionsInput
  >;
  silenceLoginCodeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedSignupAndSubscribeMutation = {
  __typename?: 'Mutation';
  signupAndSubscribe: boolean;
};

export type GeneratedSlackMessageMutationVariables = Exact<{
  channel: GeneratedSlackChannel;
  msg: Scalars['String']['input'];
  captcha: Scalars['String']['input'];
}>;

export type GeneratedSlackMessageMutation = {
  __typename?: 'Mutation';
  SlackMessage: boolean;
};

export type GeneratedAccountFragment = {
  __typename?: 'Account';
  id: string;
  username?: string | null;
  fullName?: string | null;
  email?: string | null;
  countryCode?: string | null;
  phone?: string | null;
  pronouns?: GeneratedPronouns | null;
  createdAt?: string | null;
  dateOfBirth?: string | null;
};

export type GeneratedUpdateAccountMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<GeneratedPronouns>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedUpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: string;
    username?: string | null;
    fullName?: string | null;
    email?: string | null;
    countryCode?: string | null;
    phone?: string | null;
    pronouns?: GeneratedPronouns | null;
    createdAt?: string | null;
    dateOfBirth?: string | null;
  };
};

export type GeneratedUpdateDrawEntryIntentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  currencyCode: Scalars['String']['input'];
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  optionalItems?: InputMaybe<
    Array<InputMaybe<GeneratedOrderItem>> | InputMaybe<GeneratedOrderItem>
  >;
  cryptoPaymentRequested?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GeneratedUpdateDrawEntryIntentMutation = {
  __typename?: 'Mutation';
  updateDrawEntryIntent?: {
    __typename?: 'DrawEntryIntentUpdateResponse';
    readyToConfirm: boolean;
    cryptoComPaymentId?: string | null;
    intent: {
      __typename?: 'DrawEntryIntent';
      id: string;
      errorCode?: string | null;
      confirmedAt?: string | null;
      discardedAt?: string | null;
    };
    pricing: {
      __typename?: 'OrderPricingQuote';
      id: string;
      createdAt: string;
      pricing: {
        __typename?: 'OrdersPriceCalculation';
        totalPrice: number;
        subtotalTaxPrice: number;
        subtotalShippingPrice: number;
        subtotalItemsPrice: number;
        currencyCode: string;
        taxRate: number;
        taxType: GeneratedTaxType;
        includesCalculatedShipping: boolean;
        includesCalculatedTaxes: boolean;
        breakdown: Array<{
          __typename?: 'OrdersItemPricing';
          productId: string;
          variantId: string;
          title: string;
          price: number;
          quantity: number;
          isTaxable: boolean;
          freeShipping: boolean;
          requiresShipping: boolean;
          tags: Array<string>;
          carrierServiceName: string;
          shippingProviderId: string;
        }>;
      };
    };
  } | null;
};

export type GeneratedUpdateOrderAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  shippingAddress: GeneratedCreateOrUpdateAddressInput;
}>;

export type GeneratedUpdateOrderAddressMutation = {
  __typename?: 'Mutation';
  updateOrder: boolean;
};

export type GeneratedUploadUrlQueryVariables = Exact<{
  contentType: Scalars['String']['input'];
}>;

export type GeneratedUploadUrlQuery = {
  __typename?: 'Query';
  uploadURL: string;
};

export type GeneratedUseLoginCodeMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  googleCredential?: InputMaybe<Scalars['String']['input']>;
  acceptsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  originUTMCampaign?: InputMaybe<Scalars['String']['input']>;
  originUTMMedium?: InputMaybe<Scalars['String']['input']>;
  originUTMSource?: InputMaybe<Scalars['String']['input']>;
}>;

export type GeneratedUseLoginCodeMutation = {
  __typename?: 'Mutation';
  useLoginCode: {
    __typename?: 'UseLoginCodeResult';
    isNewAccount: boolean;
    account: {
      __typename?: 'Account';
      id: string;
      username?: string | null;
      fullName?: string | null;
      email?: string | null;
      countryCode?: string | null;
      phone?: string | null;
      pronouns?: GeneratedPronouns | null;
      createdAt?: string | null;
      dateOfBirth?: string | null;
    };
  };
};

export const ArtistTabFullFragmentDoc = gql`
  fragment ArtistTabFull on ArtistTab {
    id
    artistID
    label
    slug
    priority
    overrideSize
    blockIDs
    createdAt
    updatedAt
  }
`;
export const ArtistBlockFullFragmentDoc = gql`
  fragment ArtistBlockFull on ArtistBlock {
    id
    artistID
    kind
    size
    action
    title
    description
    overline
    intro
    url
    date {
      start
      end
    }
    image {
      filename
      alt
    }
    video {
      id
      platform
      duration
    }
    createdAt
    updatedAt
  }
`;
export const SubscriptionsCountFragmentDoc = gql`
  fragment SubscriptionsCount on SubscriptionsCount {
    count
    accounts {
      avatar
      id
    }
  }
`;
export const OrderDetailsFragmentDoc = gql`
  fragment OrderDetails on Order {
    shopifyId
    shopifyOrderNumber
    invoiceURL
    resaleAgreementURL
    shippingMethods
    processedAt
    cancelledAt
    financialTransactions {
      type
      redactedPaymentIdentifier
      brand
    }
    fulfillmentStatus
    financialStatus
    shippingAddress {
      firstName
      lastName
      company
      addressLine1
      addressLine2
      city
      zip
      province
      country
      countryCode
      phone
    }
    billingAddress {
      firstName
      lastName
      company
      addressLine1
      addressLine2
      city
      zip
      province
      country
      countryCode
      phone
    }
    totalPriceSet {
      amount
      currencyCode
    }
    subtotalPriceSet {
      amount
      currencyCode
    }
    totalTaxPriceSet {
      amount
      currencyCode
    }
    totalShippingPricesSet {
      amount
      currencyCode
    }
    totalDiscountsPricesSet {
      amount
      currencyCode
    }
    lineItems {
      shopifyId
      shopifyProductId
      title
      variantTitle
      featuredImageUrl
      quantity
      productType
      framingOffered
      framedDispatchDate
      unframedDispatchDate
      fulfillmentStatus
      inTransitAt
      deliveredAt
      trackingUrl
      price
      priceCurrencyCode
    }
  }
`;
export const OrderDetailsSummaryFragmentDoc = gql`
  fragment OrderDetailsSummary on Order {
    shopifyId
    shopifyOrderNumber
    processedAt
    cancelledAt
    fulfillmentStatus
    financialStatus
    lineItems {
      shopifyId
      shopifyProductId
      title
      variantTitle
      featuredImageUrl
      productType
      framingOffered
      framedDispatchDate
      unframedDispatchDate
      fulfillmentStatus
      inTransitAt
      deliveredAt
      quantity
      trackingUrl
    }
  }
`;
export const ReviewDetailsFragmentDoc = gql`
  fragment ReviewDetails on Review {
    id
    shopifyProductId
    freeText
    images
    product {
      id
      slug
      title
      artists {
        id
        slug
        name
      }
    }
  }
`;
export const AccountFragmentDoc = gql`
  fragment Account on Account {
    id
    username
    fullName
    email
    countryCode
    phone
    pronouns
    createdAt
    dateOfBirth
  }
`;
export const GenerateMultipassTokenDocument = gql`
  mutation GenerateMultipassToken($returnTo: String!) {
    generateMultipassToken(returnTo: $returnTo) {
      token
    }
  }
`;
export type GeneratedGenerateMultipassTokenMutationFn = Apollo.MutationFunction<
  GeneratedGenerateMultipassTokenMutation,
  GeneratedGenerateMultipassTokenMutationVariables
>;

/**
 * __useGenerateMultipassTokenMutation__
 *
 * To run a mutation, you first call `useGenerateMultipassTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMultipassTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMultipassTokenMutation, { data, loading, error }] = useGenerateMultipassTokenMutation({
 *   variables: {
 *      returnTo: // value for 'returnTo'
 *   },
 * });
 */
export function useGenerateMultipassTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedGenerateMultipassTokenMutation,
    GeneratedGenerateMultipassTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedGenerateMultipassTokenMutation,
    GeneratedGenerateMultipassTokenMutationVariables
  >(GenerateMultipassTokenDocument, options);
}
export type GenerateMultipassTokenMutationHookResult = ReturnType<
  typeof useGenerateMultipassTokenMutation
>;
export type GenerateMultipassTokenMutationResult =
  Apollo.MutationResult<GeneratedGenerateMultipassTokenMutation>;
export type GenerateMultipassTokenMutationOptions = Apollo.BaseMutationOptions<
  GeneratedGenerateMultipassTokenMutation,
  GeneratedGenerateMultipassTokenMutationVariables
>;
export const GenerateAvantPassTokenDocument = gql`
  mutation GenerateAvantPassToken($input: GenerateAvantPassTokenInput!) {
    generateAvantPassToken(input: $input) {
      token
    }
  }
`;
export type GeneratedGenerateAvantPassTokenMutationFn = Apollo.MutationFunction<
  GeneratedGenerateAvantPassTokenMutation,
  GeneratedGenerateAvantPassTokenMutationVariables
>;

/**
 * __useGenerateAvantPassTokenMutation__
 *
 * To run a mutation, you first call `useGenerateAvantPassTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAvantPassTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAvantPassTokenMutation, { data, loading, error }] = useGenerateAvantPassTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAvantPassTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedGenerateAvantPassTokenMutation,
    GeneratedGenerateAvantPassTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedGenerateAvantPassTokenMutation,
    GeneratedGenerateAvantPassTokenMutationVariables
  >(GenerateAvantPassTokenDocument, options);
}
export type GenerateAvantPassTokenMutationHookResult = ReturnType<
  typeof useGenerateAvantPassTokenMutation
>;
export type GenerateAvantPassTokenMutationResult =
  Apollo.MutationResult<GeneratedGenerateAvantPassTokenMutation>;
export type GenerateAvantPassTokenMutationOptions = Apollo.BaseMutationOptions<
  GeneratedGenerateAvantPassTokenMutation,
  GeneratedGenerateAvantPassTokenMutationVariables
>;
export const CreateAddressDocument = gql`
  mutation CreateAddress($address: CreateOrUpdateAddressInput!) {
    createAddress(address: $address) {
      id
      addressLine1
      addressLine2
      city
      countryCode
      countryName
      customsCode
      company
      firstName
      lastName
      phone
      provinceCode
      provinceName
      zip
      isDefault
    }
  }
`;
export type GeneratedCreateAddressMutationFn = Apollo.MutationFunction<
  GeneratedCreateAddressMutation,
  GeneratedCreateAddressMutationVariables
>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedCreateAddressMutation,
    GeneratedCreateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedCreateAddressMutation,
    GeneratedCreateAddressMutationVariables
  >(CreateAddressDocument, options);
}
export type CreateAddressMutationHookResult = ReturnType<
  typeof useCreateAddressMutation
>;
export type CreateAddressMutationResult =
  Apollo.MutationResult<GeneratedCreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  GeneratedCreateAddressMutation,
  GeneratedCreateAddressMutationVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress(
    $addressId: String!
    $changes: CreateOrUpdateAddressInput!
  ) {
    updateAddress(addressId: $addressId, changes: $changes) {
      id
      addressLine1
      addressLine2
      city
      countryCode
      countryName
      customsCode
      company
      firstName
      lastName
      phone
      provinceCode
      provinceName
      zip
      isDefault
    }
  }
`;
export type GeneratedUpdateAddressMutationFn = Apollo.MutationFunction<
  GeneratedUpdateAddressMutation,
  GeneratedUpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpdateAddressMutation,
    GeneratedUpdateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpdateAddressMutation,
    GeneratedUpdateAddressMutationVariables
  >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
  typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
  Apollo.MutationResult<GeneratedUpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpdateAddressMutation,
  GeneratedUpdateAddressMutationVariables
>;
export const DeleteAddressDocument = gql`
  mutation DeleteAddress($addressId: String!) {
    deleteAddress(addressId: $addressId)
  }
`;
export type GeneratedDeleteAddressMutationFn = Apollo.MutationFunction<
  GeneratedDeleteAddressMutation,
  GeneratedDeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedDeleteAddressMutation,
    GeneratedDeleteAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedDeleteAddressMutation,
    GeneratedDeleteAddressMutationVariables
  >(DeleteAddressDocument, options);
}
export type DeleteAddressMutationHookResult = ReturnType<
  typeof useDeleteAddressMutation
>;
export type DeleteAddressMutationResult =
  Apollo.MutationResult<GeneratedDeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  GeneratedDeleteAddressMutation,
  GeneratedDeleteAddressMutationVariables
>;
export const Web3GenerateNonceDocument = gql`
  mutation Web3GenerateNonce($walletAddress: String!, $message: String!) {
    web3GenerateNonce(walletAddress: $walletAddress, message: $message)
  }
`;
export type GeneratedWeb3GenerateNonceMutationFn = Apollo.MutationFunction<
  GeneratedWeb3GenerateNonceMutation,
  GeneratedWeb3GenerateNonceMutationVariables
>;

/**
 * __useWeb3GenerateNonceMutation__
 *
 * To run a mutation, you first call `useWeb3GenerateNonceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWeb3GenerateNonceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [web3GenerateNonceMutation, { data, loading, error }] = useWeb3GenerateNonceMutation({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useWeb3GenerateNonceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedWeb3GenerateNonceMutation,
    GeneratedWeb3GenerateNonceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedWeb3GenerateNonceMutation,
    GeneratedWeb3GenerateNonceMutationVariables
  >(Web3GenerateNonceDocument, options);
}
export type Web3GenerateNonceMutationHookResult = ReturnType<
  typeof useWeb3GenerateNonceMutation
>;
export type Web3GenerateNonceMutationResult =
  Apollo.MutationResult<GeneratedWeb3GenerateNonceMutation>;
export type Web3GenerateNonceMutationOptions = Apollo.BaseMutationOptions<
  GeneratedWeb3GenerateNonceMutation,
  GeneratedWeb3GenerateNonceMutationVariables
>;
export const Web3VerifySignatureDocument = gql`
  mutation Web3VerifySignature($walletAddress: String!, $signature: String!) {
    web3VerifySignature(walletAddress: $walletAddress, signature: $signature)
  }
`;
export type GeneratedWeb3VerifySignatureMutationFn = Apollo.MutationFunction<
  GeneratedWeb3VerifySignatureMutation,
  GeneratedWeb3VerifySignatureMutationVariables
>;

/**
 * __useWeb3VerifySignatureMutation__
 *
 * To run a mutation, you first call `useWeb3VerifySignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWeb3VerifySignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [web3VerifySignatureMutation, { data, loading, error }] = useWeb3VerifySignatureMutation({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useWeb3VerifySignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedWeb3VerifySignatureMutation,
    GeneratedWeb3VerifySignatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedWeb3VerifySignatureMutation,
    GeneratedWeb3VerifySignatureMutationVariables
  >(Web3VerifySignatureDocument, options);
}
export type Web3VerifySignatureMutationHookResult = ReturnType<
  typeof useWeb3VerifySignatureMutation
>;
export type Web3VerifySignatureMutationResult =
  Apollo.MutationResult<GeneratedWeb3VerifySignatureMutation>;
export type Web3VerifySignatureMutationOptions = Apollo.BaseMutationOptions<
  GeneratedWeb3VerifySignatureMutation,
  GeneratedWeb3VerifySignatureMutationVariables
>;
export const MutationEnterDrawDocument = gql`
  mutation MutationEnterDraw($id: ID!, $refID: String, $note: String) {
    enterDraw(id: $id, refID: $refID, note: $note) {
      accountIsInDraw
    }
  }
`;
export type GeneratedMutationEnterDrawMutationFn = Apollo.MutationFunction<
  GeneratedMutationEnterDrawMutation,
  GeneratedMutationEnterDrawMutationVariables
>;

/**
 * __useMutationEnterDrawMutation__
 *
 * To run a mutation, you first call `useMutationEnterDrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationEnterDrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationEnterDrawMutation, { data, loading, error }] = useMutationEnterDrawMutation({
 *   variables: {
 *      id: // value for 'id'
 *      refID: // value for 'refID'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useMutationEnterDrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedMutationEnterDrawMutation,
    GeneratedMutationEnterDrawMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedMutationEnterDrawMutation,
    GeneratedMutationEnterDrawMutationVariables
  >(MutationEnterDrawDocument, options);
}
export type MutationEnterDrawMutationHookResult = ReturnType<
  typeof useMutationEnterDrawMutation
>;
export type MutationEnterDrawMutationResult =
  Apollo.MutationResult<GeneratedMutationEnterDrawMutation>;
export type MutationEnterDrawMutationOptions = Apollo.BaseMutationOptions<
  GeneratedMutationEnterDrawMutation,
  GeneratedMutationEnterDrawMutationVariables
>;
export const AuthenticateNftPrintDocument = gql`
  mutation authenticateNftPrint($input: AuthenticateNftPrintInput!) {
    authenticateNftPrint(input: $input) {
      print {
        id
        tokenId
        owner
        authenticated
        productId
        orderedAt
      }
      events {
        id
        owner
        authenticated
        productId
        createTime
        event
      }
    }
  }
`;
export type GeneratedAuthenticateNftPrintMutationFn = Apollo.MutationFunction<
  GeneratedAuthenticateNftPrintMutation,
  GeneratedAuthenticateNftPrintMutationVariables
>;

/**
 * __useAuthenticateNftPrintMutation__
 *
 * To run a mutation, you first call `useAuthenticateNftPrintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateNftPrintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateNftPrintMutation, { data, loading, error }] = useAuthenticateNftPrintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateNftPrintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedAuthenticateNftPrintMutation,
    GeneratedAuthenticateNftPrintMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedAuthenticateNftPrintMutation,
    GeneratedAuthenticateNftPrintMutationVariables
  >(AuthenticateNftPrintDocument, options);
}
export type AuthenticateNftPrintMutationHookResult = ReturnType<
  typeof useAuthenticateNftPrintMutation
>;
export type AuthenticateNftPrintMutationResult =
  Apollo.MutationResult<GeneratedAuthenticateNftPrintMutation>;
export type AuthenticateNftPrintMutationOptions = Apollo.BaseMutationOptions<
  GeneratedAuthenticateNftPrintMutation,
  GeneratedAuthenticateNftPrintMutationVariables
>;
export const MutationDetachPaymentMethodDocument = gql`
  mutation MutationDetachPaymentMethod(
    $id: ID!
    $providerId: PaymentProvider!
  ) {
    detachPaymentMethod(id: $id, providerId: $providerId)
  }
`;
export type GeneratedMutationDetachPaymentMethodMutationFn =
  Apollo.MutationFunction<
    GeneratedMutationDetachPaymentMethodMutation,
    GeneratedMutationDetachPaymentMethodMutationVariables
  >;

/**
 * __useMutationDetachPaymentMethodMutation__
 *
 * To run a mutation, you first call `useMutationDetachPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationDetachPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationDetachPaymentMethodMutation, { data, loading, error }] = useMutationDetachPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useMutationDetachPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedMutationDetachPaymentMethodMutation,
    GeneratedMutationDetachPaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedMutationDetachPaymentMethodMutation,
    GeneratedMutationDetachPaymentMethodMutationVariables
  >(MutationDetachPaymentMethodDocument, options);
}
export type MutationDetachPaymentMethodMutationHookResult = ReturnType<
  typeof useMutationDetachPaymentMethodMutation
>;
export type MutationDetachPaymentMethodMutationResult =
  Apollo.MutationResult<GeneratedMutationDetachPaymentMethodMutation>;
export type MutationDetachPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    GeneratedMutationDetachPaymentMethodMutation,
    GeneratedMutationDetachPaymentMethodMutationVariables
  >;
export const RefreshStreamTokenDocument = gql`
  query RefreshStreamToken {
    refreshStreamToken
  }
`;

/**
 * __useRefreshStreamTokenQuery__
 *
 * To run a query within a React component, call `useRefreshStreamTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshStreamTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshStreamTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshStreamTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedRefreshStreamTokenQuery,
    GeneratedRefreshStreamTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedRefreshStreamTokenQuery,
    GeneratedRefreshStreamTokenQueryVariables
  >(RefreshStreamTokenDocument, options);
}
export function useRefreshStreamTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedRefreshStreamTokenQuery,
    GeneratedRefreshStreamTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedRefreshStreamTokenQuery,
    GeneratedRefreshStreamTokenQueryVariables
  >(RefreshStreamTokenDocument, options);
}
export function useRefreshStreamTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedRefreshStreamTokenQuery,
        GeneratedRefreshStreamTokenQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedRefreshStreamTokenQuery,
    GeneratedRefreshStreamTokenQueryVariables
  >(RefreshStreamTokenDocument, options);
}
export type RefreshStreamTokenQueryHookResult = ReturnType<
  typeof useRefreshStreamTokenQuery
>;
export type RefreshStreamTokenLazyQueryHookResult = ReturnType<
  typeof useRefreshStreamTokenLazyQuery
>;
export type RefreshStreamTokenSuspenseQueryHookResult = ReturnType<
  typeof useRefreshStreamTokenSuspenseQuery
>;
export type RefreshStreamTokenQueryResult = Apollo.QueryResult<
  GeneratedRefreshStreamTokenQuery,
  GeneratedRefreshStreamTokenQueryVariables
>;
export const QueryAccountIsInDrawDocument = gql`
  query QueryAccountIsInDraw($id: ID!) {
    draw(id: $id) {
      accountIsInDraw
    }
  }
`;

/**
 * __useQueryAccountIsInDrawQuery__
 *
 * To run a query within a React component, call `useQueryAccountIsInDrawQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryAccountIsInDrawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryAccountIsInDrawQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryAccountIsInDrawQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedQueryAccountIsInDrawQuery,
    GeneratedQueryAccountIsInDrawQueryVariables
  > &
    (
      | {
          variables: GeneratedQueryAccountIsInDrawQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedQueryAccountIsInDrawQuery,
    GeneratedQueryAccountIsInDrawQueryVariables
  >(QueryAccountIsInDrawDocument, options);
}
export function useQueryAccountIsInDrawLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedQueryAccountIsInDrawQuery,
    GeneratedQueryAccountIsInDrawQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedQueryAccountIsInDrawQuery,
    GeneratedQueryAccountIsInDrawQueryVariables
  >(QueryAccountIsInDrawDocument, options);
}
export function useQueryAccountIsInDrawSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedQueryAccountIsInDrawQuery,
        GeneratedQueryAccountIsInDrawQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedQueryAccountIsInDrawQuery,
    GeneratedQueryAccountIsInDrawQueryVariables
  >(QueryAccountIsInDrawDocument, options);
}
export type QueryAccountIsInDrawQueryHookResult = ReturnType<
  typeof useQueryAccountIsInDrawQuery
>;
export type QueryAccountIsInDrawLazyQueryHookResult = ReturnType<
  typeof useQueryAccountIsInDrawLazyQuery
>;
export type QueryAccountIsInDrawSuspenseQueryHookResult = ReturnType<
  typeof useQueryAccountIsInDrawSuspenseQuery
>;
export type QueryAccountIsInDrawQueryResult = Apollo.QueryResult<
  GeneratedQueryAccountIsInDrawQuery,
  GeneratedQueryAccountIsInDrawQueryVariables
>;
export const DrawDocument = gql`
  query draw($id: ID!) {
    draw(id: $id) {
      accountIsInDraw
      allocation
      openAt
      preOrderCloseAt
      totalAccounts
      accountIsInDraw
      status
      closeAt
      cryptoComEnabled
    }
  }
`;

/**
 * __useDrawQuery__
 *
 * To run a query within a React component, call `useDrawQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrawQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDrawQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedDrawQuery,
    GeneratedDrawQueryVariables
  > &
    (
      | { variables: GeneratedDrawQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneratedDrawQuery, GeneratedDrawQueryVariables>(
    DrawDocument,
    options,
  );
}
export function useDrawLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedDrawQuery,
    GeneratedDrawQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeneratedDrawQuery, GeneratedDrawQueryVariables>(
    DrawDocument,
    options,
  );
}
export function useDrawSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedDrawQuery,
        GeneratedDrawQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedDrawQuery,
    GeneratedDrawQueryVariables
  >(DrawDocument, options);
}
export type DrawQueryHookResult = ReturnType<typeof useDrawQuery>;
export type DrawLazyQueryHookResult = ReturnType<typeof useDrawLazyQuery>;
export type DrawSuspenseQueryHookResult = ReturnType<
  typeof useDrawSuspenseQuery
>;
export type DrawQueryResult = Apollo.QueryResult<
  GeneratedDrawQuery,
  GeneratedDrawQueryVariables
>;
export const GetShippingRateByProductVariantIdDocument = gql`
  query GetShippingRateByProductVariantID(
    $productVariantID: String!
    $countryCode: String!
    $currencyCode: String!
  ) {
    getShippingRateByProductVariantID(
      productVariantID: $productVariantID
      countryCode: $countryCode
      currencyCode: $currencyCode
    ) {
      currencyCode
      shippingRate
    }
  }
`;

/**
 * __useGetShippingRateByProductVariantIdQuery__
 *
 * To run a query within a React component, call `useGetShippingRateByProductVariantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingRateByProductVariantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingRateByProductVariantIdQuery({
 *   variables: {
 *      productVariantID: // value for 'productVariantID'
 *      countryCode: // value for 'countryCode'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useGetShippingRateByProductVariantIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetShippingRateByProductVariantIdQuery,
    GeneratedGetShippingRateByProductVariantIdQueryVariables
  > &
    (
      | {
          variables: GeneratedGetShippingRateByProductVariantIdQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetShippingRateByProductVariantIdQuery,
    GeneratedGetShippingRateByProductVariantIdQueryVariables
  >(GetShippingRateByProductVariantIdDocument, options);
}
export function useGetShippingRateByProductVariantIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetShippingRateByProductVariantIdQuery,
    GeneratedGetShippingRateByProductVariantIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetShippingRateByProductVariantIdQuery,
    GeneratedGetShippingRateByProductVariantIdQueryVariables
  >(GetShippingRateByProductVariantIdDocument, options);
}
export function useGetShippingRateByProductVariantIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetShippingRateByProductVariantIdQuery,
        GeneratedGetShippingRateByProductVariantIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetShippingRateByProductVariantIdQuery,
    GeneratedGetShippingRateByProductVariantIdQueryVariables
  >(GetShippingRateByProductVariantIdDocument, options);
}
export type GetShippingRateByProductVariantIdQueryHookResult = ReturnType<
  typeof useGetShippingRateByProductVariantIdQuery
>;
export type GetShippingRateByProductVariantIdLazyQueryHookResult = ReturnType<
  typeof useGetShippingRateByProductVariantIdLazyQuery
>;
export type GetShippingRateByProductVariantIdSuspenseQueryHookResult =
  ReturnType<typeof useGetShippingRateByProductVariantIdSuspenseQuery>;
export type GetShippingRateByProductVariantIdQueryResult = Apollo.QueryResult<
  GeneratedGetShippingRateByProductVariantIdQuery,
  GeneratedGetShippingRateByProductVariantIdQueryVariables
>;
export const GetProductsDocument = gql`
  query getProducts(
    $ids: [String!]
    $shopifyIDs: [String!]
    $slugs: [String!]
    $previewToken: String
  ) {
    getProducts(
      ids: $ids
      shopifyIDs: $shopifyIDs
      slugs: $slugs
      previewToken: $previewToken
    ) {
      id
      shopifyId
      artistIds
      slug
      title
      price
      medium
      releaseType
      availability
      isFrame
      drawLaunch
      editionSize
      cryptoDisplayPrice
      framingOffered
      framedDispatchDate
      framingUpsellEndTime
      shippingUpdateEndTime
      unframedDispatchDate
      tags
      launchAt
      closeAt
      variants {
        id
        productId
        shopifyId
        title
        sku
        available
        requiresShipping
        price
      }
      thumbnail {
        url
        width
        height
        alt
      }
      images {
        url
        width
        height
        alt
      }
      artists {
        name
        id
      }
    }
  }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      shopifyIDs: // value for 'shopifyIDs'
 *      slugs: // value for 'slugs'
 *      previewToken: // value for 'previewToken'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedGetProductsQuery,
    GeneratedGetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetProductsQuery,
    GeneratedGetProductsQueryVariables
  >(GetProductsDocument, options);
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetProductsQuery,
    GeneratedGetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetProductsQuery,
    GeneratedGetProductsQueryVariables
  >(GetProductsDocument, options);
}
export function useGetProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetProductsQuery,
        GeneratedGetProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetProductsQuery,
    GeneratedGetProductsQueryVariables
  >(GetProductsDocument, options);
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>;
export type GetProductsSuspenseQueryHookResult = ReturnType<
  typeof useGetProductsSuspenseQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GeneratedGetProductsQuery,
  GeneratedGetProductsQueryVariables
>;
export const GetTaxesByCountryCodeDocument = gql`
  query GetTaxesByCountryCode($countryCode: String!) {
    getTaxesByCountryCode(countryCode: $countryCode) {
      countryCode
      dutyTaxRate
      taxType
      vatRate
    }
  }
`;

/**
 * __useGetTaxesByCountryCodeQuery__
 *
 * To run a query within a React component, call `useGetTaxesByCountryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxesByCountryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxesByCountryCodeQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetTaxesByCountryCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetTaxesByCountryCodeQuery,
    GeneratedGetTaxesByCountryCodeQueryVariables
  > &
    (
      | {
          variables: GeneratedGetTaxesByCountryCodeQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetTaxesByCountryCodeQuery,
    GeneratedGetTaxesByCountryCodeQueryVariables
  >(GetTaxesByCountryCodeDocument, options);
}
export function useGetTaxesByCountryCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetTaxesByCountryCodeQuery,
    GeneratedGetTaxesByCountryCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetTaxesByCountryCodeQuery,
    GeneratedGetTaxesByCountryCodeQueryVariables
  >(GetTaxesByCountryCodeDocument, options);
}
export function useGetTaxesByCountryCodeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetTaxesByCountryCodeQuery,
        GeneratedGetTaxesByCountryCodeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetTaxesByCountryCodeQuery,
    GeneratedGetTaxesByCountryCodeQueryVariables
  >(GetTaxesByCountryCodeDocument, options);
}
export type GetTaxesByCountryCodeQueryHookResult = ReturnType<
  typeof useGetTaxesByCountryCodeQuery
>;
export type GetTaxesByCountryCodeLazyQueryHookResult = ReturnType<
  typeof useGetTaxesByCountryCodeLazyQuery
>;
export type GetTaxesByCountryCodeSuspenseQueryHookResult = ReturnType<
  typeof useGetTaxesByCountryCodeSuspenseQuery
>;
export type GetTaxesByCountryCodeQueryResult = Apollo.QueryResult<
  GeneratedGetTaxesByCountryCodeQuery,
  GeneratedGetTaxesByCountryCodeQueryVariables
>;
export const GetAddressesDocument = gql`
  query GetAddresses {
    addresses {
      id
      addressLine1
      addressLine2
      city
      countryCode
      countryName
      customsCode
      company
      firstName
      lastName
      phone
      provinceCode
      provinceName
      zip
      isDefault
    }
  }
`;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedGetAddressesQuery,
    GeneratedGetAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetAddressesQuery,
    GeneratedGetAddressesQueryVariables
  >(GetAddressesDocument, options);
}
export function useGetAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetAddressesQuery,
    GeneratedGetAddressesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetAddressesQuery,
    GeneratedGetAddressesQueryVariables
  >(GetAddressesDocument, options);
}
export function useGetAddressesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetAddressesQuery,
        GeneratedGetAddressesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetAddressesQuery,
    GeneratedGetAddressesQueryVariables
  >(GetAddressesDocument, options);
}
export type GetAddressesQueryHookResult = ReturnType<
  typeof useGetAddressesQuery
>;
export type GetAddressesLazyQueryHookResult = ReturnType<
  typeof useGetAddressesLazyQuery
>;
export type GetAddressesSuspenseQueryHookResult = ReturnType<
  typeof useGetAddressesSuspenseQuery
>;
export type GetAddressesQueryResult = Apollo.QueryResult<
  GeneratedGetAddressesQuery,
  GeneratedGetAddressesQueryVariables
>;
export const SearchArtistsDocument = gql`
  query SearchArtists($query: String!) {
    searchArtists(query: $query) {
      id
      name
      thumbnail
    }
  }
`;

/**
 * __useSearchArtistsQuery__
 *
 * To run a query within a React component, call `useSearchArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchArtistsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchArtistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedSearchArtistsQuery,
    GeneratedSearchArtistsQueryVariables
  > &
    (
      | { variables: GeneratedSearchArtistsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedSearchArtistsQuery,
    GeneratedSearchArtistsQueryVariables
  >(SearchArtistsDocument, options);
}
export function useSearchArtistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedSearchArtistsQuery,
    GeneratedSearchArtistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedSearchArtistsQuery,
    GeneratedSearchArtistsQueryVariables
  >(SearchArtistsDocument, options);
}
export function useSearchArtistsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedSearchArtistsQuery,
        GeneratedSearchArtistsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedSearchArtistsQuery,
    GeneratedSearchArtistsQueryVariables
  >(SearchArtistsDocument, options);
}
export type SearchArtistsQueryHookResult = ReturnType<
  typeof useSearchArtistsQuery
>;
export type SearchArtistsLazyQueryHookResult = ReturnType<
  typeof useSearchArtistsLazyQuery
>;
export type SearchArtistsSuspenseQueryHookResult = ReturnType<
  typeof useSearchArtistsSuspenseQuery
>;
export type SearchArtistsQueryResult = Apollo.QueryResult<
  GeneratedSearchArtistsQuery,
  GeneratedSearchArtistsQueryVariables
>;
export const GetArtistsBySlugDocument = gql`
  query GetArtistsBySlug($slugs: [String!]!) {
    artistsBySlug(slugs: $slugs) {
      id
      name
      slug
      thumbnail
      website
      instagram
      twitter
      tiktok
      ArteBio
    }
  }
`;

/**
 * __useGetArtistsBySlugQuery__
 *
 * To run a query within a React component, call `useGetArtistsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useGetArtistsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetArtistsBySlugQuery,
    GeneratedGetArtistsBySlugQueryVariables
  > &
    (
      | { variables: GeneratedGetArtistsBySlugQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetArtistsBySlugQuery,
    GeneratedGetArtistsBySlugQueryVariables
  >(GetArtistsBySlugDocument, options);
}
export function useGetArtistsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetArtistsBySlugQuery,
    GeneratedGetArtistsBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetArtistsBySlugQuery,
    GeneratedGetArtistsBySlugQueryVariables
  >(GetArtistsBySlugDocument, options);
}
export function useGetArtistsBySlugSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetArtistsBySlugQuery,
        GeneratedGetArtistsBySlugQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetArtistsBySlugQuery,
    GeneratedGetArtistsBySlugQueryVariables
  >(GetArtistsBySlugDocument, options);
}
export type GetArtistsBySlugQueryHookResult = ReturnType<
  typeof useGetArtistsBySlugQuery
>;
export type GetArtistsBySlugLazyQueryHookResult = ReturnType<
  typeof useGetArtistsBySlugLazyQuery
>;
export type GetArtistsBySlugSuspenseQueryHookResult = ReturnType<
  typeof useGetArtistsBySlugSuspenseQuery
>;
export type GetArtistsBySlugQueryResult = Apollo.QueryResult<
  GeneratedGetArtistsBySlugQuery,
  GeneratedGetArtistsBySlugQueryVariables
>;
export const NftsVouchersDocument = gql`
  query nftsVouchers($productId: String!) {
    nftsGetVouchers(productId: $productId) {
      vouchers {
        claimed
        voucher {
          projectId
          walletAddress
          voucherId
          price
          validUntil
          tokenId
        }
      }
    }
  }
`;

/**
 * __useNftsVouchersQuery__
 *
 * To run a query within a React component, call `useNftsVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftsVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftsVouchersQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useNftsVouchersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedNftsVouchersQuery,
    GeneratedNftsVouchersQueryVariables
  > &
    (
      | { variables: GeneratedNftsVouchersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedNftsVouchersQuery,
    GeneratedNftsVouchersQueryVariables
  >(NftsVouchersDocument, options);
}
export function useNftsVouchersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedNftsVouchersQuery,
    GeneratedNftsVouchersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedNftsVouchersQuery,
    GeneratedNftsVouchersQueryVariables
  >(NftsVouchersDocument, options);
}
export function useNftsVouchersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedNftsVouchersQuery,
        GeneratedNftsVouchersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedNftsVouchersQuery,
    GeneratedNftsVouchersQueryVariables
  >(NftsVouchersDocument, options);
}
export type NftsVouchersQueryHookResult = ReturnType<
  typeof useNftsVouchersQuery
>;
export type NftsVouchersLazyQueryHookResult = ReturnType<
  typeof useNftsVouchersLazyQuery
>;
export type NftsVouchersSuspenseQueryHookResult = ReturnType<
  typeof useNftsVouchersSuspenseQuery
>;
export type NftsVouchersQueryResult = Apollo.QueryResult<
  GeneratedNftsVouchersQuery,
  GeneratedNftsVouchersQueryVariables
>;
export const NftVoucherByVoucherNumberDocument = gql`
  query nftVoucherByVoucherNumber($productId: String!, $voucherNumber: Int!) {
    nftVoucherByVoucherNumber(
      productId: $productId
      voucherNumber: $voucherNumber
    ) {
      voucher {
        projectId
        price
        validUntil
        tokenId
        nftContractAddress
        chainId
        voucherId
      }
      claimed
    }
  }
`;

/**
 * __useNftVoucherByVoucherNumberQuery__
 *
 * To run a query within a React component, call `useNftVoucherByVoucherNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftVoucherByVoucherNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftVoucherByVoucherNumberQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      voucherNumber: // value for 'voucherNumber'
 *   },
 * });
 */
export function useNftVoucherByVoucherNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedNftVoucherByVoucherNumberQuery,
    GeneratedNftVoucherByVoucherNumberQueryVariables
  > &
    (
      | {
          variables: GeneratedNftVoucherByVoucherNumberQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedNftVoucherByVoucherNumberQuery,
    GeneratedNftVoucherByVoucherNumberQueryVariables
  >(NftVoucherByVoucherNumberDocument, options);
}
export function useNftVoucherByVoucherNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedNftVoucherByVoucherNumberQuery,
    GeneratedNftVoucherByVoucherNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedNftVoucherByVoucherNumberQuery,
    GeneratedNftVoucherByVoucherNumberQueryVariables
  >(NftVoucherByVoucherNumberDocument, options);
}
export function useNftVoucherByVoucherNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedNftVoucherByVoucherNumberQuery,
        GeneratedNftVoucherByVoucherNumberQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedNftVoucherByVoucherNumberQuery,
    GeneratedNftVoucherByVoucherNumberQueryVariables
  >(NftVoucherByVoucherNumberDocument, options);
}
export type NftVoucherByVoucherNumberQueryHookResult = ReturnType<
  typeof useNftVoucherByVoucherNumberQuery
>;
export type NftVoucherByVoucherNumberLazyQueryHookResult = ReturnType<
  typeof useNftVoucherByVoucherNumberLazyQuery
>;
export type NftVoucherByVoucherNumberSuspenseQueryHookResult = ReturnType<
  typeof useNftVoucherByVoucherNumberSuspenseQuery
>;
export type NftVoucherByVoucherNumberQueryResult = Apollo.QueryResult<
  GeneratedNftVoucherByVoucherNumberQuery,
  GeneratedNftVoucherByVoucherNumberQueryVariables
>;
export const NftGetSignedVoucherDocument = gql`
  query nftGetSignedVoucher($voucherId: String!, $walletAddress: String!) {
    nftGetSignedVoucher(voucherId: $voucherId, walletAddress: $walletAddress) {
      claimed
      voucher {
        projectId
        walletAddress
        voucherId
        price
        validUntil
        tokenId
        signature
      }
    }
  }
`;

/**
 * __useNftGetSignedVoucherQuery__
 *
 * To run a query within a React component, call `useNftGetSignedVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useNftGetSignedVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNftGetSignedVoucherQuery({
 *   variables: {
 *      voucherId: // value for 'voucherId'
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useNftGetSignedVoucherQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedNftGetSignedVoucherQuery,
    GeneratedNftGetSignedVoucherQueryVariables
  > &
    (
      | {
          variables: GeneratedNftGetSignedVoucherQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedNftGetSignedVoucherQuery,
    GeneratedNftGetSignedVoucherQueryVariables
  >(NftGetSignedVoucherDocument, options);
}
export function useNftGetSignedVoucherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedNftGetSignedVoucherQuery,
    GeneratedNftGetSignedVoucherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedNftGetSignedVoucherQuery,
    GeneratedNftGetSignedVoucherQueryVariables
  >(NftGetSignedVoucherDocument, options);
}
export function useNftGetSignedVoucherSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedNftGetSignedVoucherQuery,
        GeneratedNftGetSignedVoucherQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedNftGetSignedVoucherQuery,
    GeneratedNftGetSignedVoucherQueryVariables
  >(NftGetSignedVoucherDocument, options);
}
export type NftGetSignedVoucherQueryHookResult = ReturnType<
  typeof useNftGetSignedVoucherQuery
>;
export type NftGetSignedVoucherLazyQueryHookResult = ReturnType<
  typeof useNftGetSignedVoucherLazyQuery
>;
export type NftGetSignedVoucherSuspenseQueryHookResult = ReturnType<
  typeof useNftGetSignedVoucherSuspenseQuery
>;
export type NftGetSignedVoucherQueryResult = Apollo.QueryResult<
  GeneratedNftGetSignedVoucherQuery,
  GeneratedNftGetSignedVoucherQueryVariables
>;
export const BlockchainGetSignedNftVoucherDocument = gql`
  query blockchainGetSignedNftVoucher(
    $voucherId: String!
    $walletAddress: String!
  ) {
    blockchainGetSignedNftVoucher(
      voucherId: $voucherId
      walletAddress: $walletAddress
    ) {
      signature
      projectId
      walletAddress
      voucherId
      price
      validUntil
      tokenId
    }
  }
`;

/**
 * __useBlockchainGetSignedNftVoucherQuery__
 *
 * To run a query within a React component, call `useBlockchainGetSignedNftVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainGetSignedNftVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainGetSignedNftVoucherQuery({
 *   variables: {
 *      voucherId: // value for 'voucherId'
 *      walletAddress: // value for 'walletAddress'
 *   },
 * });
 */
export function useBlockchainGetSignedNftVoucherQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainGetSignedNftVoucherQuery,
    GeneratedBlockchainGetSignedNftVoucherQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainGetSignedNftVoucherQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainGetSignedNftVoucherQuery,
    GeneratedBlockchainGetSignedNftVoucherQueryVariables
  >(BlockchainGetSignedNftVoucherDocument, options);
}
export function useBlockchainGetSignedNftVoucherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainGetSignedNftVoucherQuery,
    GeneratedBlockchainGetSignedNftVoucherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainGetSignedNftVoucherQuery,
    GeneratedBlockchainGetSignedNftVoucherQueryVariables
  >(BlockchainGetSignedNftVoucherDocument, options);
}
export function useBlockchainGetSignedNftVoucherSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainGetSignedNftVoucherQuery,
        GeneratedBlockchainGetSignedNftVoucherQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainGetSignedNftVoucherQuery,
    GeneratedBlockchainGetSignedNftVoucherQueryVariables
  >(BlockchainGetSignedNftVoucherDocument, options);
}
export type BlockchainGetSignedNftVoucherQueryHookResult = ReturnType<
  typeof useBlockchainGetSignedNftVoucherQuery
>;
export type BlockchainGetSignedNftVoucherLazyQueryHookResult = ReturnType<
  typeof useBlockchainGetSignedNftVoucherLazyQuery
>;
export type BlockchainGetSignedNftVoucherSuspenseQueryHookResult = ReturnType<
  typeof useBlockchainGetSignedNftVoucherSuspenseQuery
>;
export type BlockchainGetSignedNftVoucherQueryResult = Apollo.QueryResult<
  GeneratedBlockchainGetSignedNftVoucherQuery,
  GeneratedBlockchainGetSignedNftVoucherQueryVariables
>;
export const VoucherExistsForTokenIdDocument = gql`
  query voucherExistsForTokenId($projectId: String!, $tokenId: Int!) {
    voucherExistsForTokenId(projectId: $projectId, tokenId: $tokenId)
  }
`;

/**
 * __useVoucherExistsForTokenIdQuery__
 *
 * To run a query within a React component, call `useVoucherExistsForTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherExistsForTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherExistsForTokenIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useVoucherExistsForTokenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedVoucherExistsForTokenIdQuery,
    GeneratedVoucherExistsForTokenIdQueryVariables
  > &
    (
      | {
          variables: GeneratedVoucherExistsForTokenIdQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedVoucherExistsForTokenIdQuery,
    GeneratedVoucherExistsForTokenIdQueryVariables
  >(VoucherExistsForTokenIdDocument, options);
}
export function useVoucherExistsForTokenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedVoucherExistsForTokenIdQuery,
    GeneratedVoucherExistsForTokenIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedVoucherExistsForTokenIdQuery,
    GeneratedVoucherExistsForTokenIdQueryVariables
  >(VoucherExistsForTokenIdDocument, options);
}
export function useVoucherExistsForTokenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedVoucherExistsForTokenIdQuery,
        GeneratedVoucherExistsForTokenIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedVoucherExistsForTokenIdQuery,
    GeneratedVoucherExistsForTokenIdQueryVariables
  >(VoucherExistsForTokenIdDocument, options);
}
export type VoucherExistsForTokenIdQueryHookResult = ReturnType<
  typeof useVoucherExistsForTokenIdQuery
>;
export type VoucherExistsForTokenIdLazyQueryHookResult = ReturnType<
  typeof useVoucherExistsForTokenIdLazyQuery
>;
export type VoucherExistsForTokenIdSuspenseQueryHookResult = ReturnType<
  typeof useVoucherExistsForTokenIdSuspenseQuery
>;
export type VoucherExistsForTokenIdQueryResult = Apollo.QueryResult<
  GeneratedVoucherExistsForTokenIdQuery,
  GeneratedVoucherExistsForTokenIdQueryVariables
>;
export const GetNftPrintsForProductDocument = gql`
  query getNftPrintsForProduct($productId: String!) {
    getNftPrintsForProduct(productId: $productId) {
      prints {
        id
        tokenId
        owner
        authenticated
        orderedAt
      }
    }
  }
`;

/**
 * __useGetNftPrintsForProductQuery__
 *
 * To run a query within a React component, call `useGetNftPrintsForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNftPrintsForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftPrintsForProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetNftPrintsForProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetNftPrintsForProductQuery,
    GeneratedGetNftPrintsForProductQueryVariables
  > &
    (
      | {
          variables: GeneratedGetNftPrintsForProductQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetNftPrintsForProductQuery,
    GeneratedGetNftPrintsForProductQueryVariables
  >(GetNftPrintsForProductDocument, options);
}
export function useGetNftPrintsForProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetNftPrintsForProductQuery,
    GeneratedGetNftPrintsForProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetNftPrintsForProductQuery,
    GeneratedGetNftPrintsForProductQueryVariables
  >(GetNftPrintsForProductDocument, options);
}
export function useGetNftPrintsForProductSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetNftPrintsForProductQuery,
        GeneratedGetNftPrintsForProductQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetNftPrintsForProductQuery,
    GeneratedGetNftPrintsForProductQueryVariables
  >(GetNftPrintsForProductDocument, options);
}
export type GetNftPrintsForProductQueryHookResult = ReturnType<
  typeof useGetNftPrintsForProductQuery
>;
export type GetNftPrintsForProductLazyQueryHookResult = ReturnType<
  typeof useGetNftPrintsForProductLazyQuery
>;
export type GetNftPrintsForProductSuspenseQueryHookResult = ReturnType<
  typeof useGetNftPrintsForProductSuspenseQuery
>;
export type GetNftPrintsForProductQueryResult = Apollo.QueryResult<
  GeneratedGetNftPrintsForProductQuery,
  GeneratedGetNftPrintsForProductQueryVariables
>;
export const BlockchainGetAllNftsOfContractDocument = gql`
  query blockchainGetAllNftsOfContract(
    $input: BlockchainGetAllNftsOfContractInput!
  ) {
    blockchainGetAllNftsOfContract(input: $input) {
      token
      owner
    }
  }
`;

/**
 * __useBlockchainGetAllNftsOfContractQuery__
 *
 * To run a query within a React component, call `useBlockchainGetAllNftsOfContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainGetAllNftsOfContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainGetAllNftsOfContractQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockchainGetAllNftsOfContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainGetAllNftsOfContractQuery,
    GeneratedBlockchainGetAllNftsOfContractQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainGetAllNftsOfContractQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainGetAllNftsOfContractQuery,
    GeneratedBlockchainGetAllNftsOfContractQueryVariables
  >(BlockchainGetAllNftsOfContractDocument, options);
}
export function useBlockchainGetAllNftsOfContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainGetAllNftsOfContractQuery,
    GeneratedBlockchainGetAllNftsOfContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainGetAllNftsOfContractQuery,
    GeneratedBlockchainGetAllNftsOfContractQueryVariables
  >(BlockchainGetAllNftsOfContractDocument, options);
}
export function useBlockchainGetAllNftsOfContractSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainGetAllNftsOfContractQuery,
        GeneratedBlockchainGetAllNftsOfContractQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainGetAllNftsOfContractQuery,
    GeneratedBlockchainGetAllNftsOfContractQueryVariables
  >(BlockchainGetAllNftsOfContractDocument, options);
}
export type BlockchainGetAllNftsOfContractQueryHookResult = ReturnType<
  typeof useBlockchainGetAllNftsOfContractQuery
>;
export type BlockchainGetAllNftsOfContractLazyQueryHookResult = ReturnType<
  typeof useBlockchainGetAllNftsOfContractLazyQuery
>;
export type BlockchainGetAllNftsOfContractSuspenseQueryHookResult = ReturnType<
  typeof useBlockchainGetAllNftsOfContractSuspenseQuery
>;
export type BlockchainGetAllNftsOfContractQueryResult = Apollo.QueryResult<
  GeneratedBlockchainGetAllNftsOfContractQuery,
  GeneratedBlockchainGetAllNftsOfContractQueryVariables
>;
export const GetFullNftPrintDocument = gql`
  query getFullNftPrint($productId: String!, $tokenId: String!) {
    getFullNftPrint(productId: $productId, tokenId: $tokenId) {
      ownerEmailHint
      print {
        id
        tokenId
        owner
        authenticated
        productId
        orderedAt
        ownerId
      }
      events {
        id
        owner
        authenticated
        productId
        createTime
        event
      }
    }
  }
`;

/**
 * __useGetFullNftPrintQuery__
 *
 * To run a query within a React component, call `useGetFullNftPrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullNftPrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullNftPrintQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGetFullNftPrintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetFullNftPrintQuery,
    GeneratedGetFullNftPrintQueryVariables
  > &
    (
      | { variables: GeneratedGetFullNftPrintQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetFullNftPrintQuery,
    GeneratedGetFullNftPrintQueryVariables
  >(GetFullNftPrintDocument, options);
}
export function useGetFullNftPrintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetFullNftPrintQuery,
    GeneratedGetFullNftPrintQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetFullNftPrintQuery,
    GeneratedGetFullNftPrintQueryVariables
  >(GetFullNftPrintDocument, options);
}
export function useGetFullNftPrintSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetFullNftPrintQuery,
        GeneratedGetFullNftPrintQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetFullNftPrintQuery,
    GeneratedGetFullNftPrintQueryVariables
  >(GetFullNftPrintDocument, options);
}
export type GetFullNftPrintQueryHookResult = ReturnType<
  typeof useGetFullNftPrintQuery
>;
export type GetFullNftPrintLazyQueryHookResult = ReturnType<
  typeof useGetFullNftPrintLazyQuery
>;
export type GetFullNftPrintSuspenseQueryHookResult = ReturnType<
  typeof useGetFullNftPrintSuspenseQuery
>;
export type GetFullNftPrintQueryResult = Apollo.QueryResult<
  GeneratedGetFullNftPrintQuery,
  GeneratedGetFullNftPrintQueryVariables
>;
export const BlockchainOwnedNftTokensInContractDocument = gql`
  query blockchainOwnedNftTokensInContract(
    $input: BlockchainOwnedNftTokensInContractInput!
  ) {
    blockchainOwnedNftTokensInContract(input: $input) {
      id
      metadataUri
    }
  }
`;

/**
 * __useBlockchainOwnedNftTokensInContractQuery__
 *
 * To run a query within a React component, call `useBlockchainOwnedNftTokensInContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainOwnedNftTokensInContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainOwnedNftTokensInContractQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockchainOwnedNftTokensInContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainOwnedNftTokensInContractQuery,
    GeneratedBlockchainOwnedNftTokensInContractQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainOwnedNftTokensInContractQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainOwnedNftTokensInContractQuery,
    GeneratedBlockchainOwnedNftTokensInContractQueryVariables
  >(BlockchainOwnedNftTokensInContractDocument, options);
}
export function useBlockchainOwnedNftTokensInContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainOwnedNftTokensInContractQuery,
    GeneratedBlockchainOwnedNftTokensInContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainOwnedNftTokensInContractQuery,
    GeneratedBlockchainOwnedNftTokensInContractQueryVariables
  >(BlockchainOwnedNftTokensInContractDocument, options);
}
export function useBlockchainOwnedNftTokensInContractSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainOwnedNftTokensInContractQuery,
        GeneratedBlockchainOwnedNftTokensInContractQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainOwnedNftTokensInContractQuery,
    GeneratedBlockchainOwnedNftTokensInContractQueryVariables
  >(BlockchainOwnedNftTokensInContractDocument, options);
}
export type BlockchainOwnedNftTokensInContractQueryHookResult = ReturnType<
  typeof useBlockchainOwnedNftTokensInContractQuery
>;
export type BlockchainOwnedNftTokensInContractLazyQueryHookResult = ReturnType<
  typeof useBlockchainOwnedNftTokensInContractLazyQuery
>;
export type BlockchainOwnedNftTokensInContractSuspenseQueryHookResult =
  ReturnType<typeof useBlockchainOwnedNftTokensInContractSuspenseQuery>;
export type BlockchainOwnedNftTokensInContractQueryResult = Apollo.QueryResult<
  GeneratedBlockchainOwnedNftTokensInContractQuery,
  GeneratedBlockchainOwnedNftTokensInContractQueryVariables
>;
export const BlockchainIsNftTokenOwnedByWalletDocument = gql`
  query blockchainIsNftTokenOwnedByWallet(
    $input: BlockchainIsNftTokenOwnedByWalletInput!
  ) {
    blockchainIsNftTokenOwnedByWallet(input: $input)
  }
`;

/**
 * __useBlockchainIsNftTokenOwnedByWalletQuery__
 *
 * To run a query within a React component, call `useBlockchainIsNftTokenOwnedByWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainIsNftTokenOwnedByWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainIsNftTokenOwnedByWalletQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockchainIsNftTokenOwnedByWalletQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
    GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
    GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
  >(BlockchainIsNftTokenOwnedByWalletDocument, options);
}
export function useBlockchainIsNftTokenOwnedByWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
    GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
    GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
  >(BlockchainIsNftTokenOwnedByWalletDocument, options);
}
export function useBlockchainIsNftTokenOwnedByWalletSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
        GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
    GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
  >(BlockchainIsNftTokenOwnedByWalletDocument, options);
}
export type BlockchainIsNftTokenOwnedByWalletQueryHookResult = ReturnType<
  typeof useBlockchainIsNftTokenOwnedByWalletQuery
>;
export type BlockchainIsNftTokenOwnedByWalletLazyQueryHookResult = ReturnType<
  typeof useBlockchainIsNftTokenOwnedByWalletLazyQuery
>;
export type BlockchainIsNftTokenOwnedByWalletSuspenseQueryHookResult =
  ReturnType<typeof useBlockchainIsNftTokenOwnedByWalletSuspenseQuery>;
export type BlockchainIsNftTokenOwnedByWalletQueryResult = Apollo.QueryResult<
  GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
  GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
>;
export const BlockchainValidateGatingDocument = gql`
  query blockchainValidateGating($input: BlockchainValidateGatingInput!) {
    blockchainValidateGating(input: $input)
  }
`;

/**
 * __useBlockchainValidateGatingQuery__
 *
 * To run a query within a React component, call `useBlockchainValidateGatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainValidateGatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainValidateGatingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockchainValidateGatingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainValidateGatingQuery,
    GeneratedBlockchainValidateGatingQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainValidateGatingQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainValidateGatingQuery,
    GeneratedBlockchainValidateGatingQueryVariables
  >(BlockchainValidateGatingDocument, options);
}
export function useBlockchainValidateGatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainValidateGatingQuery,
    GeneratedBlockchainValidateGatingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainValidateGatingQuery,
    GeneratedBlockchainValidateGatingQueryVariables
  >(BlockchainValidateGatingDocument, options);
}
export function useBlockchainValidateGatingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainValidateGatingQuery,
        GeneratedBlockchainValidateGatingQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainValidateGatingQuery,
    GeneratedBlockchainValidateGatingQueryVariables
  >(BlockchainValidateGatingDocument, options);
}
export type BlockchainValidateGatingQueryHookResult = ReturnType<
  typeof useBlockchainValidateGatingQuery
>;
export type BlockchainValidateGatingLazyQueryHookResult = ReturnType<
  typeof useBlockchainValidateGatingLazyQuery
>;
export type BlockchainValidateGatingSuspenseQueryHookResult = ReturnType<
  typeof useBlockchainValidateGatingSuspenseQuery
>;
export type BlockchainValidateGatingQueryResult = Apollo.QueryResult<
  GeneratedBlockchainValidateGatingQuery,
  GeneratedBlockchainValidateGatingQueryVariables
>;
export const BlockchainGetInfoForTokensDocument = gql`
  query blockchainGetInfoForTokens($input: BlockchainGetInfoForTokensInput!) {
    blockchainGetInfoForTokens(input: $input) {
      id
      metadataUri
      owner
    }
  }
`;

/**
 * __useBlockchainGetInfoForTokensQuery__
 *
 * To run a query within a React component, call `useBlockchainGetInfoForTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainGetInfoForTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainGetInfoForTokensQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockchainGetInfoForTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainGetInfoForTokensQuery,
    GeneratedBlockchainGetInfoForTokensQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainGetInfoForTokensQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainGetInfoForTokensQuery,
    GeneratedBlockchainGetInfoForTokensQueryVariables
  >(BlockchainGetInfoForTokensDocument, options);
}
export function useBlockchainGetInfoForTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainGetInfoForTokensQuery,
    GeneratedBlockchainGetInfoForTokensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainGetInfoForTokensQuery,
    GeneratedBlockchainGetInfoForTokensQueryVariables
  >(BlockchainGetInfoForTokensDocument, options);
}
export function useBlockchainGetInfoForTokensSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainGetInfoForTokensQuery,
        GeneratedBlockchainGetInfoForTokensQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainGetInfoForTokensQuery,
    GeneratedBlockchainGetInfoForTokensQueryVariables
  >(BlockchainGetInfoForTokensDocument, options);
}
export type BlockchainGetInfoForTokensQueryHookResult = ReturnType<
  typeof useBlockchainGetInfoForTokensQuery
>;
export type BlockchainGetInfoForTokensLazyQueryHookResult = ReturnType<
  typeof useBlockchainGetInfoForTokensLazyQuery
>;
export type BlockchainGetInfoForTokensSuspenseQueryHookResult = ReturnType<
  typeof useBlockchainGetInfoForTokensSuspenseQuery
>;
export type BlockchainGetInfoForTokensQueryResult = Apollo.QueryResult<
  GeneratedBlockchainGetInfoForTokensQuery,
  GeneratedBlockchainGetInfoForTokensQueryVariables
>;
export const CalculateOrderPricingDocument = gql`
  query calculateOrderPricing(
    $countryCode: String!
    $currencyCode: String!
    $items: [CalculateOrderPricingInputItem]!
  ) {
    calculateOrderPricing(
      countryCode: $countryCode
      currencyCode: $currencyCode
      items: $items
    ) {
      totalPrice
      totalDiscount
      subtotalTaxPrice
      subtotalShippingPrice
      subtotalItemsPrice
      currencyCode
      taxRate
      taxType
      breakdown {
        productId
        variantId
        title
        price
        tags
        carrierServiceName
        shippingProviderId
        quantity
        isTaxable
        freeShipping
        requiresShipping
      }
    }
  }
`;

/**
 * __useCalculateOrderPricingQuery__
 *
 * To run a query within a React component, call `useCalculateOrderPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateOrderPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateOrderPricingQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      currencyCode: // value for 'currencyCode'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCalculateOrderPricingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedCalculateOrderPricingQuery,
    GeneratedCalculateOrderPricingQueryVariables
  > &
    (
      | {
          variables: GeneratedCalculateOrderPricingQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedCalculateOrderPricingQuery,
    GeneratedCalculateOrderPricingQueryVariables
  >(CalculateOrderPricingDocument, options);
}
export function useCalculateOrderPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedCalculateOrderPricingQuery,
    GeneratedCalculateOrderPricingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedCalculateOrderPricingQuery,
    GeneratedCalculateOrderPricingQueryVariables
  >(CalculateOrderPricingDocument, options);
}
export function useCalculateOrderPricingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedCalculateOrderPricingQuery,
        GeneratedCalculateOrderPricingQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedCalculateOrderPricingQuery,
    GeneratedCalculateOrderPricingQueryVariables
  >(CalculateOrderPricingDocument, options);
}
export type CalculateOrderPricingQueryHookResult = ReturnType<
  typeof useCalculateOrderPricingQuery
>;
export type CalculateOrderPricingLazyQueryHookResult = ReturnType<
  typeof useCalculateOrderPricingLazyQuery
>;
export type CalculateOrderPricingSuspenseQueryHookResult = ReturnType<
  typeof useCalculateOrderPricingSuspenseQuery
>;
export type CalculateOrderPricingQueryResult = Apollo.QueryResult<
  GeneratedCalculateOrderPricingQuery,
  GeneratedCalculateOrderPricingQueryVariables
>;
export const GetDrawByProductIdDocument = gql`
  query GetDrawByProductId($productId: String!) {
    getDrawByProductId(productId: $productId) {
      id
      productType
      drawType
      productId
      productVariantId
      preOrderCloseAt
      allocation
      accountIsInDraw
      closeAt
      openAt
      drawnAt
      status
      totalAccounts
      allocation
      cryptoComEnabled
    }
  }
`;

/**
 * __useGetDrawByProductIdQuery__
 *
 * To run a query within a React component, call `useGetDrawByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawByProductIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetDrawByProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetDrawByProductIdQuery,
    GeneratedGetDrawByProductIdQueryVariables
  > &
    (
      | { variables: GeneratedGetDrawByProductIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetDrawByProductIdQuery,
    GeneratedGetDrawByProductIdQueryVariables
  >(GetDrawByProductIdDocument, options);
}
export function useGetDrawByProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetDrawByProductIdQuery,
    GeneratedGetDrawByProductIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetDrawByProductIdQuery,
    GeneratedGetDrawByProductIdQueryVariables
  >(GetDrawByProductIdDocument, options);
}
export function useGetDrawByProductIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetDrawByProductIdQuery,
        GeneratedGetDrawByProductIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetDrawByProductIdQuery,
    GeneratedGetDrawByProductIdQueryVariables
  >(GetDrawByProductIdDocument, options);
}
export type GetDrawByProductIdQueryHookResult = ReturnType<
  typeof useGetDrawByProductIdQuery
>;
export type GetDrawByProductIdLazyQueryHookResult = ReturnType<
  typeof useGetDrawByProductIdLazyQuery
>;
export type GetDrawByProductIdSuspenseQueryHookResult = ReturnType<
  typeof useGetDrawByProductIdSuspenseQuery
>;
export type GetDrawByProductIdQueryResult = Apollo.QueryResult<
  GeneratedGetDrawByProductIdQuery,
  GeneratedGetDrawByProductIdQueryVariables
>;
export const GetDrawEntryIntentDocument = gql`
  query GetDrawEntryIntent($drawEntryIntentId: ID!) {
    getDrawEntryIntent(id: $drawEntryIntentId) {
      id
      errorCode
      confirmedAt
      discardedAt
    }
  }
`;

/**
 * __useGetDrawEntryIntentQuery__
 *
 * To run a query within a React component, call `useGetDrawEntryIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawEntryIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawEntryIntentQuery({
 *   variables: {
 *      drawEntryIntentId: // value for 'drawEntryIntentId'
 *   },
 * });
 */
export function useGetDrawEntryIntentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetDrawEntryIntentQuery,
    GeneratedGetDrawEntryIntentQueryVariables
  > &
    (
      | { variables: GeneratedGetDrawEntryIntentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetDrawEntryIntentQuery,
    GeneratedGetDrawEntryIntentQueryVariables
  >(GetDrawEntryIntentDocument, options);
}
export function useGetDrawEntryIntentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetDrawEntryIntentQuery,
    GeneratedGetDrawEntryIntentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetDrawEntryIntentQuery,
    GeneratedGetDrawEntryIntentQueryVariables
  >(GetDrawEntryIntentDocument, options);
}
export function useGetDrawEntryIntentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetDrawEntryIntentQuery,
        GeneratedGetDrawEntryIntentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetDrawEntryIntentQuery,
    GeneratedGetDrawEntryIntentQueryVariables
  >(GetDrawEntryIntentDocument, options);
}
export type GetDrawEntryIntentQueryHookResult = ReturnType<
  typeof useGetDrawEntryIntentQuery
>;
export type GetDrawEntryIntentLazyQueryHookResult = ReturnType<
  typeof useGetDrawEntryIntentLazyQuery
>;
export type GetDrawEntryIntentSuspenseQueryHookResult = ReturnType<
  typeof useGetDrawEntryIntentSuspenseQuery
>;
export type GetDrawEntryIntentQueryResult = Apollo.QueryResult<
  GeneratedGetDrawEntryIntentQuery,
  GeneratedGetDrawEntryIntentQueryVariables
>;
export const ListPaymentMethodsDocument = gql`
  query ListPaymentMethods {
    listPaymentMethods {
      providerId
      providerPaymentMethodId
      type
      default
      billingAddress {
        line1
        line2
        city
        state
        postalCode
        countryCode
      }
      card {
        brand
        expireMonth
        expireYear
        last4
        wallet {
          type
        }
      }
    }
  }
`;

/**
 * __useListPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useListPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedListPaymentMethodsQuery,
    GeneratedListPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedListPaymentMethodsQuery,
    GeneratedListPaymentMethodsQueryVariables
  >(ListPaymentMethodsDocument, options);
}
export function useListPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedListPaymentMethodsQuery,
    GeneratedListPaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedListPaymentMethodsQuery,
    GeneratedListPaymentMethodsQueryVariables
  >(ListPaymentMethodsDocument, options);
}
export function useListPaymentMethodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedListPaymentMethodsQuery,
        GeneratedListPaymentMethodsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedListPaymentMethodsQuery,
    GeneratedListPaymentMethodsQueryVariables
  >(ListPaymentMethodsDocument, options);
}
export type ListPaymentMethodsQueryHookResult = ReturnType<
  typeof useListPaymentMethodsQuery
>;
export type ListPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useListPaymentMethodsLazyQuery
>;
export type ListPaymentMethodsSuspenseQueryHookResult = ReturnType<
  typeof useListPaymentMethodsSuspenseQuery
>;
export type ListPaymentMethodsQueryResult = Apollo.QueryResult<
  GeneratedListPaymentMethodsQuery,
  GeneratedListPaymentMethodsQueryVariables
>;
export const GetArtistsByIdsDocument = gql`
  query GetArtistsByIds($ids: [ID!]!) {
    artists(ids: $ids) {
      id
      name
      thumbnail
    }
  }
`;

/**
 * __useGetArtistsByIdsQuery__
 *
 * To run a query within a React component, call `useGetArtistsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetArtistsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetArtistsByIdsQuery,
    GeneratedGetArtistsByIdsQueryVariables
  > &
    (
      | { variables: GeneratedGetArtistsByIdsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetArtistsByIdsQuery,
    GeneratedGetArtistsByIdsQueryVariables
  >(GetArtistsByIdsDocument, options);
}
export function useGetArtistsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetArtistsByIdsQuery,
    GeneratedGetArtistsByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetArtistsByIdsQuery,
    GeneratedGetArtistsByIdsQueryVariables
  >(GetArtistsByIdsDocument, options);
}
export function useGetArtistsByIdsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetArtistsByIdsQuery,
        GeneratedGetArtistsByIdsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetArtistsByIdsQuery,
    GeneratedGetArtistsByIdsQueryVariables
  >(GetArtistsByIdsDocument, options);
}
export type GetArtistsByIdsQueryHookResult = ReturnType<
  typeof useGetArtistsByIdsQuery
>;
export type GetArtistsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetArtistsByIdsLazyQuery
>;
export type GetArtistsByIdsSuspenseQueryHookResult = ReturnType<
  typeof useGetArtistsByIdsSuspenseQuery
>;
export type GetArtistsByIdsQueryResult = Apollo.QueryResult<
  GeneratedGetArtistsByIdsQuery,
  GeneratedGetArtistsByIdsQueryVariables
>;
export const ArteBioArtistsDocument = gql`
  query ArteBioArtists {
    arteBioArtists {
      slug
    }
  }
`;

/**
 * __useArteBioArtistsQuery__
 *
 * To run a query within a React component, call `useArteBioArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArteBioArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArteBioArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useArteBioArtistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedArteBioArtistsQuery,
    GeneratedArteBioArtistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedArteBioArtistsQuery,
    GeneratedArteBioArtistsQueryVariables
  >(ArteBioArtistsDocument, options);
}
export function useArteBioArtistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedArteBioArtistsQuery,
    GeneratedArteBioArtistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedArteBioArtistsQuery,
    GeneratedArteBioArtistsQueryVariables
  >(ArteBioArtistsDocument, options);
}
export function useArteBioArtistsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedArteBioArtistsQuery,
        GeneratedArteBioArtistsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedArteBioArtistsQuery,
    GeneratedArteBioArtistsQueryVariables
  >(ArteBioArtistsDocument, options);
}
export type ArteBioArtistsQueryHookResult = ReturnType<
  typeof useArteBioArtistsQuery
>;
export type ArteBioArtistsLazyQueryHookResult = ReturnType<
  typeof useArteBioArtistsLazyQuery
>;
export type ArteBioArtistsSuspenseQueryHookResult = ReturnType<
  typeof useArteBioArtistsSuspenseQuery
>;
export type ArteBioArtistsQueryResult = Apollo.QueryResult<
  GeneratedArteBioArtistsQuery,
  GeneratedArteBioArtistsQueryVariables
>;
export const GetArtistTabsBlocksDocument = gql`
  query getArtistTabsBlocks($artistID: ID!) {
    artistTabsBlocks(artistID: $artistID, withBlocks: true) {
      tabs {
        ...ArtistTabFull
      }
      blocks {
        ...ArtistBlockFull
      }
    }
  }
  ${ArtistTabFullFragmentDoc}
  ${ArtistBlockFullFragmentDoc}
`;

/**
 * __useGetArtistTabsBlocksQuery__
 *
 * To run a query within a React component, call `useGetArtistTabsBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistTabsBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistTabsBlocksQuery({
 *   variables: {
 *      artistID: // value for 'artistID'
 *   },
 * });
 */
export function useGetArtistTabsBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetArtistTabsBlocksQuery,
    GeneratedGetArtistTabsBlocksQueryVariables
  > &
    (
      | {
          variables: GeneratedGetArtistTabsBlocksQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetArtistTabsBlocksQuery,
    GeneratedGetArtistTabsBlocksQueryVariables
  >(GetArtistTabsBlocksDocument, options);
}
export function useGetArtistTabsBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetArtistTabsBlocksQuery,
    GeneratedGetArtistTabsBlocksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetArtistTabsBlocksQuery,
    GeneratedGetArtistTabsBlocksQueryVariables
  >(GetArtistTabsBlocksDocument, options);
}
export function useGetArtistTabsBlocksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetArtistTabsBlocksQuery,
        GeneratedGetArtistTabsBlocksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetArtistTabsBlocksQuery,
    GeneratedGetArtistTabsBlocksQueryVariables
  >(GetArtistTabsBlocksDocument, options);
}
export type GetArtistTabsBlocksQueryHookResult = ReturnType<
  typeof useGetArtistTabsBlocksQuery
>;
export type GetArtistTabsBlocksLazyQueryHookResult = ReturnType<
  typeof useGetArtistTabsBlocksLazyQuery
>;
export type GetArtistTabsBlocksSuspenseQueryHookResult = ReturnType<
  typeof useGetArtistTabsBlocksSuspenseQuery
>;
export type GetArtistTabsBlocksQueryResult = Apollo.QueryResult<
  GeneratedGetArtistTabsBlocksQuery,
  GeneratedGetArtistTabsBlocksQueryVariables
>;
export const GetArtistTabsDocument = gql`
  query getArtistTabs($artistID: ID!) {
    artistTabsBlocks(artistID: $artistID, withBlocks: false) {
      tabs {
        ...ArtistTabFull
      }
    }
  }
  ${ArtistTabFullFragmentDoc}
`;

/**
 * __useGetArtistTabsQuery__
 *
 * To run a query within a React component, call `useGetArtistTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistTabsQuery({
 *   variables: {
 *      artistID: // value for 'artistID'
 *   },
 * });
 */
export function useGetArtistTabsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetArtistTabsQuery,
    GeneratedGetArtistTabsQueryVariables
  > &
    (
      | { variables: GeneratedGetArtistTabsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetArtistTabsQuery,
    GeneratedGetArtistTabsQueryVariables
  >(GetArtistTabsDocument, options);
}
export function useGetArtistTabsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetArtistTabsQuery,
    GeneratedGetArtistTabsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetArtistTabsQuery,
    GeneratedGetArtistTabsQueryVariables
  >(GetArtistTabsDocument, options);
}
export function useGetArtistTabsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetArtistTabsQuery,
        GeneratedGetArtistTabsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetArtistTabsQuery,
    GeneratedGetArtistTabsQueryVariables
  >(GetArtistTabsDocument, options);
}
export type GetArtistTabsQueryHookResult = ReturnType<
  typeof useGetArtistTabsQuery
>;
export type GetArtistTabsLazyQueryHookResult = ReturnType<
  typeof useGetArtistTabsLazyQuery
>;
export type GetArtistTabsSuspenseQueryHookResult = ReturnType<
  typeof useGetArtistTabsSuspenseQuery
>;
export type GetArtistTabsQueryResult = Apollo.QueryResult<
  GeneratedGetArtistTabsQuery,
  GeneratedGetArtistTabsQueryVariables
>;
export const GetArtistBlocksDocument = gql`
  query getArtistBlocks($artistID: ID!) {
    artistBlocks(artistID: $artistID) {
      ...ArtistBlockFull
    }
  }
  ${ArtistBlockFullFragmentDoc}
`;

/**
 * __useGetArtistBlocksQuery__
 *
 * To run a query within a React component, call `useGetArtistBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistBlocksQuery({
 *   variables: {
 *      artistID: // value for 'artistID'
 *   },
 * });
 */
export function useGetArtistBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetArtistBlocksQuery,
    GeneratedGetArtistBlocksQueryVariables
  > &
    (
      | { variables: GeneratedGetArtistBlocksQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetArtistBlocksQuery,
    GeneratedGetArtistBlocksQueryVariables
  >(GetArtistBlocksDocument, options);
}
export function useGetArtistBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetArtistBlocksQuery,
    GeneratedGetArtistBlocksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetArtistBlocksQuery,
    GeneratedGetArtistBlocksQueryVariables
  >(GetArtistBlocksDocument, options);
}
export function useGetArtistBlocksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetArtistBlocksQuery,
        GeneratedGetArtistBlocksQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetArtistBlocksQuery,
    GeneratedGetArtistBlocksQueryVariables
  >(GetArtistBlocksDocument, options);
}
export type GetArtistBlocksQueryHookResult = ReturnType<
  typeof useGetArtistBlocksQuery
>;
export type GetArtistBlocksLazyQueryHookResult = ReturnType<
  typeof useGetArtistBlocksLazyQuery
>;
export type GetArtistBlocksSuspenseQueryHookResult = ReturnType<
  typeof useGetArtistBlocksSuspenseQuery
>;
export type GetArtistBlocksQueryResult = Apollo.QueryResult<
  GeneratedGetArtistBlocksQuery,
  GeneratedGetArtistBlocksQueryVariables
>;
export const GetArtistsPermissionDocument = gql`
  query getArtistsPermission {
    artistsPermission {
      artistID
      canRead
      canWrite
    }
  }
`;

/**
 * __useGetArtistsPermissionQuery__
 *
 * To run a query within a React component, call `useGetArtistsPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtistsPermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedGetArtistsPermissionQuery,
    GeneratedGetArtistsPermissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetArtistsPermissionQuery,
    GeneratedGetArtistsPermissionQueryVariables
  >(GetArtistsPermissionDocument, options);
}
export function useGetArtistsPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetArtistsPermissionQuery,
    GeneratedGetArtistsPermissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetArtistsPermissionQuery,
    GeneratedGetArtistsPermissionQueryVariables
  >(GetArtistsPermissionDocument, options);
}
export function useGetArtistsPermissionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetArtistsPermissionQuery,
        GeneratedGetArtistsPermissionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetArtistsPermissionQuery,
    GeneratedGetArtistsPermissionQueryVariables
  >(GetArtistsPermissionDocument, options);
}
export type GetArtistsPermissionQueryHookResult = ReturnType<
  typeof useGetArtistsPermissionQuery
>;
export type GetArtistsPermissionLazyQueryHookResult = ReturnType<
  typeof useGetArtistsPermissionLazyQuery
>;
export type GetArtistsPermissionSuspenseQueryHookResult = ReturnType<
  typeof useGetArtistsPermissionSuspenseQuery
>;
export type GetArtistsPermissionQueryResult = Apollo.QueryResult<
  GeneratedGetArtistsPermissionQuery,
  GeneratedGetArtistsPermissionQueryVariables
>;
export const ArteBioSubscriptionsExportDocument = gql`
  query arteBioSubscriptionsExport($artistID: ID!) {
    arteBioSubscriptionsExport(artistID: $artistID)
  }
`;

/**
 * __useArteBioSubscriptionsExportQuery__
 *
 * To run a query within a React component, call `useArteBioSubscriptionsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useArteBioSubscriptionsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArteBioSubscriptionsExportQuery({
 *   variables: {
 *      artistID: // value for 'artistID'
 *   },
 * });
 */
export function useArteBioSubscriptionsExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedArteBioSubscriptionsExportQuery,
    GeneratedArteBioSubscriptionsExportQueryVariables
  > &
    (
      | {
          variables: GeneratedArteBioSubscriptionsExportQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedArteBioSubscriptionsExportQuery,
    GeneratedArteBioSubscriptionsExportQueryVariables
  >(ArteBioSubscriptionsExportDocument, options);
}
export function useArteBioSubscriptionsExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedArteBioSubscriptionsExportQuery,
    GeneratedArteBioSubscriptionsExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedArteBioSubscriptionsExportQuery,
    GeneratedArteBioSubscriptionsExportQueryVariables
  >(ArteBioSubscriptionsExportDocument, options);
}
export function useArteBioSubscriptionsExportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedArteBioSubscriptionsExportQuery,
        GeneratedArteBioSubscriptionsExportQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedArteBioSubscriptionsExportQuery,
    GeneratedArteBioSubscriptionsExportQueryVariables
  >(ArteBioSubscriptionsExportDocument, options);
}
export type ArteBioSubscriptionsExportQueryHookResult = ReturnType<
  typeof useArteBioSubscriptionsExportQuery
>;
export type ArteBioSubscriptionsExportLazyQueryHookResult = ReturnType<
  typeof useArteBioSubscriptionsExportLazyQuery
>;
export type ArteBioSubscriptionsExportSuspenseQueryHookResult = ReturnType<
  typeof useArteBioSubscriptionsExportSuspenseQuery
>;
export type ArteBioSubscriptionsExportQueryResult = Apollo.QueryResult<
  GeneratedArteBioSubscriptionsExportQuery,
  GeneratedArteBioSubscriptionsExportQueryVariables
>;
export const DeleteArtistTabDocument = gql`
  mutation deleteArtistTab($id: ID!) {
    deleteArtistTab(id: $id)
  }
`;
export type GeneratedDeleteArtistTabMutationFn = Apollo.MutationFunction<
  GeneratedDeleteArtistTabMutation,
  GeneratedDeleteArtistTabMutationVariables
>;

/**
 * __useDeleteArtistTabMutation__
 *
 * To run a mutation, you first call `useDeleteArtistTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtistTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtistTabMutation, { data, loading, error }] = useDeleteArtistTabMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArtistTabMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedDeleteArtistTabMutation,
    GeneratedDeleteArtistTabMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedDeleteArtistTabMutation,
    GeneratedDeleteArtistTabMutationVariables
  >(DeleteArtistTabDocument, options);
}
export type DeleteArtistTabMutationHookResult = ReturnType<
  typeof useDeleteArtistTabMutation
>;
export type DeleteArtistTabMutationResult =
  Apollo.MutationResult<GeneratedDeleteArtistTabMutation>;
export type DeleteArtistTabMutationOptions = Apollo.BaseMutationOptions<
  GeneratedDeleteArtistTabMutation,
  GeneratedDeleteArtistTabMutationVariables
>;
export const DeleteArtistBlockDocument = gql`
  mutation deleteArtistBlock($id: ID!) {
    deleteArtistBlock(id: $id)
  }
`;
export type GeneratedDeleteArtistBlockMutationFn = Apollo.MutationFunction<
  GeneratedDeleteArtistBlockMutation,
  GeneratedDeleteArtistBlockMutationVariables
>;

/**
 * __useDeleteArtistBlockMutation__
 *
 * To run a mutation, you first call `useDeleteArtistBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtistBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtistBlockMutation, { data, loading, error }] = useDeleteArtistBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArtistBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedDeleteArtistBlockMutation,
    GeneratedDeleteArtistBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedDeleteArtistBlockMutation,
    GeneratedDeleteArtistBlockMutationVariables
  >(DeleteArtistBlockDocument, options);
}
export type DeleteArtistBlockMutationHookResult = ReturnType<
  typeof useDeleteArtistBlockMutation
>;
export type DeleteArtistBlockMutationResult =
  Apollo.MutationResult<GeneratedDeleteArtistBlockMutation>;
export type DeleteArtistBlockMutationOptions = Apollo.BaseMutationOptions<
  GeneratedDeleteArtistBlockMutation,
  GeneratedDeleteArtistBlockMutationVariables
>;
export const UpsertArtistBlockDocument = gql`
  mutation upsertArtistBlock($input: UpsertArtistBlockInput!) {
    upsertArtistBlock(input: $input) {
      ...ArtistBlockFull
    }
  }
  ${ArtistBlockFullFragmentDoc}
`;
export type GeneratedUpsertArtistBlockMutationFn = Apollo.MutationFunction<
  GeneratedUpsertArtistBlockMutation,
  GeneratedUpsertArtistBlockMutationVariables
>;

/**
 * __useUpsertArtistBlockMutation__
 *
 * To run a mutation, you first call `useUpsertArtistBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArtistBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArtistBlockMutation, { data, loading, error }] = useUpsertArtistBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArtistBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpsertArtistBlockMutation,
    GeneratedUpsertArtistBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpsertArtistBlockMutation,
    GeneratedUpsertArtistBlockMutationVariables
  >(UpsertArtistBlockDocument, options);
}
export type UpsertArtistBlockMutationHookResult = ReturnType<
  typeof useUpsertArtistBlockMutation
>;
export type UpsertArtistBlockMutationResult =
  Apollo.MutationResult<GeneratedUpsertArtistBlockMutation>;
export type UpsertArtistBlockMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpsertArtistBlockMutation,
  GeneratedUpsertArtistBlockMutationVariables
>;
export const UpsertArtistTabDocument = gql`
  mutation upsertArtistTab($input: UpsertArtistTabInput!) {
    upsertArtistTab(input: $input) {
      ...ArtistTabFull
    }
  }
  ${ArtistTabFullFragmentDoc}
`;
export type GeneratedUpsertArtistTabMutationFn = Apollo.MutationFunction<
  GeneratedUpsertArtistTabMutation,
  GeneratedUpsertArtistTabMutationVariables
>;

/**
 * __useUpsertArtistTabMutation__
 *
 * To run a mutation, you first call `useUpsertArtistTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArtistTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArtistTabMutation, { data, loading, error }] = useUpsertArtistTabMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertArtistTabMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpsertArtistTabMutation,
    GeneratedUpsertArtistTabMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpsertArtistTabMutation,
    GeneratedUpsertArtistTabMutationVariables
  >(UpsertArtistTabDocument, options);
}
export type UpsertArtistTabMutationHookResult = ReturnType<
  typeof useUpsertArtistTabMutation
>;
export type UpsertArtistTabMutationResult =
  Apollo.MutationResult<GeneratedUpsertArtistTabMutation>;
export type UpsertArtistTabMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpsertArtistTabMutation,
  GeneratedUpsertArtistTabMutationVariables
>;
export const CreateCheckoutDocument = gql`
  mutation CreateCheckout(
    $paymentProvider: PaymentProvider!
    $idempotencyKey: String!
    $items: [OrderItem]!
    $currencyCode: String!
    $email: String
    $tags: [String!]
  ) {
    createCheckout(
      paymentProvider: $paymentProvider
      idempotencyKey: $idempotencyKey
      items: $items
      currencyCode: $currencyCode
      email: $email
      tags: $tags
    ) {
      paymentProvider
      providerPaymentIntentId
      providerPaymentClientSecret
      pricing {
        totalPrice
        subtotalTaxPrice
        subtotalShippingPrice
        subtotalItemsPrice
        currencyCode
        taxRate
        taxType
        breakdown {
          productId
          variantId
          title
          price
          quantity
          isTaxable
          freeShipping
          requiresShipping
          tags
          carrierServiceName
          shippingProviderId
        }
        includesCalculatedShipping
        includesCalculatedTaxes
      }
    }
  }
`;
export type GeneratedCreateCheckoutMutationFn = Apollo.MutationFunction<
  GeneratedCreateCheckoutMutation,
  GeneratedCreateCheckoutMutationVariables
>;

/**
 * __useCreateCheckoutMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutMutation, { data, loading, error }] = useCreateCheckoutMutation({
 *   variables: {
 *      paymentProvider: // value for 'paymentProvider'
 *      idempotencyKey: // value for 'idempotencyKey'
 *      items: // value for 'items'
 *      currencyCode: // value for 'currencyCode'
 *      email: // value for 'email'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedCreateCheckoutMutation,
    GeneratedCreateCheckoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedCreateCheckoutMutation,
    GeneratedCreateCheckoutMutationVariables
  >(CreateCheckoutDocument, options);
}
export type CreateCheckoutMutationHookResult = ReturnType<
  typeof useCreateCheckoutMutation
>;
export type CreateCheckoutMutationResult =
  Apollo.MutationResult<GeneratedCreateCheckoutMutation>;
export type CreateCheckoutMutationOptions = Apollo.BaseMutationOptions<
  GeneratedCreateCheckoutMutation,
  GeneratedCreateCheckoutMutationVariables
>;
export const UpdateCheckoutDocument = gql`
  mutation UpdateCheckout(
    $paymentProvider: PaymentProvider!
    $providerPaymentIntentId: String!
    $items: [OrderItem]
    $currencyCode: String
    $email: String
    $shippingAddress: OrderAddressInput
    $tags: OrderTagsInput
  ) {
    updateCheckout(
      paymentProvider: $paymentProvider
      providerPaymentIntentId: $providerPaymentIntentId
      items: $items
      currencyCode: $currencyCode
      email: $email
      shippingAddress: $shippingAddress
      tags: $tags
    ) {
      paymentProvider
      providerPaymentIntentId
      providerPaymentClientSecret
      pricing {
        totalPrice
        subtotalTaxPrice
        subtotalShippingPrice
        subtotalItemsPrice
        currencyCode
        taxRate
        taxType
        breakdown {
          productId
          variantId
          title
          price
          quantity
          isTaxable
          freeShipping
          requiresShipping
          tags
          carrierServiceName
          shippingProviderId
        }
        includesCalculatedShipping
        includesCalculatedTaxes
      }
    }
  }
`;
export type GeneratedUpdateCheckoutMutationFn = Apollo.MutationFunction<
  GeneratedUpdateCheckoutMutation,
  GeneratedUpdateCheckoutMutationVariables
>;

/**
 * __useUpdateCheckoutMutation__
 *
 * To run a mutation, you first call `useUpdateCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckoutMutation, { data, loading, error }] = useUpdateCheckoutMutation({
 *   variables: {
 *      paymentProvider: // value for 'paymentProvider'
 *      providerPaymentIntentId: // value for 'providerPaymentIntentId'
 *      items: // value for 'items'
 *      currencyCode: // value for 'currencyCode'
 *      email: // value for 'email'
 *      shippingAddress: // value for 'shippingAddress'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpdateCheckoutMutation,
    GeneratedUpdateCheckoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpdateCheckoutMutation,
    GeneratedUpdateCheckoutMutationVariables
  >(UpdateCheckoutDocument, options);
}
export type UpdateCheckoutMutationHookResult = ReturnType<
  typeof useUpdateCheckoutMutation
>;
export type UpdateCheckoutMutationResult =
  Apollo.MutationResult<GeneratedUpdateCheckoutMutation>;
export type UpdateCheckoutMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpdateCheckoutMutation,
  GeneratedUpdateCheckoutMutationVariables
>;
export const CreateDrawEntryIntentDocument = gql`
  mutation CreateDrawEntryIntent(
    $drawId: ID!
    $currencyCode: String!
    $shippingAddressId: ID
    $paymentMethodId: ID
    $optionalItems: [OrderItem]
    $cryptoPaymentRequested: Boolean
  ) {
    createDrawEntryIntent(
      drawId: $drawId
      currencyCode: $currencyCode
      shippingAddressId: $shippingAddressId
      paymentMethodId: $paymentMethodId
      optionalItems: $optionalItems
      cryptoPaymentRequested: $cryptoPaymentRequested
    ) {
      intent {
        id
        errorCode
        confirmedAt
        discardedAt
      }
      setupSecret
      pricing {
        id
        createdAt
        pricing {
          totalPrice
          subtotalTaxPrice
          subtotalShippingPrice
          subtotalItemsPrice
          currencyCode
          taxRate
          taxType
          breakdown {
            productId
            variantId
            title
            price
            quantity
            isTaxable
            freeShipping
            requiresShipping
            tags
            carrierServiceName
            shippingProviderId
          }
          includesCalculatedShipping
          includesCalculatedTaxes
        }
      }
    }
  }
`;
export type GeneratedCreateDrawEntryIntentMutationFn = Apollo.MutationFunction<
  GeneratedCreateDrawEntryIntentMutation,
  GeneratedCreateDrawEntryIntentMutationVariables
>;

/**
 * __useCreateDrawEntryIntentMutation__
 *
 * To run a mutation, you first call `useCreateDrawEntryIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDrawEntryIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDrawEntryIntentMutation, { data, loading, error }] = useCreateDrawEntryIntentMutation({
 *   variables: {
 *      drawId: // value for 'drawId'
 *      currencyCode: // value for 'currencyCode'
 *      shippingAddressId: // value for 'shippingAddressId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      optionalItems: // value for 'optionalItems'
 *      cryptoPaymentRequested: // value for 'cryptoPaymentRequested'
 *   },
 * });
 */
export function useCreateDrawEntryIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedCreateDrawEntryIntentMutation,
    GeneratedCreateDrawEntryIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedCreateDrawEntryIntentMutation,
    GeneratedCreateDrawEntryIntentMutationVariables
  >(CreateDrawEntryIntentDocument, options);
}
export type CreateDrawEntryIntentMutationHookResult = ReturnType<
  typeof useCreateDrawEntryIntentMutation
>;
export type CreateDrawEntryIntentMutationResult =
  Apollo.MutationResult<GeneratedCreateDrawEntryIntentMutation>;
export type CreateDrawEntryIntentMutationOptions = Apollo.BaseMutationOptions<
  GeneratedCreateDrawEntryIntentMutation,
  GeneratedCreateDrawEntryIntentMutationVariables
>;
export const CreateReviewDocument = gql`
  mutation createReview($input: CreateReviewInput!) {
    CreateReview(input: $input) {
      id
    }
  }
`;
export type GeneratedCreateReviewMutationFn = Apollo.MutationFunction<
  GeneratedCreateReviewMutation,
  GeneratedCreateReviewMutationVariables
>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedCreateReviewMutation,
    GeneratedCreateReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedCreateReviewMutation,
    GeneratedCreateReviewMutationVariables
  >(CreateReviewDocument, options);
}
export type CreateReviewMutationHookResult = ReturnType<
  typeof useCreateReviewMutation
>;
export type CreateReviewMutationResult =
  Apollo.MutationResult<GeneratedCreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<
  GeneratedCreateReviewMutation,
  GeneratedCreateReviewMutationVariables
>;
export const CreateSubscriptionsDocument = gql`
  mutation createSubscriptions(
    $subscriptions: [CreateSubscriptionsInput!]!
    $UTMCampaign: String
    $UTMMedium: String
    $UTMSource: String
  ) {
    createSubscriptions(
      subscriptions: $subscriptions
      UTMCampaign: $UTMCampaign
      UTMMedium: $UTMMedium
      UTMSource: $UTMSource
    ) {
      subscribed
    }
  }
`;
export type GeneratedCreateSubscriptionsMutationFn = Apollo.MutationFunction<
  GeneratedCreateSubscriptionsMutation,
  GeneratedCreateSubscriptionsMutationVariables
>;

/**
 * __useCreateSubscriptionsMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionsMutation, { data, loading, error }] = useCreateSubscriptionsMutation({
 *   variables: {
 *      subscriptions: // value for 'subscriptions'
 *      UTMCampaign: // value for 'UTMCampaign'
 *      UTMMedium: // value for 'UTMMedium'
 *      UTMSource: // value for 'UTMSource'
 *   },
 * });
 */
export function useCreateSubscriptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedCreateSubscriptionsMutation,
    GeneratedCreateSubscriptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedCreateSubscriptionsMutation,
    GeneratedCreateSubscriptionsMutationVariables
  >(CreateSubscriptionsDocument, options);
}
export type CreateSubscriptionsMutationHookResult = ReturnType<
  typeof useCreateSubscriptionsMutation
>;
export type CreateSubscriptionsMutationResult =
  Apollo.MutationResult<GeneratedCreateSubscriptionsMutation>;
export type CreateSubscriptionsMutationOptions = Apollo.BaseMutationOptions<
  GeneratedCreateSubscriptionsMutation,
  GeneratedCreateSubscriptionsMutationVariables
>;
export const GetEuroFxDocument = gql`
  query getEuroFx {
    euroFx {
      currency
      value
    }
  }
`;

/**
 * __useGetEuroFxQuery__
 *
 * To run a query within a React component, call `useGetEuroFxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEuroFxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEuroFxQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEuroFxQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedGetEuroFxQuery,
    GeneratedGetEuroFxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetEuroFxQuery,
    GeneratedGetEuroFxQueryVariables
  >(GetEuroFxDocument, options);
}
export function useGetEuroFxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetEuroFxQuery,
    GeneratedGetEuroFxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetEuroFxQuery,
    GeneratedGetEuroFxQueryVariables
  >(GetEuroFxDocument, options);
}
export function useGetEuroFxSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetEuroFxQuery,
        GeneratedGetEuroFxQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetEuroFxQuery,
    GeneratedGetEuroFxQueryVariables
  >(GetEuroFxDocument, options);
}
export type GetEuroFxQueryHookResult = ReturnType<typeof useGetEuroFxQuery>;
export type GetEuroFxLazyQueryHookResult = ReturnType<
  typeof useGetEuroFxLazyQuery
>;
export type GetEuroFxSuspenseQueryHookResult = ReturnType<
  typeof useGetEuroFxSuspenseQuery
>;
export type GetEuroFxQueryResult = Apollo.QueryResult<
  GeneratedGetEuroFxQuery,
  GeneratedGetEuroFxQueryVariables
>;
export const GetAccountDocument = gql`
  query getAccount {
    account {
      id
      username
      aml
      avatar
      fullName
      email
      countryCode
      phone
      pronouns
      createdAt
      dateOfBirth
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedGetAccountQuery,
    GeneratedGetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetAccountQuery,
    GeneratedGetAccountQueryVariables
  >(GetAccountDocument, options);
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetAccountQuery,
    GeneratedGetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetAccountQuery,
    GeneratedGetAccountQueryVariables
  >(GetAccountDocument, options);
}
export function useGetAccountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetAccountQuery,
        GeneratedGetAccountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetAccountQuery,
    GeneratedGetAccountQueryVariables
  >(GetAccountDocument, options);
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountLazyQuery
>;
export type GetAccountSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountSuspenseQuery
>;
export type GetAccountQueryResult = Apollo.QueryResult<
  GeneratedGetAccountQuery,
  GeneratedGetAccountQueryVariables
>;
export const BlockchainGetDelegationsByDelegateDocument = gql`
  query blockchainGetDelegationsByDelegate(
    $input: BlockchainGetDelegationsByDelegateInput!
  ) {
    blockchainGetDelegationsByDelegate(input: $input) {
      type
      vault
      delegate
      contract
      tokenId
    }
  }
`;

/**
 * __useBlockchainGetDelegationsByDelegateQuery__
 *
 * To run a query within a React component, call `useBlockchainGetDelegationsByDelegateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainGetDelegationsByDelegateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainGetDelegationsByDelegateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockchainGetDelegationsByDelegateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedBlockchainGetDelegationsByDelegateQuery,
    GeneratedBlockchainGetDelegationsByDelegateQueryVariables
  > &
    (
      | {
          variables: GeneratedBlockchainGetDelegationsByDelegateQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedBlockchainGetDelegationsByDelegateQuery,
    GeneratedBlockchainGetDelegationsByDelegateQueryVariables
  >(BlockchainGetDelegationsByDelegateDocument, options);
}
export function useBlockchainGetDelegationsByDelegateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedBlockchainGetDelegationsByDelegateQuery,
    GeneratedBlockchainGetDelegationsByDelegateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedBlockchainGetDelegationsByDelegateQuery,
    GeneratedBlockchainGetDelegationsByDelegateQueryVariables
  >(BlockchainGetDelegationsByDelegateDocument, options);
}
export function useBlockchainGetDelegationsByDelegateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedBlockchainGetDelegationsByDelegateQuery,
        GeneratedBlockchainGetDelegationsByDelegateQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedBlockchainGetDelegationsByDelegateQuery,
    GeneratedBlockchainGetDelegationsByDelegateQueryVariables
  >(BlockchainGetDelegationsByDelegateDocument, options);
}
export type BlockchainGetDelegationsByDelegateQueryHookResult = ReturnType<
  typeof useBlockchainGetDelegationsByDelegateQuery
>;
export type BlockchainGetDelegationsByDelegateLazyQueryHookResult = ReturnType<
  typeof useBlockchainGetDelegationsByDelegateLazyQuery
>;
export type BlockchainGetDelegationsByDelegateSuspenseQueryHookResult =
  ReturnType<typeof useBlockchainGetDelegationsByDelegateSuspenseQuery>;
export type BlockchainGetDelegationsByDelegateQueryResult = Apollo.QueryResult<
  GeneratedBlockchainGetDelegationsByDelegateQuery,
  GeneratedBlockchainGetDelegationsByDelegateQueryVariables
>;
export const GetDrawByProductIDsDocument = gql`
  query GetDrawByProductIDs($productIDs: [String!]!) {
    getDrawByProductIDs(productIDs: $productIDs) {
      closeAt
      preOrderCloseAt
      productId
    }
  }
`;

/**
 * __useGetDrawByProductIDsQuery__
 *
 * To run a query within a React component, call `useGetDrawByProductIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawByProductIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawByProductIDsQuery({
 *   variables: {
 *      productIDs: // value for 'productIDs'
 *   },
 * });
 */
export function useGetDrawByProductIDsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetDrawByProductIDsQuery,
    GeneratedGetDrawByProductIDsQueryVariables
  > &
    (
      | {
          variables: GeneratedGetDrawByProductIDsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetDrawByProductIDsQuery,
    GeneratedGetDrawByProductIDsQueryVariables
  >(GetDrawByProductIDsDocument, options);
}
export function useGetDrawByProductIDsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetDrawByProductIDsQuery,
    GeneratedGetDrawByProductIDsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetDrawByProductIDsQuery,
    GeneratedGetDrawByProductIDsQueryVariables
  >(GetDrawByProductIDsDocument, options);
}
export function useGetDrawByProductIDsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetDrawByProductIDsQuery,
        GeneratedGetDrawByProductIDsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetDrawByProductIDsQuery,
    GeneratedGetDrawByProductIDsQueryVariables
  >(GetDrawByProductIDsDocument, options);
}
export type GetDrawByProductIDsQueryHookResult = ReturnType<
  typeof useGetDrawByProductIDsQuery
>;
export type GetDrawByProductIDsLazyQueryHookResult = ReturnType<
  typeof useGetDrawByProductIDsLazyQuery
>;
export type GetDrawByProductIDsSuspenseQueryHookResult = ReturnType<
  typeof useGetDrawByProductIDsSuspenseQuery
>;
export type GetDrawByProductIDsQueryResult = Apollo.QueryResult<
  GeneratedGetDrawByProductIDsQuery,
  GeneratedGetDrawByProductIDsQueryVariables
>;
export const GetHlsDocument = gql`
  query getHls($eventID: ID!) {
    eventarteHlsURL(eventID: $eventID)
  }
`;

/**
 * __useGetHlsQuery__
 *
 * To run a query within a React component, call `useGetHlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHlsQuery({
 *   variables: {
 *      eventID: // value for 'eventID'
 *   },
 * });
 */
export function useGetHlsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetHlsQuery,
    GeneratedGetHlsQueryVariables
  > &
    (
      | { variables: GeneratedGetHlsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneratedGetHlsQuery, GeneratedGetHlsQueryVariables>(
    GetHlsDocument,
    options,
  );
}
export function useGetHlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetHlsQuery,
    GeneratedGetHlsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetHlsQuery,
    GeneratedGetHlsQueryVariables
  >(GetHlsDocument, options);
}
export function useGetHlsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetHlsQuery,
        GeneratedGetHlsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetHlsQuery,
    GeneratedGetHlsQueryVariables
  >(GetHlsDocument, options);
}
export type GetHlsQueryHookResult = ReturnType<typeof useGetHlsQuery>;
export type GetHlsLazyQueryHookResult = ReturnType<typeof useGetHlsLazyQuery>;
export type GetHlsSuspenseQueryHookResult = ReturnType<
  typeof useGetHlsSuspenseQuery
>;
export type GetHlsQueryResult = Apollo.QueryResult<
  GeneratedGetHlsQuery,
  GeneratedGetHlsQueryVariables
>;
export const GetLiveEventPollDocument = gql`
  query getLiveEventPoll($idOrSlug: ID!) {
    eventarte(idOrSlug: $idOrSlug) {
      journalURL
      startTime
      endTime
      replayReady
      vimeoClipID
      giveaways {
        id
        entryTriggerEmoji
        name
        entryTriggerWord
        winnerUsername
        startTime
        endTime
      }
    }
  }
`;

/**
 * __useGetLiveEventPollQuery__
 *
 * To run a query within a React component, call `useGetLiveEventPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveEventPollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveEventPollQuery({
 *   variables: {
 *      idOrSlug: // value for 'idOrSlug'
 *   },
 * });
 */
export function useGetLiveEventPollQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetLiveEventPollQuery,
    GeneratedGetLiveEventPollQueryVariables
  > &
    (
      | { variables: GeneratedGetLiveEventPollQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetLiveEventPollQuery,
    GeneratedGetLiveEventPollQueryVariables
  >(GetLiveEventPollDocument, options);
}
export function useGetLiveEventPollLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetLiveEventPollQuery,
    GeneratedGetLiveEventPollQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetLiveEventPollQuery,
    GeneratedGetLiveEventPollQueryVariables
  >(GetLiveEventPollDocument, options);
}
export function useGetLiveEventPollSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetLiveEventPollQuery,
        GeneratedGetLiveEventPollQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetLiveEventPollQuery,
    GeneratedGetLiveEventPollQueryVariables
  >(GetLiveEventPollDocument, options);
}
export type GetLiveEventPollQueryHookResult = ReturnType<
  typeof useGetLiveEventPollQuery
>;
export type GetLiveEventPollLazyQueryHookResult = ReturnType<
  typeof useGetLiveEventPollLazyQuery
>;
export type GetLiveEventPollSuspenseQueryHookResult = ReturnType<
  typeof useGetLiveEventPollSuspenseQuery
>;
export type GetLiveEventPollQueryResult = Apollo.QueryResult<
  GeneratedGetLiveEventPollQuery,
  GeneratedGetLiveEventPollQueryVariables
>;
export const GetLiveEventDocument = gql`
  query getLiveEvent($idOrSlug: ID!) {
    eventarte(idOrSlug: $idOrSlug) {
      id
      slug
      title
      subtitle
      description
      location
      posterURL
      chatPosterURL
      vimeoClipID
      vimeoEventID
      replayReady
      medias {
        coverUrl
        duration
        mediaType
        url
      }
      journalURL
      timeline {
        time
        description
      }
      artistAccountIDs
      artists {
        id
        name
        slug
        thumbnail
      }
      productInfo
      products {
        id
        shopifyID
        name
        price
        imageURL
        shopifyProduct {
          description
          dimensions
          editionSize
          finishing
          framingOptions
          id
          images {
            url
          }
          medium
          name
          price
          slug
          storyblokID
          frameOptions {
            name
            frameId
            component
            frameImage {
              url
              alt
              height
              width
            }
            description
            productCompositeImage {
              url
              alt
              height
              width
            }
          }
        }
      }
      startTime
      endTime
      giveaways {
        id
        entryTriggerEmoji
        name
        entryTriggerWord
        winnerUsername
        startTime
        endTime
      }
    }
  }
`;

/**
 * __useGetLiveEventQuery__
 *
 * To run a query within a React component, call `useGetLiveEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveEventQuery({
 *   variables: {
 *      idOrSlug: // value for 'idOrSlug'
 *   },
 * });
 */
export function useGetLiveEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetLiveEventQuery,
    GeneratedGetLiveEventQueryVariables
  > &
    (
      | { variables: GeneratedGetLiveEventQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetLiveEventQuery,
    GeneratedGetLiveEventQueryVariables
  >(GetLiveEventDocument, options);
}
export function useGetLiveEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetLiveEventQuery,
    GeneratedGetLiveEventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetLiveEventQuery,
    GeneratedGetLiveEventQueryVariables
  >(GetLiveEventDocument, options);
}
export function useGetLiveEventSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetLiveEventQuery,
        GeneratedGetLiveEventQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetLiveEventQuery,
    GeneratedGetLiveEventQueryVariables
  >(GetLiveEventDocument, options);
}
export type GetLiveEventQueryHookResult = ReturnType<
  typeof useGetLiveEventQuery
>;
export type GetLiveEventLazyQueryHookResult = ReturnType<
  typeof useGetLiveEventLazyQuery
>;
export type GetLiveEventSuspenseQueryHookResult = ReturnType<
  typeof useGetLiveEventSuspenseQuery
>;
export type GetLiveEventQueryResult = Apollo.QueryResult<
  GeneratedGetLiveEventQuery,
  GeneratedGetLiveEventQueryVariables
>;
export const GetOrderReviewProductDocument = gql`
  query getOrderReviewProduct($shopifyProductId: String!) {
    getProducts(shopifyIDs: [$shopifyProductId]) {
      id
      title
      thumbnail {
        url
        alt
      }
      artists {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOrderReviewProductQuery__
 *
 * To run a query within a React component, call `useGetOrderReviewProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderReviewProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderReviewProductQuery({
 *   variables: {
 *      shopifyProductId: // value for 'shopifyProductId'
 *   },
 * });
 */
export function useGetOrderReviewProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetOrderReviewProductQuery,
    GeneratedGetOrderReviewProductQueryVariables
  > &
    (
      | {
          variables: GeneratedGetOrderReviewProductQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetOrderReviewProductQuery,
    GeneratedGetOrderReviewProductQueryVariables
  >(GetOrderReviewProductDocument, options);
}
export function useGetOrderReviewProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetOrderReviewProductQuery,
    GeneratedGetOrderReviewProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetOrderReviewProductQuery,
    GeneratedGetOrderReviewProductQueryVariables
  >(GetOrderReviewProductDocument, options);
}
export function useGetOrderReviewProductSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetOrderReviewProductQuery,
        GeneratedGetOrderReviewProductQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetOrderReviewProductQuery,
    GeneratedGetOrderReviewProductQueryVariables
  >(GetOrderReviewProductDocument, options);
}
export type GetOrderReviewProductQueryHookResult = ReturnType<
  typeof useGetOrderReviewProductQuery
>;
export type GetOrderReviewProductLazyQueryHookResult = ReturnType<
  typeof useGetOrderReviewProductLazyQuery
>;
export type GetOrderReviewProductSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderReviewProductSuspenseQuery
>;
export type GetOrderReviewProductQueryResult = Apollo.QueryResult<
  GeneratedGetOrderReviewProductQuery,
  GeneratedGetOrderReviewProductQueryVariables
>;
export const GetPrivateLinkDocument = gql`
  query getPrivateLink($slug: String!) {
    unstable__privateLink(slug: $slug) {
      expiresAt
      heading
      imageUrl
      intro
      passwordRequired
      slug
      strapline
      videoUrl
    }
  }
`;

/**
 * __useGetPrivateLinkQuery__
 *
 * To run a query within a React component, call `useGetPrivateLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateLinkQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPrivateLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetPrivateLinkQuery,
    GeneratedGetPrivateLinkQueryVariables
  > &
    (
      | { variables: GeneratedGetPrivateLinkQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetPrivateLinkQuery,
    GeneratedGetPrivateLinkQueryVariables
  >(GetPrivateLinkDocument, options);
}
export function useGetPrivateLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetPrivateLinkQuery,
    GeneratedGetPrivateLinkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetPrivateLinkQuery,
    GeneratedGetPrivateLinkQueryVariables
  >(GetPrivateLinkDocument, options);
}
export function useGetPrivateLinkSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetPrivateLinkQuery,
        GeneratedGetPrivateLinkQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetPrivateLinkQuery,
    GeneratedGetPrivateLinkQueryVariables
  >(GetPrivateLinkDocument, options);
}
export type GetPrivateLinkQueryHookResult = ReturnType<
  typeof useGetPrivateLinkQuery
>;
export type GetPrivateLinkLazyQueryHookResult = ReturnType<
  typeof useGetPrivateLinkLazyQuery
>;
export type GetPrivateLinkSuspenseQueryHookResult = ReturnType<
  typeof useGetPrivateLinkSuspenseQuery
>;
export type GetPrivateLinkQueryResult = Apollo.QueryResult<
  GeneratedGetPrivateLinkQuery,
  GeneratedGetPrivateLinkQueryVariables
>;
export const GetPrivateLinkWithProductsDocument = gql`
  query getPrivateLinkWithProducts($slug: String!, $password: String!) {
    unstable__privateLink(slug: $slug, password: $password) {
      slug
      expiresAt
      heading
      imageUrl
      videoUrl
      intro
      strapline
      products {
        comment {
          body
          avatarUrl
        }
        shopifyVariantId
        shopifyProductId
        unlockCode
        tags
        isEnquiry
      }
    }
  }
`;

/**
 * __useGetPrivateLinkWithProductsQuery__
 *
 * To run a query within a React component, call `useGetPrivateLinkWithProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateLinkWithProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateLinkWithProductsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetPrivateLinkWithProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetPrivateLinkWithProductsQuery,
    GeneratedGetPrivateLinkWithProductsQueryVariables
  > &
    (
      | {
          variables: GeneratedGetPrivateLinkWithProductsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetPrivateLinkWithProductsQuery,
    GeneratedGetPrivateLinkWithProductsQueryVariables
  >(GetPrivateLinkWithProductsDocument, options);
}
export function useGetPrivateLinkWithProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetPrivateLinkWithProductsQuery,
    GeneratedGetPrivateLinkWithProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetPrivateLinkWithProductsQuery,
    GeneratedGetPrivateLinkWithProductsQueryVariables
  >(GetPrivateLinkWithProductsDocument, options);
}
export function useGetPrivateLinkWithProductsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetPrivateLinkWithProductsQuery,
        GeneratedGetPrivateLinkWithProductsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetPrivateLinkWithProductsQuery,
    GeneratedGetPrivateLinkWithProductsQueryVariables
  >(GetPrivateLinkWithProductsDocument, options);
}
export type GetPrivateLinkWithProductsQueryHookResult = ReturnType<
  typeof useGetPrivateLinkWithProductsQuery
>;
export type GetPrivateLinkWithProductsLazyQueryHookResult = ReturnType<
  typeof useGetPrivateLinkWithProductsLazyQuery
>;
export type GetPrivateLinkWithProductsSuspenseQueryHookResult = ReturnType<
  typeof useGetPrivateLinkWithProductsSuspenseQuery
>;
export type GetPrivateLinkWithProductsQueryResult = Apollo.QueryResult<
  GeneratedGetPrivateLinkWithProductsQuery,
  GeneratedGetPrivateLinkWithProductsQueryVariables
>;
export const GetSubscriptionsCountDocument = gql`
  query getSubscriptionsCount(
    $type: SubscriptionType!
    $objectId: String!
    $withRandomAccounts: Boolean!
  ) {
    subscriptionsCount(
      type: $type
      objectId: $objectId
      withRandomAccounts: $withRandomAccounts
    ) {
      ...SubscriptionsCount
    }
  }
  ${SubscriptionsCountFragmentDoc}
`;

/**
 * __useGetSubscriptionsCountQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsCountQuery({
 *   variables: {
 *      type: // value for 'type'
 *      objectId: // value for 'objectId'
 *      withRandomAccounts: // value for 'withRandomAccounts'
 *   },
 * });
 */
export function useGetSubscriptionsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetSubscriptionsCountQuery,
    GeneratedGetSubscriptionsCountQueryVariables
  > &
    (
      | {
          variables: GeneratedGetSubscriptionsCountQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetSubscriptionsCountQuery,
    GeneratedGetSubscriptionsCountQueryVariables
  >(GetSubscriptionsCountDocument, options);
}
export function useGetSubscriptionsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetSubscriptionsCountQuery,
    GeneratedGetSubscriptionsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetSubscriptionsCountQuery,
    GeneratedGetSubscriptionsCountQueryVariables
  >(GetSubscriptionsCountDocument, options);
}
export function useGetSubscriptionsCountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetSubscriptionsCountQuery,
        GeneratedGetSubscriptionsCountQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetSubscriptionsCountQuery,
    GeneratedGetSubscriptionsCountQueryVariables
  >(GetSubscriptionsCountDocument, options);
}
export type GetSubscriptionsCountQueryHookResult = ReturnType<
  typeof useGetSubscriptionsCountQuery
>;
export type GetSubscriptionsCountLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionsCountLazyQuery
>;
export type GetSubscriptionsCountSuspenseQueryHookResult = ReturnType<
  typeof useGetSubscriptionsCountSuspenseQuery
>;
export type GetSubscriptionsCountQueryResult = Apollo.QueryResult<
  GeneratedGetSubscriptionsCountQuery,
  GeneratedGetSubscriptionsCountQueryVariables
>;
export const GetVoucherByTokenIdAndProjectIdDocument = gql`
  query getVoucherByTokenIdAndProjectId($projectId: String!, $tokenId: Int!) {
    getVoucherByTokenIdAndProjectId(projectId: $projectId, tokenId: $tokenId) {
      id
      uid
      walletAddress
      accountId
      tokenId
      chainId
      contractAddress
      projectId
      price
      validUntil
      claimed
      claimer
      nftContractAddress
      editionNumber
      orderId
      status
      tokenStandard
      createTime
      updateTime
    }
  }
`;

/**
 * __useGetVoucherByTokenIdAndProjectIdQuery__
 *
 * To run a query within a React component, call `useGetVoucherByTokenIdAndProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoucherByTokenIdAndProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoucherByTokenIdAndProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGetVoucherByTokenIdAndProjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetVoucherByTokenIdAndProjectIdQuery,
    GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
  > &
    (
      | {
          variables: GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetVoucherByTokenIdAndProjectIdQuery,
    GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
  >(GetVoucherByTokenIdAndProjectIdDocument, options);
}
export function useGetVoucherByTokenIdAndProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetVoucherByTokenIdAndProjectIdQuery,
    GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetVoucherByTokenIdAndProjectIdQuery,
    GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
  >(GetVoucherByTokenIdAndProjectIdDocument, options);
}
export function useGetVoucherByTokenIdAndProjectIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetVoucherByTokenIdAndProjectIdQuery,
        GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetVoucherByTokenIdAndProjectIdQuery,
    GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
  >(GetVoucherByTokenIdAndProjectIdDocument, options);
}
export type GetVoucherByTokenIdAndProjectIdQueryHookResult = ReturnType<
  typeof useGetVoucherByTokenIdAndProjectIdQuery
>;
export type GetVoucherByTokenIdAndProjectIdLazyQueryHookResult = ReturnType<
  typeof useGetVoucherByTokenIdAndProjectIdLazyQuery
>;
export type GetVoucherByTokenIdAndProjectIdSuspenseQueryHookResult = ReturnType<
  typeof useGetVoucherByTokenIdAndProjectIdSuspenseQuery
>;
export type GetVoucherByTokenIdAndProjectIdQueryResult = Apollo.QueryResult<
  GeneratedGetVoucherByTokenIdAndProjectIdQuery,
  GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
>;
export const HubspotWhitepaperListDocument = gql`
  mutation HubspotWhitepaperList($email: String!, $captcha: String!) {
    HubspotWhitepaperList(email: $email, captcha: $captcha)
  }
`;
export type GeneratedHubspotWhitepaperListMutationFn = Apollo.MutationFunction<
  GeneratedHubspotWhitepaperListMutation,
  GeneratedHubspotWhitepaperListMutationVariables
>;

/**
 * __useHubspotWhitepaperListMutation__
 *
 * To run a mutation, you first call `useHubspotWhitepaperListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHubspotWhitepaperListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hubspotWhitepaperListMutation, { data, loading, error }] = useHubspotWhitepaperListMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useHubspotWhitepaperListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedHubspotWhitepaperListMutation,
    GeneratedHubspotWhitepaperListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedHubspotWhitepaperListMutation,
    GeneratedHubspotWhitepaperListMutationVariables
  >(HubspotWhitepaperListDocument, options);
}
export type HubspotWhitepaperListMutationHookResult = ReturnType<
  typeof useHubspotWhitepaperListMutation
>;
export type HubspotWhitepaperListMutationResult =
  Apollo.MutationResult<GeneratedHubspotWhitepaperListMutation>;
export type HubspotWhitepaperListMutationOptions = Apollo.BaseMutationOptions<
  GeneratedHubspotWhitepaperListMutation,
  GeneratedHubspotWhitepaperListMutationVariables
>;
export const IsSubscribedDocument = gql`
  query IsSubscribed($subscriptions: [IsSubscribedSubscriptionsInput!]!) {
    isSubscribed(subscriptions: $subscriptions) {
      subscriptions {
        objectId
        sType
      }
    }
  }
`;

/**
 * __useIsSubscribedQuery__
 *
 * To run a query within a React component, call `useIsSubscribedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSubscribedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSubscribedQuery({
 *   variables: {
 *      subscriptions: // value for 'subscriptions'
 *   },
 * });
 */
export function useIsSubscribedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedIsSubscribedQuery,
    GeneratedIsSubscribedQueryVariables
  > &
    (
      | { variables: GeneratedIsSubscribedQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedIsSubscribedQuery,
    GeneratedIsSubscribedQueryVariables
  >(IsSubscribedDocument, options);
}
export function useIsSubscribedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedIsSubscribedQuery,
    GeneratedIsSubscribedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedIsSubscribedQuery,
    GeneratedIsSubscribedQueryVariables
  >(IsSubscribedDocument, options);
}
export function useIsSubscribedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedIsSubscribedQuery,
        GeneratedIsSubscribedQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedIsSubscribedQuery,
    GeneratedIsSubscribedQueryVariables
  >(IsSubscribedDocument, options);
}
export type IsSubscribedQueryHookResult = ReturnType<
  typeof useIsSubscribedQuery
>;
export type IsSubscribedLazyQueryHookResult = ReturnType<
  typeof useIsSubscribedLazyQuery
>;
export type IsSubscribedSuspenseQueryHookResult = ReturnType<
  typeof useIsSubscribedSuspenseQuery
>;
export type IsSubscribedQueryResult = Apollo.QueryResult<
  GeneratedIsSubscribedQuery,
  GeneratedIsSubscribedQueryVariables
>;
export const EventarteJoinedChatDocument = gql`
  mutation eventarteJoinedChat($eventID: ID!) {
    eventarteJoinedChat(eventID: $eventID)
  }
`;
export type GeneratedEventarteJoinedChatMutationFn = Apollo.MutationFunction<
  GeneratedEventarteJoinedChatMutation,
  GeneratedEventarteJoinedChatMutationVariables
>;

/**
 * __useEventarteJoinedChatMutation__
 *
 * To run a mutation, you first call `useEventarteJoinedChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventarteJoinedChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventarteJoinedChatMutation, { data, loading, error }] = useEventarteJoinedChatMutation({
 *   variables: {
 *      eventID: // value for 'eventID'
 *   },
 * });
 */
export function useEventarteJoinedChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedEventarteJoinedChatMutation,
    GeneratedEventarteJoinedChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedEventarteJoinedChatMutation,
    GeneratedEventarteJoinedChatMutationVariables
  >(EventarteJoinedChatDocument, options);
}
export type EventarteJoinedChatMutationHookResult = ReturnType<
  typeof useEventarteJoinedChatMutation
>;
export type EventarteJoinedChatMutationResult =
  Apollo.MutationResult<GeneratedEventarteJoinedChatMutation>;
export type EventarteJoinedChatMutationOptions = Apollo.BaseMutationOptions<
  GeneratedEventarteJoinedChatMutation,
  GeneratedEventarteJoinedChatMutationVariables
>;
export const LeaveDrawDocument = gql`
  mutation LeaveDraw($id: ID!) {
    leaveDraw(id: $id) {
      id
    }
  }
`;
export type GeneratedLeaveDrawMutationFn = Apollo.MutationFunction<
  GeneratedLeaveDrawMutation,
  GeneratedLeaveDrawMutationVariables
>;

/**
 * __useLeaveDrawMutation__
 *
 * To run a mutation, you first call `useLeaveDrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveDrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveDrawMutation, { data, loading, error }] = useLeaveDrawMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveDrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedLeaveDrawMutation,
    GeneratedLeaveDrawMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedLeaveDrawMutation,
    GeneratedLeaveDrawMutationVariables
  >(LeaveDrawDocument, options);
}
export type LeaveDrawMutationHookResult = ReturnType<
  typeof useLeaveDrawMutation
>;
export type LeaveDrawMutationResult =
  Apollo.MutationResult<GeneratedLeaveDrawMutation>;
export type LeaveDrawMutationOptions = Apollo.BaseMutationOptions<
  GeneratedLeaveDrawMutation,
  GeneratedLeaveDrawMutationVariables
>;
export const ListDrawEntryIntentsDocument = gql`
  query listDrawEntryIntents {
    listDrawEntryIntent {
      id
      draw {
        id
        product {
          id
          shopifyId
          slug
          title
          price
          thumbnail {
            url
          }
        }
        closeAt
        preOrderCloseAt
        status
      }
      confirmedAt
      cryptoPaymentRequested
      preOrder
      paymentMethod {
        type
        card {
          brand
          expireMonth
          expireYear
          last4
        }
      }
    }
  }
`;

/**
 * __useListDrawEntryIntentsQuery__
 *
 * To run a query within a React component, call `useListDrawEntryIntentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDrawEntryIntentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDrawEntryIntentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListDrawEntryIntentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedListDrawEntryIntentsQuery,
    GeneratedListDrawEntryIntentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedListDrawEntryIntentsQuery,
    GeneratedListDrawEntryIntentsQueryVariables
  >(ListDrawEntryIntentsDocument, options);
}
export function useListDrawEntryIntentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedListDrawEntryIntentsQuery,
    GeneratedListDrawEntryIntentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedListDrawEntryIntentsQuery,
    GeneratedListDrawEntryIntentsQueryVariables
  >(ListDrawEntryIntentsDocument, options);
}
export function useListDrawEntryIntentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedListDrawEntryIntentsQuery,
        GeneratedListDrawEntryIntentsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedListDrawEntryIntentsQuery,
    GeneratedListDrawEntryIntentsQueryVariables
  >(ListDrawEntryIntentsDocument, options);
}
export type ListDrawEntryIntentsQueryHookResult = ReturnType<
  typeof useListDrawEntryIntentsQuery
>;
export type ListDrawEntryIntentsLazyQueryHookResult = ReturnType<
  typeof useListDrawEntryIntentsLazyQuery
>;
export type ListDrawEntryIntentsSuspenseQueryHookResult = ReturnType<
  typeof useListDrawEntryIntentsSuspenseQuery
>;
export type ListDrawEntryIntentsQueryResult = Apollo.QueryResult<
  GeneratedListDrawEntryIntentsQuery,
  GeneratedListDrawEntryIntentsQueryVariables
>;
export const LoginDocument = gql`
  mutation Login(
    $email: String!
    $originUTMCampaign: String
    $originUTMMedium: String
    $originUTMSource: String
    $origin: String
    $captcha: String
  ) {
    login(
      email: $email
      originUTMCampaign: $originUTMCampaign
      originUTMMedium: $originUTMMedium
      originUTMSource: $originUTMSource
      origin: $origin
      captcha: $captcha
    )
  }
`;
export type GeneratedLoginMutationFn = Apollo.MutationFunction<
  GeneratedLoginMutation,
  GeneratedLoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      originUTMCampaign: // value for 'originUTMCampaign'
 *      originUTMMedium: // value for 'originUTMMedium'
 *      originUTMSource: // value for 'originUTMSource'
 *      origin: // value for 'origin'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedLoginMutation,
    GeneratedLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedLoginMutation,
    GeneratedLoginMutationVariables
  >(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<GeneratedLoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  GeneratedLoginMutation,
  GeneratedLoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type GeneratedLogoutMutationFn = Apollo.MutationFunction<
  GeneratedLogoutMutation,
  GeneratedLogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedLogoutMutation,
    GeneratedLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedLogoutMutation,
    GeneratedLogoutMutationVariables
  >(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult =
  Apollo.MutationResult<GeneratedLogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  GeneratedLogoutMutation,
  GeneratedLogoutMutationVariables
>;
export const ListCustomerOrdersDocument = gql`
  query listCustomerOrders($nextPageToken: String) {
    listOrders(nextPageToken: $nextPageToken) {
      nextPageToken
      orders {
        ...OrderDetailsSummary
      }
    }
  }
  ${OrderDetailsSummaryFragmentDoc}
`;

/**
 * __useListCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useListCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerOrdersQuery({
 *   variables: {
 *      nextPageToken: // value for 'nextPageToken'
 *   },
 * });
 */
export function useListCustomerOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedListCustomerOrdersQuery,
    GeneratedListCustomerOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedListCustomerOrdersQuery,
    GeneratedListCustomerOrdersQueryVariables
  >(ListCustomerOrdersDocument, options);
}
export function useListCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedListCustomerOrdersQuery,
    GeneratedListCustomerOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedListCustomerOrdersQuery,
    GeneratedListCustomerOrdersQueryVariables
  >(ListCustomerOrdersDocument, options);
}
export function useListCustomerOrdersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedListCustomerOrdersQuery,
        GeneratedListCustomerOrdersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedListCustomerOrdersQuery,
    GeneratedListCustomerOrdersQueryVariables
  >(ListCustomerOrdersDocument, options);
}
export type ListCustomerOrdersQueryHookResult = ReturnType<
  typeof useListCustomerOrdersQuery
>;
export type ListCustomerOrdersLazyQueryHookResult = ReturnType<
  typeof useListCustomerOrdersLazyQuery
>;
export type ListCustomerOrdersSuspenseQueryHookResult = ReturnType<
  typeof useListCustomerOrdersSuspenseQuery
>;
export type ListCustomerOrdersQueryResult = Apollo.QueryResult<
  GeneratedListCustomerOrdersQuery,
  GeneratedListCustomerOrdersQueryVariables
>;
export const GetOrderDocument = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      ...OrderDetails
    }
  }
  ${OrderDetailsFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedGetOrderQuery,
    GeneratedGetOrderQueryVariables
  > &
    (
      | { variables: GeneratedGetOrderQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetOrderQuery,
    GeneratedGetOrderQueryVariables
  >(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetOrderQuery,
    GeneratedGetOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetOrderQuery,
    GeneratedGetOrderQueryVariables
  >(GetOrderDocument, options);
}
export function useGetOrderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetOrderQuery,
        GeneratedGetOrderQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetOrderQuery,
    GeneratedGetOrderQueryVariables
  >(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<
  typeof useGetOrderLazyQuery
>;
export type GetOrderSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderSuspenseQuery
>;
export type GetOrderQueryResult = Apollo.QueryResult<
  GeneratedGetOrderQuery,
  GeneratedGetOrderQueryVariables
>;
export const GetQuestionnaireDocument = gql`
  query getQuestionnaire {
    getQuestionnaire {
      id
      frequency
      budget
      limitedPrints
      limitedSculptures
      digital
      collectibles
      paintings
      sculptures
      photography
      drawings
    }
  }
`;

/**
 * __useGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionnaireQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedGetQuestionnaireQuery,
    GeneratedGetQuestionnaireQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedGetQuestionnaireQuery,
    GeneratedGetQuestionnaireQueryVariables
  >(GetQuestionnaireDocument, options);
}
export function useGetQuestionnaireLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedGetQuestionnaireQuery,
    GeneratedGetQuestionnaireQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedGetQuestionnaireQuery,
    GeneratedGetQuestionnaireQueryVariables
  >(GetQuestionnaireDocument, options);
}
export function useGetQuestionnaireSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedGetQuestionnaireQuery,
        GeneratedGetQuestionnaireQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedGetQuestionnaireQuery,
    GeneratedGetQuestionnaireQueryVariables
  >(GetQuestionnaireDocument, options);
}
export type GetQuestionnaireQueryHookResult = ReturnType<
  typeof useGetQuestionnaireQuery
>;
export type GetQuestionnaireLazyQueryHookResult = ReturnType<
  typeof useGetQuestionnaireLazyQuery
>;
export type GetQuestionnaireSuspenseQueryHookResult = ReturnType<
  typeof useGetQuestionnaireSuspenseQuery
>;
export type GetQuestionnaireQueryResult = Apollo.QueryResult<
  GeneratedGetQuestionnaireQuery,
  GeneratedGetQuestionnaireQueryVariables
>;
export const UpdateQuestionnaireDocument = gql`
  mutation updateQuestionnaire($input: UpdateQuestionnaireInput!) {
    updateQuestionnaire(input: $input) {
      id
    }
  }
`;
export type GeneratedUpdateQuestionnaireMutationFn = Apollo.MutationFunction<
  GeneratedUpdateQuestionnaireMutation,
  GeneratedUpdateQuestionnaireMutationVariables
>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpdateQuestionnaireMutation,
    GeneratedUpdateQuestionnaireMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpdateQuestionnaireMutation,
    GeneratedUpdateQuestionnaireMutationVariables
  >(UpdateQuestionnaireDocument, options);
}
export type UpdateQuestionnaireMutationHookResult = ReturnType<
  typeof useUpdateQuestionnaireMutation
>;
export type UpdateQuestionnaireMutationResult =
  Apollo.MutationResult<GeneratedUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpdateQuestionnaireMutation,
  GeneratedUpdateQuestionnaireMutationVariables
>;
export const ReviewsDocument = gql`
  query reviews($shopifyProductId: String) {
    reviews(shopifyProductId: $shopifyProductId) {
      ...ReviewDetails
    }
  }
  ${ReviewDetailsFragmentDoc}
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      shopifyProductId: // value for 'shopifyProductId'
 *   },
 * });
 */
export function useReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneratedReviewsQuery,
    GeneratedReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneratedReviewsQuery, GeneratedReviewsQueryVariables>(
    ReviewsDocument,
    options,
  );
}
export function useReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedReviewsQuery,
    GeneratedReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedReviewsQuery,
    GeneratedReviewsQueryVariables
  >(ReviewsDocument, options);
}
export function useReviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedReviewsQuery,
        GeneratedReviewsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedReviewsQuery,
    GeneratedReviewsQueryVariables
  >(ReviewsDocument, options);
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsSuspenseQueryHookResult = ReturnType<
  typeof useReviewsSuspenseQuery
>;
export type ReviewsQueryResult = Apollo.QueryResult<
  GeneratedReviewsQuery,
  GeneratedReviewsQueryVariables
>;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $originUTMCampaign: String
    $originUTMMedium: String
    $originUTMSource: String
    $origin: String
    $captcha: String
  ) {
    signup(
      email: $email
      originUTMCampaign: $originUTMCampaign
      originUTMMedium: $originUTMMedium
      originUTMSource: $originUTMSource
      origin: $origin
      captcha: $captcha
    )
  }
`;
export type GeneratedSignupMutationFn = Apollo.MutationFunction<
  GeneratedSignupMutation,
  GeneratedSignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      originUTMCampaign: // value for 'originUTMCampaign'
 *      originUTMMedium: // value for 'originUTMMedium'
 *      originUTMSource: // value for 'originUTMSource'
 *      origin: // value for 'origin'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedSignupMutation,
    GeneratedSignupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedSignupMutation,
    GeneratedSignupMutationVariables
  >(SignupDocument, options);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult =
  Apollo.MutationResult<GeneratedSignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  GeneratedSignupMutation,
  GeneratedSignupMutationVariables
>;
export const SignupAndSubscribeDocument = gql`
  mutation SignupAndSubscribe(
    $email: String!
    $originUTMCampaign: String
    $originUTMMedium: String
    $originUTMSource: String
    $origin: String
    $subscriptions: [CreateSubscriptionsInput!]
    $silenceLoginCodeEmail: Boolean
    $captcha: String
  ) {
    signupAndSubscribe(
      email: $email
      originUTMCampaign: $originUTMCampaign
      originUTMMedium: $originUTMMedium
      originUTMSource: $originUTMSource
      origin: $origin
      subscriptions: $subscriptions
      silenceLoginCodeEmail: $silenceLoginCodeEmail
      captcha: $captcha
    )
  }
`;
export type GeneratedSignupAndSubscribeMutationFn = Apollo.MutationFunction<
  GeneratedSignupAndSubscribeMutation,
  GeneratedSignupAndSubscribeMutationVariables
>;

/**
 * __useSignupAndSubscribeMutation__
 *
 * To run a mutation, you first call `useSignupAndSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupAndSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupAndSubscribeMutation, { data, loading, error }] = useSignupAndSubscribeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      originUTMCampaign: // value for 'originUTMCampaign'
 *      originUTMMedium: // value for 'originUTMMedium'
 *      originUTMSource: // value for 'originUTMSource'
 *      origin: // value for 'origin'
 *      subscriptions: // value for 'subscriptions'
 *      silenceLoginCodeEmail: // value for 'silenceLoginCodeEmail'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useSignupAndSubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedSignupAndSubscribeMutation,
    GeneratedSignupAndSubscribeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedSignupAndSubscribeMutation,
    GeneratedSignupAndSubscribeMutationVariables
  >(SignupAndSubscribeDocument, options);
}
export type SignupAndSubscribeMutationHookResult = ReturnType<
  typeof useSignupAndSubscribeMutation
>;
export type SignupAndSubscribeMutationResult =
  Apollo.MutationResult<GeneratedSignupAndSubscribeMutation>;
export type SignupAndSubscribeMutationOptions = Apollo.BaseMutationOptions<
  GeneratedSignupAndSubscribeMutation,
  GeneratedSignupAndSubscribeMutationVariables
>;
export const SlackMessageDocument = gql`
  mutation SlackMessage(
    $channel: SlackChannel!
    $msg: String!
    $captcha: String!
  ) {
    SlackMessage(channel: $channel, msg: $msg, captcha: $captcha)
  }
`;
export type GeneratedSlackMessageMutationFn = Apollo.MutationFunction<
  GeneratedSlackMessageMutation,
  GeneratedSlackMessageMutationVariables
>;

/**
 * __useSlackMessageMutation__
 *
 * To run a mutation, you first call `useSlackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slackMessageMutation, { data, loading, error }] = useSlackMessageMutation({
 *   variables: {
 *      channel: // value for 'channel'
 *      msg: // value for 'msg'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useSlackMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedSlackMessageMutation,
    GeneratedSlackMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedSlackMessageMutation,
    GeneratedSlackMessageMutationVariables
  >(SlackMessageDocument, options);
}
export type SlackMessageMutationHookResult = ReturnType<
  typeof useSlackMessageMutation
>;
export type SlackMessageMutationResult =
  Apollo.MutationResult<GeneratedSlackMessageMutation>;
export type SlackMessageMutationOptions = Apollo.BaseMutationOptions<
  GeneratedSlackMessageMutation,
  GeneratedSlackMessageMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount(
    $name: String
    $username: String
    $countryCode: String
    $phone: String
    $pronouns: Pronouns
    $dateOfBirth: String
    $email: String
  ) {
    updateAccount(
      name: $name
      username: $username
      countryCode: $countryCode
      phone: $phone
      pronouns: $pronouns
      dateOfBirth: $dateOfBirth
      email: $email
    ) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;
export type GeneratedUpdateAccountMutationFn = Apollo.MutationFunction<
  GeneratedUpdateAccountMutation,
  GeneratedUpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      username: // value for 'username'
 *      countryCode: // value for 'countryCode'
 *      phone: // value for 'phone'
 *      pronouns: // value for 'pronouns'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpdateAccountMutation,
    GeneratedUpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpdateAccountMutation,
    GeneratedUpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<GeneratedUpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpdateAccountMutation,
  GeneratedUpdateAccountMutationVariables
>;
export const UpdateDrawEntryIntentDocument = gql`
  mutation UpdateDrawEntryIntent(
    $id: ID!
    $currencyCode: String!
    $shippingAddressId: ID
    $paymentMethodId: ID
    $optionalItems: [OrderItem]
    $cryptoPaymentRequested: Boolean
  ) {
    updateDrawEntryIntent(
      id: $id
      currencyCode: $currencyCode
      shippingAddressId: $shippingAddressId
      paymentMethodId: $paymentMethodId
      optionalItems: $optionalItems
      cryptoPaymentRequested: $cryptoPaymentRequested
    ) {
      intent {
        id
        errorCode
        confirmedAt
        discardedAt
      }
      pricing {
        id
        createdAt
        pricing {
          totalPrice
          subtotalTaxPrice
          subtotalShippingPrice
          subtotalItemsPrice
          currencyCode
          taxRate
          taxType
          breakdown {
            productId
            variantId
            title
            price
            quantity
            isTaxable
            freeShipping
            requiresShipping
            tags
            carrierServiceName
            shippingProviderId
          }
          includesCalculatedShipping
          includesCalculatedTaxes
        }
      }
      readyToConfirm
      cryptoComPaymentId
    }
  }
`;
export type GeneratedUpdateDrawEntryIntentMutationFn = Apollo.MutationFunction<
  GeneratedUpdateDrawEntryIntentMutation,
  GeneratedUpdateDrawEntryIntentMutationVariables
>;

/**
 * __useUpdateDrawEntryIntentMutation__
 *
 * To run a mutation, you first call `useUpdateDrawEntryIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDrawEntryIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDrawEntryIntentMutation, { data, loading, error }] = useUpdateDrawEntryIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currencyCode: // value for 'currencyCode'
 *      shippingAddressId: // value for 'shippingAddressId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      optionalItems: // value for 'optionalItems'
 *      cryptoPaymentRequested: // value for 'cryptoPaymentRequested'
 *   },
 * });
 */
export function useUpdateDrawEntryIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpdateDrawEntryIntentMutation,
    GeneratedUpdateDrawEntryIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpdateDrawEntryIntentMutation,
    GeneratedUpdateDrawEntryIntentMutationVariables
  >(UpdateDrawEntryIntentDocument, options);
}
export type UpdateDrawEntryIntentMutationHookResult = ReturnType<
  typeof useUpdateDrawEntryIntentMutation
>;
export type UpdateDrawEntryIntentMutationResult =
  Apollo.MutationResult<GeneratedUpdateDrawEntryIntentMutation>;
export type UpdateDrawEntryIntentMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpdateDrawEntryIntentMutation,
  GeneratedUpdateDrawEntryIntentMutationVariables
>;
export const UpdateOrderAddressDocument = gql`
  mutation UpdateOrderAddress(
    $id: ID!
    $shippingAddress: CreateOrUpdateAddressInput!
  ) {
    updateOrder(id: $id, shippingAddress: $shippingAddress)
  }
`;
export type GeneratedUpdateOrderAddressMutationFn = Apollo.MutationFunction<
  GeneratedUpdateOrderAddressMutation,
  GeneratedUpdateOrderAddressMutationVariables
>;

/**
 * __useUpdateOrderAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrderAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderAddressMutation, { data, loading, error }] = useUpdateOrderAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shippingAddress: // value for 'shippingAddress'
 *   },
 * });
 */
export function useUpdateOrderAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUpdateOrderAddressMutation,
    GeneratedUpdateOrderAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUpdateOrderAddressMutation,
    GeneratedUpdateOrderAddressMutationVariables
  >(UpdateOrderAddressDocument, options);
}
export type UpdateOrderAddressMutationHookResult = ReturnType<
  typeof useUpdateOrderAddressMutation
>;
export type UpdateOrderAddressMutationResult =
  Apollo.MutationResult<GeneratedUpdateOrderAddressMutation>;
export type UpdateOrderAddressMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUpdateOrderAddressMutation,
  GeneratedUpdateOrderAddressMutationVariables
>;
export const UploadUrlDocument = gql`
  query uploadURL($contentType: String!) {
    uploadURL(contentType: $contentType)
  }
`;

/**
 * __useUploadUrlQuery__
 *
 * To run a query within a React component, call `useUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadUrlQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GeneratedUploadUrlQuery,
    GeneratedUploadUrlQueryVariables
  > &
    (
      | { variables: GeneratedUploadUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GeneratedUploadUrlQuery,
    GeneratedUploadUrlQueryVariables
  >(UploadUrlDocument, options);
}
export function useUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneratedUploadUrlQuery,
    GeneratedUploadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GeneratedUploadUrlQuery,
    GeneratedUploadUrlQueryVariables
  >(UploadUrlDocument, options);
}
export function useUploadUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GeneratedUploadUrlQuery,
        GeneratedUploadUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GeneratedUploadUrlQuery,
    GeneratedUploadUrlQueryVariables
  >(UploadUrlDocument, options);
}
export type UploadUrlQueryHookResult = ReturnType<typeof useUploadUrlQuery>;
export type UploadUrlLazyQueryHookResult = ReturnType<
  typeof useUploadUrlLazyQuery
>;
export type UploadUrlSuspenseQueryHookResult = ReturnType<
  typeof useUploadUrlSuspenseQuery
>;
export type UploadUrlQueryResult = Apollo.QueryResult<
  GeneratedUploadUrlQuery,
  GeneratedUploadUrlQueryVariables
>;
export const UseLoginCodeDocument = gql`
  mutation UseLoginCode(
    $email: String
    $code: String
    $googleCredential: String
    $acceptsMarketing: Boolean
    $origin: String
    $originUTMCampaign: String
    $originUTMMedium: String
    $originUTMSource: String
  ) {
    useLoginCode(
      code: $code
      email: $email
      googleCredential: $googleCredential
      origin: $origin
      originUTMCampaign: $originUTMCampaign
      originUTMMedium: $originUTMMedium
      originUTMSource: $originUTMSource
      acceptsMarketing: $acceptsMarketing
    ) {
      isNewAccount
      account {
        ...Account
      }
    }
  }
  ${AccountFragmentDoc}
`;
export type GeneratedUseLoginCodeMutationFn = Apollo.MutationFunction<
  GeneratedUseLoginCodeMutation,
  GeneratedUseLoginCodeMutationVariables
>;

/**
 * __useUseLoginCodeMutation__
 *
 * To run a mutation, you first call `useUseLoginCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseLoginCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useLoginCodeMutation, { data, loading, error }] = useUseLoginCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      googleCredential: // value for 'googleCredential'
 *      acceptsMarketing: // value for 'acceptsMarketing'
 *      origin: // value for 'origin'
 *      originUTMCampaign: // value for 'originUTMCampaign'
 *      originUTMMedium: // value for 'originUTMMedium'
 *      originUTMSource: // value for 'originUTMSource'
 *   },
 * });
 */
export function useUseLoginCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneratedUseLoginCodeMutation,
    GeneratedUseLoginCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GeneratedUseLoginCodeMutation,
    GeneratedUseLoginCodeMutationVariables
  >(UseLoginCodeDocument, options);
}
export type UseLoginCodeMutationHookResult = ReturnType<
  typeof useUseLoginCodeMutation
>;
export type UseLoginCodeMutationResult =
  Apollo.MutationResult<GeneratedUseLoginCodeMutation>;
export type UseLoginCodeMutationOptions = Apollo.BaseMutationOptions<
  GeneratedUseLoginCodeMutation,
  GeneratedUseLoginCodeMutationVariables
>;
export const namedOperations = {
  Query: {
    RefreshStreamToken: 'RefreshStreamToken',
    QueryAccountIsInDraw: 'QueryAccountIsInDraw',
    draw: 'draw',
    GetShippingRateByProductVariantID: 'GetShippingRateByProductVariantID',
    getProducts: 'getProducts',
    GetTaxesByCountryCode: 'GetTaxesByCountryCode',
    GetAddresses: 'GetAddresses',
    SearchArtists: 'SearchArtists',
    GetArtistsBySlug: 'GetArtistsBySlug',
    nftsVouchers: 'nftsVouchers',
    nftVoucherByVoucherNumber: 'nftVoucherByVoucherNumber',
    nftGetSignedVoucher: 'nftGetSignedVoucher',
    blockchainGetSignedNftVoucher: 'blockchainGetSignedNftVoucher',
    voucherExistsForTokenId: 'voucherExistsForTokenId',
    getNftPrintsForProduct: 'getNftPrintsForProduct',
    blockchainGetAllNftsOfContract: 'blockchainGetAllNftsOfContract',
    getFullNftPrint: 'getFullNftPrint',
    blockchainOwnedNftTokensInContract: 'blockchainOwnedNftTokensInContract',
    blockchainIsNftTokenOwnedByWallet: 'blockchainIsNftTokenOwnedByWallet',
    blockchainValidateGating: 'blockchainValidateGating',
    blockchainGetInfoForTokens: 'blockchainGetInfoForTokens',
    calculateOrderPricing: 'calculateOrderPricing',
    GetDrawByProductId: 'GetDrawByProductId',
    GetDrawEntryIntent: 'GetDrawEntryIntent',
    ListPaymentMethods: 'ListPaymentMethods',
    GetArtistsByIds: 'GetArtistsByIds',
    ArteBioArtists: 'ArteBioArtists',
    getArtistTabsBlocks: 'getArtistTabsBlocks',
    getArtistTabs: 'getArtistTabs',
    getArtistBlocks: 'getArtistBlocks',
    getArtistsPermission: 'getArtistsPermission',
    arteBioSubscriptionsExport: 'arteBioSubscriptionsExport',
    getEuroFx: 'getEuroFx',
    getAccount: 'getAccount',
    blockchainGetDelegationsByDelegate: 'blockchainGetDelegationsByDelegate',
    GetDrawByProductIDs: 'GetDrawByProductIDs',
    getHls: 'getHls',
    getLiveEventPoll: 'getLiveEventPoll',
    getLiveEvent: 'getLiveEvent',
    getOrderReviewProduct: 'getOrderReviewProduct',
    getPrivateLink: 'getPrivateLink',
    getPrivateLinkWithProducts: 'getPrivateLinkWithProducts',
    getSubscriptionsCount: 'getSubscriptionsCount',
    getVoucherByTokenIdAndProjectId: 'getVoucherByTokenIdAndProjectId',
    IsSubscribed: 'IsSubscribed',
    listDrawEntryIntents: 'listDrawEntryIntents',
    listCustomerOrders: 'listCustomerOrders',
    GetOrder: 'GetOrder',
    getQuestionnaire: 'getQuestionnaire',
    reviews: 'reviews',
    uploadURL: 'uploadURL',
  },
  Mutation: {
    GenerateMultipassToken: 'GenerateMultipassToken',
    GenerateAvantPassToken: 'GenerateAvantPassToken',
    CreateAddress: 'CreateAddress',
    UpdateAddress: 'UpdateAddress',
    DeleteAddress: 'DeleteAddress',
    Web3GenerateNonce: 'Web3GenerateNonce',
    Web3VerifySignature: 'Web3VerifySignature',
    MutationEnterDraw: 'MutationEnterDraw',
    authenticateNftPrint: 'authenticateNftPrint',
    MutationDetachPaymentMethod: 'MutationDetachPaymentMethod',
    deleteArtistTab: 'deleteArtistTab',
    deleteArtistBlock: 'deleteArtistBlock',
    upsertArtistBlock: 'upsertArtistBlock',
    upsertArtistTab: 'upsertArtistTab',
    CreateCheckout: 'CreateCheckout',
    UpdateCheckout: 'UpdateCheckout',
    CreateDrawEntryIntent: 'CreateDrawEntryIntent',
    createReview: 'createReview',
    createSubscriptions: 'createSubscriptions',
    HubspotWhitepaperList: 'HubspotWhitepaperList',
    eventarteJoinedChat: 'eventarteJoinedChat',
    LeaveDraw: 'LeaveDraw',
    Login: 'Login',
    Logout: 'Logout',
    updateQuestionnaire: 'updateQuestionnaire',
    Signup: 'Signup',
    SignupAndSubscribe: 'SignupAndSubscribe',
    SlackMessage: 'SlackMessage',
    UpdateAccount: 'UpdateAccount',
    UpdateDrawEntryIntent: 'UpdateDrawEntryIntent',
    UpdateOrderAddress: 'UpdateOrderAddress',
    UseLoginCode: 'UseLoginCode',
  },
  Fragment: {
    ArtistTabFull: 'ArtistTabFull',
    ArtistBlockFull: 'ArtistBlockFull',
    SubscriptionsCount: 'SubscriptionsCount',
    OrderDetails: 'OrderDetails',
    OrderDetailsSummary: 'OrderDetailsSummary',
    ReviewDetails: 'ReviewDetails',
    Account: 'Account',
  },
};

export async function queryRefreshStreamToken(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedRefreshStreamTokenQueryVariables,
) {
  const result = await client.query<
    GeneratedRefreshStreamTokenQuery,
    GeneratedRefreshStreamTokenQueryVariables
  >({
    errorPolicy: 'all',
    query: RefreshStreamTokenDocument,
    variables,
  });

  return result;
}

export async function queryQueryAccountIsInDraw(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedQueryAccountIsInDrawQueryVariables,
) {
  const result = await client.query<
    GeneratedQueryAccountIsInDrawQuery,
    GeneratedQueryAccountIsInDrawQueryVariables
  >({
    errorPolicy: 'all',
    query: QueryAccountIsInDrawDocument,
    variables,
  });

  return result;
}

export async function queryDraw(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedDrawQueryVariables,
) {
  const result = await client.query<
    GeneratedDrawQuery,
    GeneratedDrawQueryVariables
  >({
    errorPolicy: 'all',
    query: DrawDocument,
    variables,
  });

  return result;
}

export async function queryGetShippingRateByProductVariantId(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetShippingRateByProductVariantIdQueryVariables,
) {
  const result = await client.query<
    GeneratedGetShippingRateByProductVariantIdQuery,
    GeneratedGetShippingRateByProductVariantIdQueryVariables
  >({
    errorPolicy: 'all',
    query: GetShippingRateByProductVariantIdDocument,
    variables,
  });

  return result;
}

export async function queryGetProducts(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetProductsQueryVariables,
) {
  const result = await client.query<
    GeneratedGetProductsQuery,
    GeneratedGetProductsQueryVariables
  >({
    errorPolicy: 'all',
    query: GetProductsDocument,
    variables,
  });

  return result;
}

export async function queryGetTaxesByCountryCode(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetTaxesByCountryCodeQueryVariables,
) {
  const result = await client.query<
    GeneratedGetTaxesByCountryCodeQuery,
    GeneratedGetTaxesByCountryCodeQueryVariables
  >({
    errorPolicy: 'all',
    query: GetTaxesByCountryCodeDocument,
    variables,
  });

  return result;
}

export async function queryGetAddresses(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetAddressesQueryVariables,
) {
  const result = await client.query<
    GeneratedGetAddressesQuery,
    GeneratedGetAddressesQueryVariables
  >({
    errorPolicy: 'all',
    query: GetAddressesDocument,
    variables,
  });

  return result;
}

export async function querySearchArtists(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedSearchArtistsQueryVariables,
) {
  const result = await client.query<
    GeneratedSearchArtistsQuery,
    GeneratedSearchArtistsQueryVariables
  >({
    errorPolicy: 'all',
    query: SearchArtistsDocument,
    variables,
  });

  return result;
}

export async function queryGetArtistsBySlug(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetArtistsBySlugQueryVariables,
) {
  const result = await client.query<
    GeneratedGetArtistsBySlugQuery,
    GeneratedGetArtistsBySlugQueryVariables
  >({
    errorPolicy: 'all',
    query: GetArtistsBySlugDocument,
    variables,
  });

  return result;
}

export async function queryNftsVouchers(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedNftsVouchersQueryVariables,
) {
  const result = await client.query<
    GeneratedNftsVouchersQuery,
    GeneratedNftsVouchersQueryVariables
  >({
    errorPolicy: 'all',
    query: NftsVouchersDocument,
    variables,
  });

  return result;
}

export async function queryNftVoucherByVoucherNumber(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedNftVoucherByVoucherNumberQueryVariables,
) {
  const result = await client.query<
    GeneratedNftVoucherByVoucherNumberQuery,
    GeneratedNftVoucherByVoucherNumberQueryVariables
  >({
    errorPolicy: 'all',
    query: NftVoucherByVoucherNumberDocument,
    variables,
  });

  return result;
}

export async function queryNftGetSignedVoucher(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedNftGetSignedVoucherQueryVariables,
) {
  const result = await client.query<
    GeneratedNftGetSignedVoucherQuery,
    GeneratedNftGetSignedVoucherQueryVariables
  >({
    errorPolicy: 'all',
    query: NftGetSignedVoucherDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainGetSignedNftVoucher(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainGetSignedNftVoucherQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainGetSignedNftVoucherQuery,
    GeneratedBlockchainGetSignedNftVoucherQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainGetSignedNftVoucherDocument,
    variables,
  });

  return result;
}

export async function queryVoucherExistsForTokenId(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedVoucherExistsForTokenIdQueryVariables,
) {
  const result = await client.query<
    GeneratedVoucherExistsForTokenIdQuery,
    GeneratedVoucherExistsForTokenIdQueryVariables
  >({
    errorPolicy: 'all',
    query: VoucherExistsForTokenIdDocument,
    variables,
  });

  return result;
}

export async function queryGetNftPrintsForProduct(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetNftPrintsForProductQueryVariables,
) {
  const result = await client.query<
    GeneratedGetNftPrintsForProductQuery,
    GeneratedGetNftPrintsForProductQueryVariables
  >({
    errorPolicy: 'all',
    query: GetNftPrintsForProductDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainGetAllNftsOfContract(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainGetAllNftsOfContractQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainGetAllNftsOfContractQuery,
    GeneratedBlockchainGetAllNftsOfContractQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainGetAllNftsOfContractDocument,
    variables,
  });

  return result;
}

export async function queryGetFullNftPrint(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetFullNftPrintQueryVariables,
) {
  const result = await client.query<
    GeneratedGetFullNftPrintQuery,
    GeneratedGetFullNftPrintQueryVariables
  >({
    errorPolicy: 'all',
    query: GetFullNftPrintDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainOwnedNftTokensInContract(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainOwnedNftTokensInContractQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainOwnedNftTokensInContractQuery,
    GeneratedBlockchainOwnedNftTokensInContractQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainOwnedNftTokensInContractDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainIsNftTokenOwnedByWallet(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainIsNftTokenOwnedByWalletQuery,
    GeneratedBlockchainIsNftTokenOwnedByWalletQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainIsNftTokenOwnedByWalletDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainValidateGating(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainValidateGatingQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainValidateGatingQuery,
    GeneratedBlockchainValidateGatingQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainValidateGatingDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainGetInfoForTokens(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainGetInfoForTokensQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainGetInfoForTokensQuery,
    GeneratedBlockchainGetInfoForTokensQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainGetInfoForTokensDocument,
    variables,
  });

  return result;
}

export async function queryCalculateOrderPricing(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedCalculateOrderPricingQueryVariables,
) {
  const result = await client.query<
    GeneratedCalculateOrderPricingQuery,
    GeneratedCalculateOrderPricingQueryVariables
  >({
    errorPolicy: 'all',
    query: CalculateOrderPricingDocument,
    variables,
  });

  return result;
}

export async function queryGetDrawByProductId(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetDrawByProductIdQueryVariables,
) {
  const result = await client.query<
    GeneratedGetDrawByProductIdQuery,
    GeneratedGetDrawByProductIdQueryVariables
  >({
    errorPolicy: 'all',
    query: GetDrawByProductIdDocument,
    variables,
  });

  return result;
}

export async function queryGetDrawEntryIntent(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetDrawEntryIntentQueryVariables,
) {
  const result = await client.query<
    GeneratedGetDrawEntryIntentQuery,
    GeneratedGetDrawEntryIntentQueryVariables
  >({
    errorPolicy: 'all',
    query: GetDrawEntryIntentDocument,
    variables,
  });

  return result;
}

export async function queryListPaymentMethods(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedListPaymentMethodsQueryVariables,
) {
  const result = await client.query<
    GeneratedListPaymentMethodsQuery,
    GeneratedListPaymentMethodsQueryVariables
  >({
    errorPolicy: 'all',
    query: ListPaymentMethodsDocument,
    variables,
  });

  return result;
}

export async function queryGetArtistsByIds(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetArtistsByIdsQueryVariables,
) {
  const result = await client.query<
    GeneratedGetArtistsByIdsQuery,
    GeneratedGetArtistsByIdsQueryVariables
  >({
    errorPolicy: 'all',
    query: GetArtistsByIdsDocument,
    variables,
  });

  return result;
}

export async function queryArteBioArtists(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedArteBioArtistsQueryVariables,
) {
  const result = await client.query<
    GeneratedArteBioArtistsQuery,
    GeneratedArteBioArtistsQueryVariables
  >({
    errorPolicy: 'all',
    query: ArteBioArtistsDocument,
    variables,
  });

  return result;
}

export async function queryGetArtistTabsBlocks(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetArtistTabsBlocksQueryVariables,
) {
  const result = await client.query<
    GeneratedGetArtistTabsBlocksQuery,
    GeneratedGetArtistTabsBlocksQueryVariables
  >({
    errorPolicy: 'all',
    query: GetArtistTabsBlocksDocument,
    variables,
  });

  return result;
}

export async function queryGetArtistTabs(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetArtistTabsQueryVariables,
) {
  const result = await client.query<
    GeneratedGetArtistTabsQuery,
    GeneratedGetArtistTabsQueryVariables
  >({
    errorPolicy: 'all',
    query: GetArtistTabsDocument,
    variables,
  });

  return result;
}

export async function queryGetArtistBlocks(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetArtistBlocksQueryVariables,
) {
  const result = await client.query<
    GeneratedGetArtistBlocksQuery,
    GeneratedGetArtistBlocksQueryVariables
  >({
    errorPolicy: 'all',
    query: GetArtistBlocksDocument,
    variables,
  });

  return result;
}

export async function queryGetArtistsPermission(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetArtistsPermissionQueryVariables,
) {
  const result = await client.query<
    GeneratedGetArtistsPermissionQuery,
    GeneratedGetArtistsPermissionQueryVariables
  >({
    errorPolicy: 'all',
    query: GetArtistsPermissionDocument,
    variables,
  });

  return result;
}

export async function queryArteBioSubscriptionsExport(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedArteBioSubscriptionsExportQueryVariables,
) {
  const result = await client.query<
    GeneratedArteBioSubscriptionsExportQuery,
    GeneratedArteBioSubscriptionsExportQueryVariables
  >({
    errorPolicy: 'all',
    query: ArteBioSubscriptionsExportDocument,
    variables,
  });

  return result;
}

export async function queryGetEuroFx(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetEuroFxQueryVariables,
) {
  const result = await client.query<
    GeneratedGetEuroFxQuery,
    GeneratedGetEuroFxQueryVariables
  >({
    errorPolicy: 'all',
    query: GetEuroFxDocument,
    variables,
  });

  return result;
}

export async function queryGetAccount(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetAccountQueryVariables,
) {
  const result = await client.query<
    GeneratedGetAccountQuery,
    GeneratedGetAccountQueryVariables
  >({
    errorPolicy: 'all',
    query: GetAccountDocument,
    variables,
  });

  return result;
}

export async function queryBlockchainGetDelegationsByDelegate(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedBlockchainGetDelegationsByDelegateQueryVariables,
) {
  const result = await client.query<
    GeneratedBlockchainGetDelegationsByDelegateQuery,
    GeneratedBlockchainGetDelegationsByDelegateQueryVariables
  >({
    errorPolicy: 'all',
    query: BlockchainGetDelegationsByDelegateDocument,
    variables,
  });

  return result;
}

export async function queryGetDrawByProductIDs(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetDrawByProductIDsQueryVariables,
) {
  const result = await client.query<
    GeneratedGetDrawByProductIDsQuery,
    GeneratedGetDrawByProductIDsQueryVariables
  >({
    errorPolicy: 'all',
    query: GetDrawByProductIDsDocument,
    variables,
  });

  return result;
}

export async function queryGetHls(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetHlsQueryVariables,
) {
  const result = await client.query<
    GeneratedGetHlsQuery,
    GeneratedGetHlsQueryVariables
  >({
    errorPolicy: 'all',
    query: GetHlsDocument,
    variables,
  });

  return result;
}

export async function queryGetLiveEventPoll(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetLiveEventPollQueryVariables,
) {
  const result = await client.query<
    GeneratedGetLiveEventPollQuery,
    GeneratedGetLiveEventPollQueryVariables
  >({
    errorPolicy: 'all',
    query: GetLiveEventPollDocument,
    variables,
  });

  return result;
}

export async function queryGetLiveEvent(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetLiveEventQueryVariables,
) {
  const result = await client.query<
    GeneratedGetLiveEventQuery,
    GeneratedGetLiveEventQueryVariables
  >({
    errorPolicy: 'all',
    query: GetLiveEventDocument,
    variables,
  });

  return result;
}

export async function queryGetOrderReviewProduct(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetOrderReviewProductQueryVariables,
) {
  const result = await client.query<
    GeneratedGetOrderReviewProductQuery,
    GeneratedGetOrderReviewProductQueryVariables
  >({
    errorPolicy: 'all',
    query: GetOrderReviewProductDocument,
    variables,
  });

  return result;
}

export async function queryGetPrivateLink(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetPrivateLinkQueryVariables,
) {
  const result = await client.query<
    GeneratedGetPrivateLinkQuery,
    GeneratedGetPrivateLinkQueryVariables
  >({
    errorPolicy: 'all',
    query: GetPrivateLinkDocument,
    variables,
  });

  return result;
}

export async function queryGetPrivateLinkWithProducts(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetPrivateLinkWithProductsQueryVariables,
) {
  const result = await client.query<
    GeneratedGetPrivateLinkWithProductsQuery,
    GeneratedGetPrivateLinkWithProductsQueryVariables
  >({
    errorPolicy: 'all',
    query: GetPrivateLinkWithProductsDocument,
    variables,
  });

  return result;
}

export async function queryGetSubscriptionsCount(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetSubscriptionsCountQueryVariables,
) {
  const result = await client.query<
    GeneratedGetSubscriptionsCountQuery,
    GeneratedGetSubscriptionsCountQueryVariables
  >({
    errorPolicy: 'all',
    query: GetSubscriptionsCountDocument,
    variables,
  });

  return result;
}

export async function queryGetVoucherByTokenIdAndProjectId(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables,
) {
  const result = await client.query<
    GeneratedGetVoucherByTokenIdAndProjectIdQuery,
    GeneratedGetVoucherByTokenIdAndProjectIdQueryVariables
  >({
    errorPolicy: 'all',
    query: GetVoucherByTokenIdAndProjectIdDocument,
    variables,
  });

  return result;
}

export async function queryIsSubscribed(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedIsSubscribedQueryVariables,
) {
  const result = await client.query<
    GeneratedIsSubscribedQuery,
    GeneratedIsSubscribedQueryVariables
  >({
    errorPolicy: 'all',
    query: IsSubscribedDocument,
    variables,
  });

  return result;
}

export async function queryListDrawEntryIntents(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedListDrawEntryIntentsQueryVariables,
) {
  const result = await client.query<
    GeneratedListDrawEntryIntentsQuery,
    GeneratedListDrawEntryIntentsQueryVariables
  >({
    errorPolicy: 'all',
    query: ListDrawEntryIntentsDocument,
    variables,
  });

  return result;
}

export async function queryListCustomerOrders(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedListCustomerOrdersQueryVariables,
) {
  const result = await client.query<
    GeneratedListCustomerOrdersQuery,
    GeneratedListCustomerOrdersQueryVariables
  >({
    errorPolicy: 'all',
    query: ListCustomerOrdersDocument,
    variables,
  });

  return result;
}

export async function queryGetOrder(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetOrderQueryVariables,
) {
  const result = await client.query<
    GeneratedGetOrderQuery,
    GeneratedGetOrderQueryVariables
  >({
    errorPolicy: 'all',
    query: GetOrderDocument,
    variables,
  });

  return result;
}

export async function queryGetQuestionnaire(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedGetQuestionnaireQueryVariables,
) {
  const result = await client.query<
    GeneratedGetQuestionnaireQuery,
    GeneratedGetQuestionnaireQueryVariables
  >({
    errorPolicy: 'all',
    query: GetQuestionnaireDocument,
    variables,
  });

  return result;
}

export async function queryReviews(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedReviewsQueryVariables,
) {
  const result = await client.query<
    GeneratedReviewsQuery,
    GeneratedReviewsQueryVariables
  >({
    errorPolicy: 'all',
    query: ReviewsDocument,
    variables,
  });

  return result;
}

export async function queryUploadUrl(
  client: ApolloClient<NormalizedCacheObject>,
  variables: GeneratedUploadUrlQueryVariables,
) {
  const result = await client.query<
    GeneratedUploadUrlQuery,
    GeneratedUploadUrlQueryVariables
  >({
    errorPolicy: 'all',
    query: UploadUrlDocument,
    variables,
  });

  return result;
}
