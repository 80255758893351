import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useScrollLock } from '../../hooks/useScrollLock';
import { ModalCover } from './ModalCover';

export interface ModalProps {
  title: string | React.ReactNode;
  onClose?: () => void;
  overlayOtherDialogs?: boolean;
  dismissible?: boolean;
  open: boolean;
  verticalAlign?: 'center' | 'flex-start' | 'flex-end';
  preventOutsideClick?: boolean;
  children?: React.ReactNode;
}

export const Modal: React.FunctionComponent<ModalProps> = ({
  children,
  dismissible,
  onClose,
  open,
  overlayOtherDialogs = false,
  preventOutsideClick = false,
  title,
  verticalAlign,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isClient, setIsClient] = useState(false);

  useScrollLock(open);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (!dismissible) return;

    if (['Escape', 'Esc'].includes(e.key)) onClose?.();
  };

  const onClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as Element;
    const clickWithin = modalRef?.current?.contains(target);
    const isOnOverlay = modalRef?.current === target;

    if (!dismissible || (clickWithin && !isOnOverlay) || preventOutsideClick) {
      e.stopPropagation();

      return;
    }

    onClose?.();
  };

  const wrappedChildren = (
    <ModalCover
      ref={modalRef}
      display={open}
      onClick={onClickOutside}
      onKeyDown={onKeyDown}
      overlayOtherDialogs={overlayOtherDialogs}
      title={String(title)}
      verticalAlign={verticalAlign}
    >
      {children}
    </ModalCover>
  );

  useEffect(() => setIsClient(true), []);

  if (!isClient) {
    return null;
  }

  // An alternative to FocusTrap
  const nextElement = document.getElementById('app_focus_trap_inert');

  if (!nextElement) {
    return null;
  }

  if (dismissible && modalRef.current) {
    if (open) {
      nextElement.setAttribute('inert', '');
    } else {
      nextElement.removeAttribute('inert');
    }
  }

  return ReactDOM.createPortal(wrappedChildren, document.body);
};
