import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button } from '../../Button';
import buttonStyles from '../../Button/button.module.css';
import { Icon } from '../../Icon';
import carouselStyles from '../splide.module.css';

type Props = {
  view: 'grid' | 'carousel';
  items: Array<React.ReactNode>;
  onSlide?: (index: number) => void;
  onViewUpdate?: (view: 'grid' | 'carousel') => void;
};

export const PrivateRoomGallery: React.FC<Props> = ({
  items,
  onSlide = () => {},
  onViewUpdate = () => {},
  view,
}) => {
  const { t } = useTranslation('private');
  const mainRef = React.useRef<Splide>(null);

  const mountSplide = () => {
    if (mainRef.current) {
      mainRef.current.splide?.destroy();
      mainRef.current.splide?.mount();
    }
  };

  const destroySplide = () => {
    if (mainRef.current) {
      mainRef.current.splide?.destroy();
    }
  };

  const handleUpdateView = (newValue: 'grid' | 'carousel') => {
    onViewUpdate(newValue);

    if (newValue === 'carousel') {
      mountSplide();
    } else {
      destroySplide();
    }
  };

  const paginationArrows = (
    <>
      <button
        className={classNames(
          buttonStyles.UiButton__variant__tertiary,
          'splide__arrow splide__arrow--prev z-50 -translate-x-16 !rounded-full p-3',
        )}
        type="submit"
      >
        <Icon name="ic_arrow_left" />
      </button>
      <button
        className={classNames(
          buttonStyles.UiButton__variant__tertiary,
          'splide__arrow splide__arrow--next z-50 translate-x-16 !rounded-full p-3',
        )}
        type="submit"
      >
        <Icon name="ic_arrow_right" />
      </button>
    </>
  );

  return (
    <div className={classNames(carouselStyles.privateRoomGallery__wrapper)}>
      {items.length > 1 ? (
        <div className="flex w-full items-center justify-between px-website py-3">
          <p className="text-white">
            {items.length} {t('worksCounter')}
          </p>

          <div className="flex items-center justify-center gap-1">
            <Button
              disabled={view === 'grid'}
              hideLabel
              icon="ic_grid"
              label="Display as a grid"
              onClick={() => handleUpdateView('grid')}
              size="xs"
              variant="transparent"
            />
            <Button
              disabled={view === 'carousel'}
              hideLabel
              icon="ic_carousel"
              label="Display as a carousel"
              onClick={() => handleUpdateView('carousel')}
              size="xs"
              variant="transparent"
            />
          </div>
        </div>
      ) : null}

      <div className={classNames(carouselStyles.privateRoomGallery)}>
        <Splide
          key={view}
          ref={mainRef}
          hasTrack={false}
          onMoved={(e) => {
            onSlide(e.index);
          }}
          options={{
            rewind: false,
            pagination: true,
            mediaQuery: 'min',

            arrows: false,
            focus: 'center',
            gap: 20,
            perMove: 1,
            perPage: view === 'carousel' ? 1.5 : 1,

            flickPower: 300,
            flickMaxPages: 0.3,
            dragMinThreshold: {
              mouse: 10,
              touch: 30,
            },

            breakpoints: {
              768: {
                arrows: items.length > 1,
                gap: 60,
                perPage: 1,
              },
              1024: {
                gap: 120,
              },
            },

            classes: {
              pagination: 'splide__pagination gap-2 pt-6',
              page: 'splide__pagination__page block w-1.5 h-1.5 bg-white/10 hover:bg-white/40 [&.is-active]:bg-white rounded-full transition duration-300 ease-out',
            },
          }}
        >
          <div className="relative w-full">
            <div
              className={classNames(
                'splide__arrows',
                carouselStyles.privateRoomGallery__arrows,
                { 'invisible opacity-0': view === 'grid' },
              )}
            >
              {paginationArrows}
            </div>

            <SplideTrack
              className={classNames(
                view === 'grid' &&
                  carouselStyles.privateRoomGallery__grid__track,
                view === 'carousel' &&
                  carouselStyles.privateRoomGallery__carousel__track,
              )}
            >
              {items.map((item, index) => (
                <SplideSlide
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classNames(
                    view === 'carousel' &&
                      carouselStyles.privateRoomGallery__carousel__track__slide,
                  )}
                >
                  <div className="h-full w-full">{item}</div>
                </SplideSlide>
              ))}
            </SplideTrack>
          </div>
        </Splide>
      </div>
    </div>
  );
};
