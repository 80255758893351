import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React, { useState } from 'react';

import { ClickOutside } from '../../../components/ClickOutside';
import { Button, ButtonSizeType, ButtonVariantType } from '../Button';
import { Icon, IconName } from '../Icon';
import styles from './dropdown.module.css';

interface DropdownProps {
  anchor?: 'topLeft' | 'topCenter' | 'topRight';
  buttonLabel: string;
  buttonIcon?: IconName;
  hideLabel?: boolean;
  buttonSize?: ButtonSizeType;
  buttonVariant?: ButtonVariantType;
  children?: React.ReactNode;
}

export const DropdownGroup = ({ children }: { children: React.ReactNode }) => {
  return <ul className={styles.UiDropdown__group}>{children}</ul>;
};

interface DropdownGroupItemBase {
  label: string;
  icon?: IconName;
  variant?: 'default' | 'danger';
  onClick?: () => void;
}

type HrefInterface = React.HTMLAttributes<HTMLAnchorElement> &
  DropdownGroupItemBase & {
    as?: 'a';
    href: string;
    target?: '_blank';
    rel?: 'noreferrer';
  };

type ButtonInterface = React.HTMLAttributes<HTMLButtonElement> &
  DropdownGroupItemBase & {
    as?: 'button';
    type?: 'button';
    disabled?: boolean;
  };

type DropdownGroupItemProps = HrefInterface | ButtonInterface;

const isHref = (props: DropdownGroupItemProps): props is HrefInterface => {
  return props.as === 'a';
};

export const DropdownGroupItem = (props: DropdownGroupItemProps) => {
  if (isHref(props)) {
    const { href, icon, label, onClick, variant = 'default' } = props;

    return (
      <li>
        <Link
          className={classNames(
            variant === 'default' && styles.UiDropdown__group__item__default,
            variant === 'danger' && styles.UiDropdown__group__item__danger,
          )}
          href={href}
          onClick={onClick}
          {...(props?.target && { 'target': props?.target })}
        >
          <span className="w-full flex-1">{label}</span>
          {icon ? <Icon name={icon} /> : null}
        </Link>
      </li>
    );
  }

  const { icon, label, onClick, variant = 'default' } = props;

  return (
    <li>
      <button
        className={classNames(
          variant === 'default' && styles.UiDropdown__group__item__default,
          variant === 'danger' && styles.UiDropdown__group__item__danger,
        )}
        onClick={onClick}
        type="button"
      >
        <span className="w-full flex-1">{label}</span>
        {icon ? <Icon name={icon} /> : null}
      </button>
    </li>
  );
};

export const Dropdown: React.FC<DropdownProps> = ({
  anchor = 'topCenter',
  buttonIcon = 'ic_chevron_down',
  buttonLabel,
  buttonSize = 'lg',
  buttonVariant = 'primary',
  children,
  hideLabel,
}) => {
  const [open, setOpen] = useState(false);

  const positionMapper = {
    topLeft: 'left-0',
    topCenter: 'left-1/2',
    topRight: 'left-full',
  };

  const transformMapper = {
    topLeft: '0',
    topCenter: '-50%',
    topRight: '-100%',
  };

  return (
    <div className="relative">
      <Button
        hideLabel={hideLabel}
        icon={buttonIcon}
        label={buttonLabel}
        onClick={() => setOpen(true)}
        size={buttonSize}
        variant={buttonVariant}
      />

      <AnimatePresence>
        {open && (
          <ClickOutside onClickOutside={() => setOpen(false)}>
            <motion.div
              animate={{
                opacity: 1,
                transform: `translate(${transformMapper[anchor]}, 8px)`,
              }}
              className={classNames(styles.UiDropdown, positionMapper[anchor])}
              exit={{
                opacity: 0,
                transform: `translate(${transformMapper[anchor]}, 48px)`,
              }}
              initial={{
                opacity: 0,
                transform: `translate(${transformMapper[anchor]}, 48px)`,
              }}
            >
              {children}
            </motion.div>
          </ClickOutside>
        )}
      </AnimatePresence>
    </div>
  );
};
