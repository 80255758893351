import React, { PropsWithChildren } from 'react';

import SocialMetadata from '../SocialMetadata';
import Head from './Head';
import { useTheme } from './Theme/useTheme';
import { LayoutTheme } from './types';

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
  ogType?: 'article' | 'website';
  ogImageUrl?: string;
  canonicalUrl: string;
  hostname?: string;
  theme?: LayoutTheme;
}>;

export const SimpleLayout = ({
  canonicalUrl,
  children,
  description = '',
  hostname,
  ogImageUrl,
  ogType,
  theme = 'light',
  title = '',
}: Props) => {
  useTheme(theme);

  return (
    <div className="flex min-h-dvh flex-col">
      <Head
        description={description}
        theme={theme}
        title={title}
        urls={{ canonical: canonicalUrl }}
      />
      <SocialMetadata
        description={description}
        hostname={hostname}
        imageUrl={ogImageUrl}
        title={title}
        type={ogType}
        url={canonicalUrl}
      />
      {children}
    </div>
  );
};
