import axios from 'axios';

import {
  CONTINENT_COOKIE_NAME,
  COUNTRIES,
  COUNTRY_COOKIE_NAME,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CURRENCY_CODE,
} from '../constants/locations';
import { GetUserLocationResponse } from '../pages/api/location';
import { ContinentCode, CountryCode, CurrencyCode } from '../types/Locations';
import { captureError } from './capture';
import { getCookie, setCookie } from './cookies';

function getLocationFromCookies() {
  const country = getCookie<CountryCode>(COUNTRY_COOKIE_NAME);
  const continent = getCookie<ContinentCode>(CONTINENT_COOKIE_NAME);

  if (!country) return null;

  return {
    country,
    continent,
  };
}

export async function getLocation() {
  try {
    const locationFromCookies = getLocationFromCookies();
    if (locationFromCookies)
      return { error: null, location: locationFromCookies };

    const url = new URL('/api/location', window.location.origin);
    const res = await axios.get<GetUserLocationResponse>(url.toString());

    if (res.data.location) {
      setCookie(COUNTRY_COOKIE_NAME, res.data.location.country);
      setCookie(CONTINENT_COOKIE_NAME, res.data.location.continent);
    }

    return res.data;
  } catch (err) {
    captureError(err);
    return {};
  }
}

export const getCountryByCurrency = (currency: CurrencyCode) => {
  if (currency === DEFAULT_CURRENCY_CODE) return DEFAULT_COUNTRY_CODE;
  return Object.keys(COUNTRIES).find(
    (key: CountryCode) => COUNTRIES[key].currency === currency,
  ) as CountryCode | undefined;
};

export const getCurrencySymbolByCode = (currency: CurrencyCode) => {
  return Intl.NumberFormat('en', {
    currency,
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d/g, '');
};
