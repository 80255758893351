import classNames from 'classnames';
import React from 'react';

import styles from './field.module.css';

export interface CheckboxWrapperProps {
  label?: string;
  note?: string | React.ReactNode;
  error?: string;
  hideError?: boolean;
  hideLabel?: boolean;
  isInline?: boolean;
  children?: React.ReactNode;
}

export const CheckboxWrapper: React.FC<CheckboxWrapperProps> = ({
  children,
  error,
  hideError = false,
  hideLabel = false,
  isInline,
  label,
  note,
}) => (
  <fieldset className={classNames(styles.UiField)}>
    {label ? (
      <>
        {
          // https://adrianroselli.com/2022/07/use-legend-and-fieldset.html
        }
        <legend className="sr-only">{label}</legend>
        <div
          aria-hidden="true"
          className={classNames(styles.UiField__label, {
            'hidden': hideLabel,
          })}
        >
          {label}
        </div>
      </>
    ) : null}

    <div
      className={classNames(
        isInline ? 'flex-row flex-wrap' : 'flex-col',
        'flex w-full gap-2',
      )}
    >
      {children}
    </div>

    {note ? <p className={classNames(styles.UiField__note)}>{note}</p> : null}

    {!hideError && error ? (
      <p className={classNames(styles.UiField__error)}>{error}</p>
    ) : null}
  </fieldset>
);
