import classNames from 'classnames';
import { Accept, useDropzone } from 'react-dropzone';

import {
  Button,
  type ButtonSizeType,
  type ButtonVariantType,
} from '../../Button/Button';
import styles from '../FieldWrapper/field.module.css';

export interface UploadFieldProps {
  id: string;
  accept?: Accept;
  multiple?: boolean;
  label?: string;
  title: string;
  note: string;
  error?: string;
  hideError?: boolean;
  buttonLabel: string;
  buttonSize?: ButtonSizeType;
  buttonVariant?: ButtonVariantType;
  onChange: (files: File[]) => void;
}

export const UploadField = ({
  accept = { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] },
  buttonLabel,
  buttonSize = 'md',
  buttonVariant = 'secondary',
  error,
  hideError = false,
  id,
  label,
  multiple = false,
  note,
  onChange,
  title,
}: UploadFieldProps) => {
  const { getInputProps, getRootProps } = useDropzone({
    accept,
    onDrop: onChange,
    multiple,
  });

  return (
    <div className={classNames(styles.UiField)}>
      {label ? (
        <label className={classNames(styles.UiField__label)}>{label}</label>
      ) : null}

      <div
        className={classNames(
          styles.UiField__field,
          error && !hideError && styles.UiField__field__error,
        )}
        {...getRootProps()}
      >
        <div className="mx-auto flex w-full max-w-md flex-col items-center justify-center gap-4 py-12">
          <input id={id} {...getInputProps()} />

          <div className="flex w-full flex-col items-center justify-center gap-1 text-center">
            <p>{title}</p>
            {note ? (
              <p className="text-balance text-xs text-neutral-4">{note}</p>
            ) : null}
          </div>

          <Button
            label={buttonLabel}
            onClick={() => {
              return;
            }}
            size={buttonSize}
            type="button"
            variant={buttonVariant}
          />
        </div>
      </div>

      {!hideError && error ? (
        <p className={classNames(styles.UiField__error)}>{error}</p>
      ) : null}
    </div>
  );
};
