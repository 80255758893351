import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';

import { Button } from '../Button';
import { Icon } from '../Icon';

type AccordionProps = {
  children: ReactNode;
};

export const Accordion = ({ children }: AccordionProps) => (
  <div className="flex w-full flex-col divide-y divide-divider/20">
    {children}
  </div>
);

type AccordionItemProps = {
  index?: number;
  subject: string;
  isOpen?: boolean;
  children: ReactNode;
  analytics?: () => void;
  anchorClick?: () => void;
};

export const AccordionItem = ({
  analytics = () => {},
  anchorClick,
  children,
  index,
  isOpen = false,
  subject,
}: AccordionItemProps) => {
  const [accordionOpen, setAccordionOpen] = useState(isOpen);

  const handleExpand = () => {
    const newValue = !accordionOpen;

    if (analytics && newValue) analytics();

    setAccordionOpen(newValue);
  };

  return (
    <div className="group relative flex w-full flex-col">
      {anchorClick ? (
        <div className="absolute left-0 top-3 hidden translate-x-0 pr-2 opacity-0 transition duration-300 ease-out focus-within:-translate-x-full focus-within:opacity-100 group-hover:-translate-x-full group-hover:opacity-100 md:block">
          <Button
            label="#"
            onClick={anchorClick}
            size="xs"
            type="button"
            variant="secondary"
          />
        </div>
      ) : null}
      <motion.button
        className="z-20 flex w-full items-start gap-2 py-4 text-left"
        initial={false}
        onClick={handleExpand}
        type="button"
      >
        {index ? (
          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-sm bg-neutral-6 text-center text-xs text-white">
            {index}
          </span>
        ) : null}
        <span className="w-full py-0.5 font-bold">{subject}</span>
        <span className="shrink-0 py-0.5">
          <Icon
            className={classNames(
              accordionOpen ? 'rotate-90' : 'rotate-0',
              'transition duration-300 ease-in-out',
            )}
            name="ic_chevron_right"
          />
        </span>
      </motion.button>
      <AnimatePresence initial={false}>
        {accordionOpen && (
          <motion.section
            key="content"
            animate="open"
            className="overflow-hidden"
            exit="collapsed"
            initial="collapsed"
            transition={{ duration: 0.3 }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            <div className="flex flex-col gap-y-3 pb-4">{children}</div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};
