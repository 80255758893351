import '../styles/globals.css';
import '../styles/tailwind.css';

import type { AppProps } from 'next/app';

import { AAGrowthbookProvider } from '../components/AAGrowthbookProvider';
import { ArteBioPageProvider } from '../context/ArteBioPageContext';
import { GoogleTagManager } from '../scripts/GoogleTagManager';

export const AppArteBio = ({ Component, pageProps }: AppProps) => {
  return (
    <ArteBioPageProvider isArteBioPage>
      <GoogleTagManager />
      <AAGrowthbookProvider>
        <Component {...pageProps} />
      </AAGrowthbookProvider>
    </ArteBioPageProvider>
  );
};
